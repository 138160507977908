import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import withSizes from "react-sizes";

import {
  faClipboardUser,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Module, ModuleBody } from "@wfp/ui";

import { deliveryCategory, UserPermissionRoles } from "SCConstants";
import { mapSizesToProps } from "helpers/mapSizesToProps";
import { getReportedStores } from "helpers/report";
import {
  Attendance,
  AggregateSchoolYearLevel,
  SchoolYear,
  getCurrentSchoolYear,
  getIsSyncableLocked,
  getPreviousSchoolYears,
  getSchoolYearByDate,
  getSchoolYearLevelsBySchoolYearId,
} from "data-handler/ducks/stores";

import DeleteModalButton from "components/DeleteModalButton";
import Empty from "components/Empty";
import Section from "components/Section";
import SubTitle from "components/SubTitle";
import Gate from "components/Gate";
import StudentAttendanceTableMobile from "components/StudentAttendanceShow/StudentAttendanceTableMobile";
import StudentAttendanceTableDesktop from "components/StudentAttendanceShow/StudentAttendanceTableDesktop";

import { RootState } from "data-handler/rootReducer";

import UneditableMessage from "components/UneditableMessage";
import moment from "moment";
import { buildAttendanceUrl } from "helpers/attendance";

const noMealReasonsRestriction = (currentStoreData: Attendance) => {
  return (
    (currentStoreData?.consumption?.no_meal_reasons?.includes(1) ||
      currentStoreData?.consumption?.no_meal_reasons?.includes(5) ||
      currentStoreData?.consumption?.no_meal_reasons?.includes(7)) &&
    currentStoreData?.type !== "delete"
  );
};

type StudentAttendanceShowProps = {
  currentStoreData: Attendance;
  currentAttendanceStoreData: Attendance;
  date: string;
  currentSchoolEnabledStudentAttendance: boolean;
};

type WithSizes = {
  isMobile: boolean;
};

const StudentAttendanceShow: React.ComponentType<
  StudentAttendanceShowProps & WithSizes
> = ({
  currentStoreData,
  currentAttendanceStoreData,
  date,
  isMobile,
  currentSchoolEnabledStudentAttendance,
}) => {
  type Params = {
    item: string;
    thrItem: string;
    studentItem: string;
    schoolId: string;
  };
  const params: Params = useParams();

  const urlDetails = currentAttendanceStoreData
    ? "student-attendance"
    : "new-student-attendance";

  const editUrl = buildAttendanceUrl(
    date,
    params.schoolId,
    params.item,
    params.thrItem,
    params.studentItem,
    urlDetails
  );
  const reportedStores: any = useSelector(getReportedStores);
  const isReported: boolean = Boolean(reportedStores[params.item]);
  const isLocked = useSelector((state: RootState) =>
    getIsSyncableLocked(state, currentAttendanceStoreData)
  );

  const defineSection = !currentAttendanceStoreData?.consumption
    ? undefined
    : "attendance";

  const deleteAttendanceLabel = (
    <FormattedMessage
      id="delete.deleteStudentAttendanceLabel"
      defaultMessage="Delete attendance"
    />
  );

  const currentYear: SchoolYear | null = useSelector(getCurrentSchoolYear); //users current year

  const selectedAttendanceSchoolYear: SchoolYear | null = useSelector(
    getSchoolYearByDate((date ? moment(date) : moment()).format("YYYY-MM-DD"))
  );

  const selectedAttendanceSchoolYearLevels: AggregateSchoolYearLevel[] = useSelector(
    getSchoolYearLevelsBySchoolYearId(selectedAttendanceSchoolYear!.object_id)
  );

  const previousSchoolYears = useSelector(getPreviousSchoolYears)[0];

  if (!currentSchoolEnabledStudentAttendance) {
    return null;
  }

  if (!currentAttendanceStoreData?.levels) {
    return (
      <Empty
        kind="section"
        title={
          <FormattedMessage
            id="StudentAttendanceShow.studentAttendanceTitle"
            defaultMessage="Attendance"
          />
        }
        icon={<FontAwesomeIcon icon={faClipboardUser} size="1x" />}
        button={
          <Gate
            forbidRoles={[UserPermissionRoles.is_viewer]}
            isForbidden={
              isLocked ||
              (currentYear !== null &&
                currentYear !== selectedAttendanceSchoolYear) ||
              (currentYear === null &&
                previousSchoolYears !== selectedAttendanceSchoolYear)
            }
          >
            <NavLink className="edit-nav-link" to={editUrl}>
              <Button
                kind="accent"
                icon={<FontAwesomeIcon icon={faChevronRight} />}
                disabled={
                  isReported || noMealReasonsRestriction(currentStoreData)
                }
              >
                <FormattedMessage
                  id="StudentAttendanceShow.provideStudentAttendanceButton"
                  defaultMessage="Provide attendance"
                />
              </Button>
            </NavLink>
          </Gate>
        }
      >
        <FormattedMessage
          id={
            currentSchoolEnabledStudentAttendance
              ? "AttendanceShow.provideCanteenAttendanceLabel"
              : "AttendanceShow.provideAttendanceLabel"
          }
          defaultMessage={
            currentSchoolEnabledStudentAttendance
              ? "Provide canteen Attendance"
              : "Provide attendance"
          }
        />
      </Empty>
    );
  }

  const newStudentAttendanceId =
    "newDate+" +
    moment(currentAttendanceStoreData?.occurred_on).format("YYYY-MM-DD");

  return (
    <Section>
      <SubTitle
        additional={
          <Gate
            isForbidden={
              isLocked ||
              (currentYear !== null &&
                currentYear !== selectedAttendanceSchoolYear) ||
              (currentYear === null &&
                previousSchoolYears !== selectedAttendanceSchoolYear)
            }
            forbidRoles={[UserPermissionRoles.is_viewer]}
          >
            {currentAttendanceStoreData.type !== "delete" && (
              <DeleteModalButton
                currentStoreData={currentAttendanceStoreData}
                section={defineSection}
                title={deleteAttendanceLabel}
                customRedirectPath={
                  currentAttendanceStoreData?.sync === false &&
                  !currentAttendanceStoreData?.id &&
                  ((!currentAttendanceStoreData.levels &&
                    currentAttendanceStoreData.consumption) ||
                    (currentAttendanceStoreData.levels &&
                      !currentAttendanceStoreData.consumption))
                    ? buildAttendanceUrl(
                        date,
                        params.schoolId,
                        params.item,
                        params.thrItem,
                        newStudentAttendanceId
                      )
                    : buildAttendanceUrl(
                        date,
                        params.schoolId,
                        params.item,
                        params.thrItem,
                        params.studentItem
                      )
                }
              />
            )}
            <NavLink className="edit-nav-link" to={editUrl}>
              <Button
                kind="secondary"
                icon={<FontAwesomeIcon icon={faChevronRight} />}
                disabled={isReported || isLocked}
              >
                <FormattedMessage
                  id="StudentAttendanceShow.editStudentAttendanceButton"
                  defaultMessage="Edit attendance"
                />
              </Button>
            </NavLink>
          </Gate>
        }
      >
        <Section>
          <UneditableMessage currentStoreData={currentAttendanceStoreData} />
        </Section>

        <FormattedMessage
          id="StudentAttendanceShow.attendanceSubTitle"
          defaultMessage="Attendance"
        />
      </SubTitle>
      <Module noMargin>
        <ModuleBody noPadding>
          {!isMobile &&
          selectedAttendanceSchoolYear !== null &&
          selectedAttendanceSchoolYearLevels !== null ? (
            <StudentAttendanceTableDesktop
              attendance={currentAttendanceStoreData}
              schoolYear={selectedAttendanceSchoolYear}
              schoolYearLevels={selectedAttendanceSchoolYearLevels}
              category={deliveryCategory}
            />
          ) : (
            <StudentAttendanceTableMobile
              attendance={currentAttendanceStoreData}
              schoolYear={selectedAttendanceSchoolYear!}
              schoolYearLevels={selectedAttendanceSchoolYearLevels}
              category={deliveryCategory}
            />
          )}
        </ModuleBody>
      </Module>
    </Section>
  );
};

export default (withSizes(mapSizesToProps)(
  StudentAttendanceShow as any
) as unknown) as React.ComponentType<StudentAttendanceShowProps>;
