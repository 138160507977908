import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { Module, ModuleBody } from "@wfp/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSeedling } from "@fortawesome/free-solid-svg-icons";

import TableExtended from "components/TableExtended";
import MeasureUnitValue from "components/MeasureUnitValue";
import Empty from "components/Empty";
import SubTitle from "components/SubTitle";

import commoditiesDuck from "data-handler/ducks/commodities";
import deliveryIssuesDuck from "data-handler/ducks/deliveryIssues";
import { getCurrencyForCurrentSchool } from "data-handler/ducks/currency";
import {
  commoditySourceOptions,
  nonWfpCategory,
  takeHomeRationCategory,
} from "SCConstants";

import {
  getWaybillByCommodityAndDeliveryID,
  getPurchaseNameByCommodityPurchaseID,
} from "helpers/stock";
import { deliveryCategory, purchaseDetailCategory } from "SCConstants";
import "./_commodities-show.scss";
import { getAllStores } from "data-handler/ducks/stores";
import { getVendorCategories } from "data-handler/ducks/vendors";
import { missingCommodity } from "data-handler/ducks/reports";

const sourceFromKey = (value: string) => {
  if (!value) return null;
  const valueString = value.toString();
  const commodity = commoditySourceOptions.find((e) => {
    return e.value.toString() === valueString;
  });
  return commodity;
};

type Commodity = {
  commodity: number;
  total_paid: number;
  batch_no: string;
  category: string;
  source: string;
  quantity: number;
  issues: number[];
  comments: string[];
  delivery_commodity?: string;
  purchase_detail_commodity?: string;
};

type CommodityShowProps = {
  commodities: Commodity[];
  emptyTitle: React.ReactNode;
  title: React.ReactNode;
  model: string;
  type?: string;
};
export default function CommodityShow({
  commodities,
  emptyTitle,
  title,
  model,
  type,
}: CommodityShowProps) {
  const deliveryIssuesIndex = useSelector(deliveryIssuesDuck.getIndex);
  const commodityIndex = useSelector(commoditiesDuck.getIndex);

  const getCommodity = (id: number) => {
    const defaultCommodity = {
      ...missingCommodity,
      name: `Unidentified(${id})`,
    };
    return commodityIndex[id] || defaultCommodity;
  };

  const allStores = useSelector(getAllStores);
  const defaultCurrencyName = useSelector(getCurrencyForCurrentSchool).name;
  const commoditiesTotalPaid = commodities?.map((i) => i.total_paid);
  const commoditiesTotalPaidSum = commoditiesTotalPaid?.reduce(
    (a: any, b: any) => parseFloat(a) + parseFloat(b),
    0
  );
  const InitialStockSource = type === "Carry-over" ? "Carry-over" : null;
  const vendorCategories = useSelector(getVendorCategories);
  const rows = commodities
    ? commodities.map((row, i) => {
        const commodity = getCommodity(row.commodity);
        const waybill_no =
          row.batch_no && !row.delivery_commodity && model === "incident"
            ? "Initial stock"
            : getWaybillByCommodityAndDeliveryID(
                allStores,
                row.delivery_commodity!
              );
        let purchaseName = "";
        if (row.category === purchaseDetailCategory) {
          purchaseName = getPurchaseNameByCommodityPurchaseID(
            allStores,
            row.purchase_detail_commodity!,
            vendorCategories
          );
        }

        const commodityCategory =
          row.category === takeHomeRationCategory ? (
            <FormattedMessage
              id="source.thr"
              defaultMessage="Take-home ration"
            />
          ) : row.category === deliveryCategory ? (
            <FormattedMessage
              id="source.deliverySource"
              defaultMessage="Store (On-site Meals)"
            />
          ) : row.category === purchaseDetailCategory ? (
            <FormattedMessage
              id="source.purchaseSource"
              defaultMessage="Store (Purchases) "
            />
          ) : row.category === nonWfpCategory ? (
            <FormattedMessage
              id="source.nonWfp"
              defaultMessage="Non-WFP food"
            />
          ) : undefined;

        return (
          <tr key={i}>
            <td className="commodities-show__commodity">
              <span className="mobile-title">
                <FormattedMessage
                  id="CommodityShow.commodityTitle"
                  defaultMessage="Commodity"
                />
              </span>
              {commodity && commodity.name}
            </td>
            {model === "delivery" && (
              <td className="commodities-show__batch">
                <span className="mobile-title">
                  <FormattedMessage
                    id="CommodityShow.batch_no"
                    defaultMessage="Batch number"
                  />
                </span>
                {row?.batch_no === null || row?.batch_no === undefined ? (
                  <FormattedMessage
                    id="DeliveriesEdit.unknownLabel"
                    defaultMessage="Unknown"
                  />
                ) : (
                  row.batch_no
                )}
              </td>
            )}
            {(model === "attendance" || model === "delivery") && (
              <td className="commodities-show__source">
                <span className="mobile-title">
                  <FormattedMessage
                    id="CommodityShow.source"
                    defaultMessage="Source"
                  />
                </span>
                {(sourceFromKey(row.source) &&
                  sourceFromKey(row.source)?.text) ||
                  commodityCategory ||
                  InitialStockSource}
              </td>
            )}
            {model === "delivery" && type !== "Carry-over" && (
              <td>
                <span className="mobile-title">
                  <FormattedMessage
                    id="CommodityShow.quality"
                    defaultMessage="Quality"
                  />
                </span>
                {(row.issues || []).map((issue) => (
                  <p key={issue}>{deliveryIssuesIndex[issue]?.description}</p>
                ))}
              </td>
            )}
            <td className="commodities-show__quantity">
              <MeasureUnitValue measureUnit={commodity.measure_unit}>
                {row.quantity}
              </MeasureUnitValue>
            </td>
            {model === "delivery" && type !== "Carry-over" && (
              <td>
                <span className="mobile-title">
                  <FormattedMessage
                    id="DeliveriesEdit.comments"
                    defaultMessage="Comments"
                  />
                </span>
                {row.comments}
              </td>
            )}
            {model === "purchasedetail" && (
              <td>
                <span className="mobile-title">
                  <FormattedMessage
                    id="PriceShow.price"
                    defaultMessage="Amount paid"
                  />
                  {` (${defaultCurrencyName})`}
                </span>
                {Number(row.total_paid)}
              </td>
            )}
            {model === "incident" && row.category === deliveryCategory && (
              <td className="commodities-show__quantity">
                <span className="mobile-title">
                  <FormattedMessage
                    id="IncidentShow.waybill"
                    defaultMessage="Waybill number"
                  />
                </span>
                {waybill_no}
              </td>
            )}{" "}
            {model === "incident" &&
              row.category === purchaseDetailCategory && (
                <td className="commodities-show__quantity">
                  <span className="mobile-title">
                    <FormattedMessage
                      id="IncidentShow.purchase"
                      defaultMessage="Purchase"
                    />
                  </span>
                  {purchaseName}
                </td>
              )}{" "}
          </tr>
        );
      })
    : null;

  if (!commodities || commodities.length === 0) {
    return (
      <>
        <SubTitle small> </SubTitle>
        <Module noMargin>
          <Empty
            title={emptyTitle}
            icon={<FontAwesomeIcon icon={faSeedling} size="1x" />}
          >
            <FormattedMessage
              id="CommodityShow.noCommoditiesAddedYet"
              defaultMessage="No commodities added yet"
            />
          </Empty>
        </Module>
      </>
    );
  }
  return (
    <div className="commodities-show">
      {title && <SubTitle small>{title}</SubTitle>}
      <Module noMargin>
        <ModuleBody noPadding>
          <TableExtended noBorder>
            {/* TODO: get headers from parent, don't assume `rows` shape */}
            <thead>
              <tr>
                <th>
                  <FormattedMessage
                    id="CommodityShow.commodityTitle"
                    defaultMessage="Commodity"
                  />
                </th>
                {model === "delivery" && (
                  <th>
                    <FormattedMessage
                      id="CommodityShow.batch_no"
                      defaultMessage="Batch number"
                    />
                  </th>
                )}

                {(model === "attendance" || model === "delivery") && (
                  <th>
                    <FormattedMessage
                      id="CommodityShow.source"
                      defaultMessage="Source"
                    />
                  </th>
                )}
                {model === "delivery" && type !== "Carry-over" && (
                  <th>
                    <FormattedMessage
                      id="CommodityShow.quality"
                      defaultMessage="Quality"
                    />
                  </th>
                )}
                <th>
                  <FormattedMessage
                    id="CommodityShow.quantity"
                    defaultMessage="Quantity"
                  />
                </th>
                {model === "delivery" && type !== "Carry-over" && (
                  <th>
                    <FormattedMessage
                      id="CommodityShow.comments"
                      defaultMessage="Comments"
                    />
                  </th>
                )}
                {model === "purchasedetail" && (
                  <th>
                    <FormattedMessage
                      id="PriceShow.price"
                      defaultMessage="Amount paid"
                    />
                    {` (${defaultCurrencyName})`}
                  </th>
                )}
                {model === "incident" && (
                  <th>
                    <FormattedMessage
                      id="IncidentShow.waybill"
                      defaultMessage="Waybill number"
                    />
                  </th>
                )}
              </tr>
            </thead>
            <tbody>{rows}</tbody>{" "}
            {model === "purchasedetail" && (
              <thead>
                <th>
                  <FormattedMessage
                    id="TotalPriceShow.totalPrice"
                    defaultMessage="Total amount paid"
                  />
                  {` (${defaultCurrencyName})`}
                </th>
                <td> {} </td>
                <td> {commoditiesTotalPaidSum} </td>
              </thead>
            )}
          </TableExtended>
        </ModuleBody>
      </Module>
    </div>
  );
}
