import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSync, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";

import "./_syncIcon.scss";

const SyncIcon = ({ isSynced, type }) => {
  return isSynced ? (
    <div className="sync-icon sync-icon--synced">
      <FormattedMessage id="SyncIcon.synced" defaultMessage="Synced" />
      <FontAwesomeIcon icon={faCheck} />
    </div>
  ) : (
    <>
      {type === "delete" && (
        <div className="sync-delete">
          <FormattedMessage id="SyncIcon.deleted" defaultMessage="Deleted" />
          <FontAwesomeIcon icon={faTimes} />
        </div>
      )}
      <div className="sync-icon">
        <FormattedMessage id="SyncIcon.notSynced" defaultMessage="Not synced" />
        <FontAwesomeIcon icon={faSync} />
      </div>
    </>
  );
};

export default SyncIcon;
