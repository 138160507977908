import React from "react";
import { Input } from "@wfp/ui";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

export default function TimeInput(props) {
  return (
    <Input
      type="time"
      {...props}
      value={props.value}
      formItemClassName={styles.dateInput}
      /* placeholder=""
        defaultValue=""*/
    >
      {(props) => {
        return (
          <div className={styles.dateInputWrapper}>
            <div className={styles.icon}>
              <FontAwesomeIcon icon={faClock} />
            </div>
            <input {...props} ref={props.inputRef} />
          </div>
        );
      }}
    </Input>
  );
}
