import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { iconWfpLogoVerticalEn } from "@wfp/icons";
import {
  Button,
  Icon,
  Link,
  SubNavigationGroup,
  SubNavigationItem,
  SubNavigationList,
  Checkbox,
} from "@wfp/ui";

import PageTitle from "../PageTitle";

import styles from "./registration.module.scss";

import { changeLanguage } from "../../data-handler/ducks/language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { requestRegistration } from "../../data-handler/ducks/registration";
import {
  getTermsStatement,
  getPrivacyStatement,
  requestTermsStatement,
  requestPrivacyStatement,
} from "data-handler/ducks/statements";
import { getLanguage } from "data-handler/ducks/language";

import { languagesOptions } from "SCConstants";

const TermsAndConditionsPage = ({ data, endpoint, onBackToDataEntry }) => {
  const intl = useIntl();
  const language = useSelector(getLanguage);
  const terms = useSelector(getTermsStatement);
  const privacy = useSelector(getPrivacyStatement);
  const dispatch = useDispatch(language);
  const history = useHistory();

  const { handleSubmit, register, watch } = useForm();
  const acceptTerms = watch("register_terms");

  const onSubmit = async () => {
    const regdata = data ? data : undefined;
    const regEndpoint = endpoint ? endpoint : undefined;
    dispatch(requestRegistration(regdata, regEndpoint, history));
  };

  useEffect(() => {
    dispatch(requestTermsStatement(intl.locale));
    dispatch(requestPrivacyStatement(intl.locale));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.register}>
      <div className={styles.termsContent}>
        <div className={styles.loginLogo}>
          <Icon icon={iconWfpLogoVerticalEn} />
        </div>
        <PageTitle>
          <FormattedMessage
            id="user.welcomeText"
            defaultMessage="Welcome to School Connect by the World Food Programme"
          />
        </PageTitle>
        <div className={styles.backLink}>
          <Button
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
            iconReverse
            kind="secondary"
            onClick={() => onBackToDataEntry()}
          >
            <FormattedMessage
              id="TermsAndConditionsPage.back"
              defaultMessage="Back"
            />
          </Button>
        </div>
        <SubNavigationList>
          <SubNavigationGroup>
            {languagesOptions.map((language) => (
              <SubNavigationItem>
                <Link
                  onClick={() => {
                    dispatch(changeLanguage(language.value));
                  }}
                >
                  {language.label} <i>({language.labelTranslated})</i>
                </Link>
              </SubNavigationItem>
            ))}
          </SubNavigationGroup>
        </SubNavigationList>
        <div>
          <br />
          <div dangerouslySetInnerHTML={{ __html: terms }} />
          <div dangerouslySetInnerHTML={{ __html: privacy }} />
        </div>
        <div>
          <form>
            <Checkbox
              labelText={
                <FormattedMessage
                  id="Registration.acceptTerms"
                  defaultMessage="I accept legal terms and conditions"
                />
              }
              id="register_terms"
              name="register_terms"
              defaultValue=""
              inputRef={register}
            />
            <Button
              type="submit"
              disabled={!acceptTerms}
              onClick={handleSubmit(onSubmit)}
            >
              <FormattedMessage
                id="Registration.submitButton"
                defaultMessage="Submit registration"
              />
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
