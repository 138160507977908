/**
 * Selectors useful for aggregating report data
 */
import { createSelector } from "reselect";
import moment from "moment";
import {
  Attendance,
  Delivery,
  DeliveryCommodity,
  getAllPurchases,
  getAllStores,
  getSchoolYearByDate,
  Incident,
  Purchase,
  Report,
  Store,
} from "data-handler/ducks/stores";
import commoditiesDuck from "data-handler/ducks/commodities";
import { getReportByYearMonthDay } from "data-handler/ducks/reports";
import { deliveryCategory, purchaseDetailCategory } from "SCConstants";
import { deliveriesInStockByCommodityByCategory } from "helpers/stock";
import { RootState } from "data-handler/rootReducer";

// TODO: shouldn't this return stores?
export const getReportedStores = createSelector([getAllStores], (stores) => {
  const reports: Report[] = stores.filter((store) => store.model === "report");
  const output: { [key: string]: string } = {};
  reports.forEach((report: any) => {
    const date0: moment.Moment = moment(report.date).startOf("month");
    const date1: moment.Moment = moment(report.date).endOf("month");

    const relatedReports = stores.filter((store: any) =>
      moment(
        store.occurred_on || store.occurred_at || store.delivered_at
      ).isBetween(date0, date1, "days", "[]")
    );

    relatedReports.forEach((element: any) => {
      output[element.client_id] = report.client_id;
    });
  });

  return output;
});

export const getPurchasedStocksByDate = (
  state: RootState,
  date: string,
  commodityCategory: string,
  initialStock: boolean = false
) => {
  const schoolYear = getSchoolYearByDate(date)(state);

  // This variable is used to only include initial stock deliveries if
  // the given end date has the same month as the school year start date
  const firstMonthOfTheSchoolYear =
    moment(date).isSame(schoolYear?.starts_on, "month") && initialStock;

  const attendances: Attendance[] = getAllStores(state).filter((store) =>
    firstMonthOfTheSchoolYear
      ? false
      : store.model === "attendance" &&
        store.consumption &&
        store.consumption.meal_provided === true &&
        moment(store.occurred_on).isBetween(
          schoolYear?.starts_on,
          date,
          "days",
          initialStock ? "[)" : "[]"
        )
  ) as Attendance[];

  const purchasesIncidents = getAllStores(state).filter((store) =>
    firstMonthOfTheSchoolYear
      ? false
      : store.model === "incident" &&
        store.type !== "delete" &&
        moment(store.occurred_at).isBetween(
          schoolYear?.starts_on,
          date,
          "days",
          initialStock ? "[)" : "[]"
        )
  ) as Incident[];

  const purchases = getAllStores(state).filter((store) =>
    firstMonthOfTheSchoolYear
      ? // TODO: uncomment this once we allow carry over for purchased stock (replace false)
        // store.model === "purchasedetail" &&
        // store.type !== "delete" &&
        // store.is_initial_stock === true &&
        // moment(store.delivered_at).isSame(date, "day")
        false
      : store.model === "purchasedetail" &&
        store.type !== "delete" &&
        moment(store.purchased_at).isBetween(
          schoolYear?.starts_on,
          date,
          "days",
          initialStock ? "[)" : "[]"
        )
  ) as Purchase[];

  const accumulator: { [key: number]: number } = {};
  // Subtract consumption
  attendances.forEach((attendance) => {
    attendance.consumption!.commodities!.forEach(
      ({ commodity, quantity, category }) => {
        if (category === commodityCategory) {
          accumulator[commodity] = parseFloat(
            ((accumulator[commodity] || 0) - parseFloat(quantity)).toFixed(10)
          );
        }
      }
    );
  });

  purchases.forEach((stock) => {
    stock.commodities.forEach(({ commodity, quantity, category }) => {
      if (category === commodityCategory) {
        accumulator[commodity] = parseFloat(
          ((accumulator[commodity] || 0) + parseFloat(quantity)).toFixed(10)
        );
      }
    });
  });
  // Subtract incidents
  purchasesIncidents.forEach((incident) => {
    incident.commodities.forEach(({ commodity, quantity, category }) => {
      if (category === commodityCategory) {
        accumulator[commodity] = parseFloat(
          ((accumulator[commodity] || 0) - parseFloat(quantity as any)).toFixed(
            10
          )
        );
      }
    });
  });

  return accumulator;
};

/**
 * Returns a snapshot of the current school's stock on the given `date`
 *
 * in the shape of: { <commodity_id>: <commodity_quantity>, ... }
 */
export const getStocksByDate = (
  state: RootState,
  date: string,
  commodityCategory: string,
  initialStock: boolean = false
) => {
  const schoolYear = getSchoolYearByDate(date)(state);

  // This variable is used to only include initial stock deliveries if
  // the given end date has the same month as the school year start date
  const firstMonthOfTheSchoolYear =
    moment(date).isSame(schoolYear?.starts_on, "month") && initialStock;

  const deliveries = getAllStores(state).filter((store) =>
    firstMonthOfTheSchoolYear
      ? store.model === "delivery" &&
        store.type !== "delete" &&
        (store as any).is_initial_stock === true &&
        moment(store.delivered_at).isSame(date, "day")
      : store.model === "delivery" &&
        store.type !== "delete" &&
        moment(store.delivered_at).isBetween(
          schoolYear?.starts_on,
          date,
          "days",
          initialStock ? "[)" : "[]"
        )
  ) as Delivery[];

  const attendances = getAllStores(state).filter((store) =>
    firstMonthOfTheSchoolYear
      ? false
      : store.model === "attendance" &&
        store.consumption &&
        store.consumption.meal_provided === true &&
        moment(store.occurred_on).isBetween(
          schoolYear?.starts_on,
          date,
          "days",
          initialStock ? "[)" : "[]"
        )
  ) as Attendance[];

  const incidents = getAllStores(state).filter((store) =>
    firstMonthOfTheSchoolYear
      ? false
      : store.model === "incident" &&
        store.type !== "delete" &&
        moment(store.occurred_at).isBetween(
          schoolYear?.starts_on,
          date,
          "days",
          initialStock ? "[)" : "[]"
        )
  ) as Incident[];

  const accumulator: { [key: number]: number } = {};
  // Add deliveries
  deliveries.forEach((stock) => {
    stock.commodities.forEach(({ commodity, quantity, category }) => {
      if (category === commodityCategory) {
        accumulator[commodity] = parseFloat(
          ((accumulator[commodity] || 0) + parseFloat(quantity)).toFixed(10)
        );
      }
    });
  });
  // Subtract consumption
  attendances.forEach((attendance) => {
    attendance.consumption!.commodities!.forEach(
      ({ commodity, quantity, category }) => {
        if (category === commodityCategory) {
          accumulator[commodity] = parseFloat(
            ((accumulator[commodity] || 0) - parseFloat(quantity)).toFixed(10)
          );
        }
      }
    );
  });

  // Subtract incidents
  incidents.forEach((incident) => {
    incident.commodities.forEach(({ commodity, quantity, category }) => {
      if (category === commodityCategory) {
        accumulator[commodity] = parseFloat(
          ((accumulator[commodity] || 0) - parseFloat(quantity as any)).toFixed(
            10
          )
        );
      }
    });
  });

  return accumulator;
};

/**
 * Returns a snapshot of the stock deliveries within given dates
 */
export const getDeliveriesWithinDates = (
  state: RootState,
  date0: string,
  date1: string,
  includeDeleted = false,
  category = deliveryCategory
) => {
  const deliveriesWithinDates = getAllStores(state).filter(
    (store: any) =>
      store.model === "delivery" &&
      (!includeDeleted ? store.type !== "delete" : true) &&
      store.is_negative_stock_rebalance === false &&
      store.is_initial_stock === false &&
      moment(store.delivered_at).isBetween(date0, date1, "days", "[]")
  ) as Delivery[];

  const accumulator = deliveriesWithinDates.reduce((acc, curr) => {
    curr.commodities.forEach(
      ({ commodity, quantity, category: commCategory }) => {
        if (commCategory === category || category === "all") {
          acc[commodity] = (acc[commodity] || 0) + Number(quantity);
        }
      }
    );
    return acc;
  }, {} as { [key: number]: number });

  return accumulator;
};

/**
 * Returns a snapshot of the stock purchases within given dates
 */
export const getPurchasedCommoditiesWithinDates = (
  state: RootState,
  date0: string,
  date1: string,
  detailType: string
) => {
  const purchasesWithinDates = getAllStores(state).filter(
    (store) =>
      store.model === "purchasedetail" &&
      store.category === purchaseDetailCategory &&
      store.type !== "delete" &&
      moment(store.purchased_at).isBetween(date0, date1, "days", "[]")
  ) as Purchase[];
  var detail;

  const accumulator = purchasesWithinDates.reduce((acc, curr) => {
    curr.commodities.forEach(
      // TODO Purchase Commodity do not have type comments but it has comment
      // this might be a bug
      ({ commodity, quantity, total_paid, comments }: any) => {
        switch (detailType) {
          case "total_paid":
            detail = Number(total_paid);
            break;
          case "quantity":
            detail = Number(quantity);
            break;
          case "date":
            // TODO purchase do not have a data this might be a bug
            detail = String(
              moment((curr as any).date).format("DD-MM-YYYY") + ", "
            );
            break;
          case "comments":
            detail = comments;
            break;
          default:
            detail = "";
        }

        if (!detail) {
          return acc;
        }
        if (acc[commodity] === undefined) {
          acc[commodity] = detail;
        } else {
          acc[commodity] = acc[commodity] + detail;
        }
      }
    );
    return acc;
  }, {} as { [key: number]: string });

  return accumulator;
};

/**
 * Returns a snapshot of the stock purchases within given dates
 */
export const getOtherCostWithinDates = (
  state: RootState,
  date0: string,
  date1: string,
  detailType: string
) => {
  const purchasesWithinDates = getAllStores(state).filter(
    (store) =>
      store.model === "purchasedetail" &&
      store.type !== "delete" &&
      moment(store.purchased_at).isBetween(date0, date1, "days", "[]")
  ) as Purchase[];

  var detail;

  const accumulator = purchasesWithinDates?.reduce((acc, curr: any) => {
    // TODO Purchase does not have other costs
    if (curr.other_costs) {
      curr.other_costs.forEach(({ other_cost, total_paid, comments }: any) => {
        switch (detailType) {
          case "total_paid":
            detail = Number(total_paid);
            break;
          case "date":
            detail = String(moment(curr.date).format("DD-MM-YYYY") + ", ");
            break;
          case "comments":
            detail = comments;
            break;
          default:
            detail = "";
        }
        if (!detail) {
          return acc;
        }
        if (acc[other_cost] === undefined) {
          acc[other_cost] = detail;
        } else {
          acc[other_cost] = acc[other_cost] + detail;
        }
      });
    }
    return acc;
  }, {} as { [key: string]: string });
  return accumulator;
};

export const getPurchaseDetails = (
  state: RootState,
  date0: string,
  date1: string,
  detailType: string
) => {
  const purchasesWithinDates = getAllPurchases(state).filter(
    (store) =>
      store.model === "purchasedetail" &&
      store.type !== "delete" &&
      moment(store.purchased_at).isBetween(date0, date1, "days", "[]")
  ) as Purchase[];
  var detail;

  const commodityId = purchasesWithinDates.reduce((acc, curr) => {
    curr.commodities.forEach(({ commodity }) => {
      acc = commodity;
    });
    return acc;
  }, undefined as number | undefined);

  const accumulator = purchasesWithinDates.reduce((acc, curr) => {
    // TODO purchase do not have property date
    detailType === "date"
      ? (detail = String((curr as any).date))
      : (detail = "N/A");
    acc[commodityId!] = detail;
    return acc;
  }, {} as { [key: string]: string });
  return accumulator;
};

/**
 * Returns a snapshot of the stock consumption within given dates
 */
export const getConsumptionWithinDates = (
  stores: (state: RootState) => Store[],
  state: RootState,
  date0: string,
  date1: string,
  category: string
) => {
  const attendances = stores(state).filter(
    (store) =>
      store.model === "attendance" &&
      store.consumption &&
      store.consumption.meal_provided === true &&
      moment(store.occurred_on).isBetween(date0, date1, "days", "[]")
  ) as Attendance[];

  const accumulator = attendances.reduce((acc, curr) => {
    curr.consumption!.commodities!.forEach(
      ({ commodity, quantity, category: commodityCategory }) => {
        if (commodityCategory === category) {
          acc[commodity] = (acc[commodity] || 0) + Number(quantity);
        }
      }
    );
    return acc;
  }, {} as { [key: number]: number });

  return accumulator;
};

/**
 * Returns a snapshot of the stock consumption within given dates
 */
export const getIncidentsWithinDates = (
  stores: (state: RootState) => Store[],
  state: RootState,
  date0: string,
  date1: string,
  category: string,
  includeDeleted: boolean = false
) => {
  const incidents = stores(state).filter(
    (store) =>
      store.model === "incident" &&
      (!includeDeleted ? store.type !== "delete" : true) &&
      !store.is_initial_stock_incident &&
      moment(store.occurred_at).isBetween(date0, date1, "days", "[]")
  ) as Incident[];

  const accumulator = incidents.reduce((acc, curr) => {
    curr.commodities.forEach(
      ({ commodity, quantity, category: commodityCategory }) => {
        if (commodityCategory === category) {
          acc[commodity] = (acc[commodity] || 0) + Number(quantity);
        }
      }
    );
    return acc;
  }, {} as { [key: number]: number });

  return accumulator;
};

/**
 * An incident is considered a loss in any case unless it's a return
 */
const isIncidentLoss = (incident: Incident) => !isIncidentReturn(incident);

/**
 * An incident is considered a return if "Food will be returned to WFP"
 * is one of its causes.
 */
const isIncidentReturn = (incident: Incident) => {
  // Reason 1 = Loss
  // Reason 2 = return
  // Defined in incidentEdit/index.js:79 "incidentTypes"
  return parseInt(incident.reason) === 2;
};

/**
 * Returns a snapshot of the stock losses within given dates
 */
export const getLossesWithinDates = (
  state: RootState,
  date0: string,
  date1: string,
  category: string
) => {
  const lossesWithinDates = getAllStores(state).filter(
    (store) =>
      store.model === "incident" &&
      store.type !== "delete" &&
      isIncidentLoss(store) &&
      moment(store.occurred_at).isBetween(date0, date1, "days", "[]")
  ) as Incident[];

  const accumulator = lossesWithinDates.reduce((acc, curr) => {
    curr.commodities.forEach(
      ({ commodity, quantity, category: commodityCategory }) => {
        if (commodityCategory === category) {
          acc[commodity] = (acc[commodity] || 0) + Number(quantity);
        }
      }
    );
    return acc;
  }, {} as { [key: number]: number });

  return accumulator;
};

/**
 * Returns a snapshot of the stock returns to WFP within given dates
 */
export const getReturnsWithinDates = (
  state: RootState,
  date0: string,
  date1: string,
  category: string
) => {
  const returnsWithinDates = getAllStores(state).filter(
    (store) =>
      store.model === "incident" &&
      store.type !== "delete" &&
      isIncidentReturn(store) &&
      moment(store.occurred_at).isBetween(date0, date1, "days", "[]")
  ) as Incident[];

  const accumulator = returnsWithinDates.reduce((acc, curr) => {
    curr.commodities.forEach(
      ({ commodity, quantity, category: commodityCategory }) => {
        if (commodityCategory === category) {
          acc[commodity] = (acc[commodity] || 0) + Number(quantity);
        }
      }
    );
    return acc;
  }, {} as { [key: number]: number });

  return accumulator;
};

/**
 * Returns a snapshot of the stock deliveries batch numbers within given dates
 */
export const getBatchNumbersWithinDates = (
  state: RootState,
  date0: string,
  date1: string,
  previousReportDates: {
    year: string;
    month: string;
    startDay: number;
    endDay: number;
  },
  commodities: DeliveryCommodity[],
  category: string
) => {
  const allStores = getAllStores(state);

  // The end date of the previous report
  const previousReportDate1 = previousReportDates
    ? moment(
        `${previousReportDates.year}/${previousReportDates.month}/${previousReportDates.endDay}`
      )
    : undefined;

  const deliveries = getAllStores(state).filter(
    (store) =>
      store.model === "delivery" &&
      store.type !== "delete" &&
      moment(store.delivered_at).isBetween(date0, date1, "days", "[]")
  ) as Delivery[];

  const batch_nos: { [commodityId: string]: string[] } = {};

  const allCommodities: DeliveryCommodity[] = commoditiesDuck.getList(state);

  allCommodities.forEach((commodity) => {
    batch_nos[commodity.id] = [];
  });

  deliveries.forEach((delivery) => {
    delivery.commodities.forEach(
      ({ commodity, batch_no, category: commCategory }) => {
        if (
          category === commCategory &&
          !batch_nos[commodity].includes(batch_no)
        ) {
          batch_nos[commodity].push(batch_no);
        }
      }
    );
  });

  // Add batch numbers from previous month for commodities that
  // were not fully consumed in that previous month
  commodities.forEach((commodity) => {
    deliveriesInStockByCommodityByCategory(
      allStores,
      (commodity.id as unknown) as number,
      category,
      undefined,
      previousReportDate1 as any,
      previousReportDate1 as any
    ).forEach((item: any) => {
      if (
        parseFloat(item.quantityAvailable) > 0 &&
        !batch_nos[commodity.id].includes(item.batch_no)
      ) {
        batch_nos[commodity.id].push(item.batch_no);
      }
    });
  });

  return batch_nos;
};

/**
 * Returns a snapshot of the stock deliveries batch numbers within given dates
 */
export const getOtherCostsWithinDates = (
  state: RootState,
  date0: string,
  date1: string
) => {
  const deliveries = getAllStores(state).filter(
    (store) =>
      store.model === "purchasedetail" &&
      store.type !== "delete" &&
      moment(store.purchased_at).isBetween(date0, date1, "days", "[]")
  ) as Purchase[];

  const dates: { [commodityId: string]: string[] } = {};

  const otherCosts: DeliveryCommodity[] = commoditiesDuck.getList(state);
  otherCosts.forEach((commodity) => {
    dates[commodity.id] = [];
  });

  deliveries.forEach((delivery) => {
    delivery.commodities.forEach(({ commodity, batch_no }) => {
      dates[commodity].push(batch_no);
    });
  });

  return dates;
};

/**
 * Returns true if the report specific to the given date is open, or if
 * there is no report but there is a school year ongoing for the given date
 *
 * Otherwise, false
 */
export const isReportOpenByDate = (date: string | undefined = undefined) => (
  state: RootState
) => {
  if (!date) {
    date = moment().format("YYYY-MM-DD");
  }

  const splitDate = date?.split("-");
  const year = splitDate ? parseInt(splitDate[0]) : undefined;
  const month = splitDate ? parseInt(splitDate[1]) : undefined;
  const day = splitDate ? parseInt(splitDate[2]) : undefined;

  const report = getReportByYearMonthDay(state, year!, month!, day!);

  const currentSchoolYear = getSchoolYearByDate(date)(state);

  return (!report && currentSchoolYear) || report?.state === "open"
    ? true
    : false;
};
