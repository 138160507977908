import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Controller, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import Select from "react-select";

import { faWheatAwn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TextInput, Input } from "@wfp/ui";

import QuantityInput from "components/QuantityInput";
import CurrencyInput from "components/CurrencyInput";

import RepeaterAddButton from "components/RepeaterAddButton";
import Empty from "components/Empty";
import CommoditySelect from "components/CommoditySelect";
import { Repeater, RepeaterItem } from "components/Repeater";
import CommoditiesAddButton from "components/RepeaterAddButton";
import Camera from "components/Camera";

import {
  getPurchaseOtherCostsList,
  requestPurchaseOtherCosts,
} from "data-handler/ducks/purchaseOtherCosts";

import { getIsOnlineMode } from "data-handler/ducks/auth";

import { purchaseDetailCategory } from "SCConstants";
import "./_cash-to-school.scss";

function otherCosts(list) {
  return list.map((e) => {
    return {
      value: e.id,
      label: e.name,
    };
  });
}

function calculateTotal(list = []) {
  return list.reduce((acc, cur) => {
    return parseFloat(cur.total_paid) + acc;
  }, 0);
}

function updateGrandTotal(getValues, setValue) {
  const commodities = getValues()["commodities"]; // List
  const otherCosts = getValues()["other_costs"]; // list
  const commodityTotal = calculateTotal(commodities);
  const otherCostsTotal = calculateTotal(otherCosts);
  const total =
    (isNaN(commodityTotal) ? 0 : commodityTotal) +
    (isNaN(otherCostsTotal) ? 0 : otherCostsTotal);
  setValue(`total_paid`, total);
}

const CommoditiesEdit = ({
  errors,
  control,
  setValue,
  watch,
  getValues,
  register,
}) => {
  const purchaseOtherCosts = useSelector(getPurchaseOtherCostsList);
  const isOnlineMode = useSelector(getIsOnlineMode);

  const [refreshKey, setRefreshKey] = useState(new Date().valueOf());
  const dispatch = useDispatch();
  useEffect(() => {
    if (isOnlineMode) {
      dispatch(requestPurchaseOtherCosts());
    }
  }, [dispatch, isOnlineMode]);

  var total = watch(`total_paid`);

  useEffect(() => {
    setRefreshKey(new Date().valueOf());
    setValue(`total_paid`, total);
  }, [setValue, total]);

  const purchaseOtherCostsOptions = purchaseOtherCosts
    ? otherCosts(Object.values(purchaseOtherCosts?.results))
    : undefined;

  const requiredLabelCommodities = (
    <div
      className="wfp--form-required"
      styles={{ borderColor: " 2px solid #C5192D" }}
    >
      <FormattedMessage
        id="PurchaseEdit.commodityError"
        defaultMessage=" Select commodity"
      />
    </div>
  );

  let {
    fields: commodityRows,
    append: appendCommodity,
    remove: removeCommodity,
  } = useFieldArray({
    control,
    name: "commodities",
  });

  let {
    fields: purchaseRows,
    append: appendOtherCosts,
    remove: removeOtherCosts,
  } = useFieldArray({
    control,
    name: "other_costs",
  });

  return (
    <div className="commodities-section">
      <div className="wfp--form-item wfp--form-item--invalid commodity-select">
        {commodityRows.length === 0 && (
          <Empty
            title="No commodities added yet"
            icon={<FontAwesomeIcon icon={faWheatAwn} size="1x" />}
            message={requiredLabelCommodities}
          >
            <FormattedMessage
              id="PurchaseEdit.addACommodityForThisDelivery"
              defaultMessage="Add a commodity for this purchase"
              message={requiredLabelCommodities}
            />
          </Empty>
        )}
        {commodityRows.length === 0 && (
          <Empty
            title="No commodities added yet"
            icon={<FontAwesomeIcon icon={faWheatAwn} size="1x" />}
            message={requiredLabelCommodities}
          >
            <FormattedMessage
              id="PurchaseEdit.addCommodityForThisDelivery"
              defaultMessage="Add a commodity for this purchase"
              message={requiredLabelCommodities}
            />
          </Empty>
        )}
        <Repeater>
          {commodityRows.map((commodityRow, index) => (
            <RepeaterItem
              key={commodityRow.id}
              removeAction={() => removeCommodity(index)}
              disableRemove={commodityRows.length === 1}
              className="purchase-edit__commodities"
            >
              <Controller
                control={control}
                render={({ onChange, value }) => (
                  <Camera onChange={onChange} image={value} />
                )}
                name={`commodities[${index}].picture`}
              />
              <div className="container">
                <Controller
                  render={({ onChange, value }) => {
                    return (
                      <CommoditySelect
                        className="wfp--react-select-container"
                        classNamePrefix="wfp--react-select"
                        required
                        category={purchaseDetailCategory}
                        key={`commodities[${index}].commodity`}
                        onChange={(e) => {
                          setValue(
                            `commodities[${index}].measure_unit`,
                            e.measure_unit
                          );
                          onChange(e.value);
                        }}
                        value={value}
                        rules={{ required: true }}
                        inputRef={register}
                        invalidOverride={
                          errors[`commodities[${index}].commodity`] !==
                          undefined
                        }
                        invalidTextOverride={requiredLabelCommodities}
                      />
                    );
                  }}
                  name={`commodities[${index}].commodity`}
                  control={control}
                />

                <Controller
                  render={({ onChange, onBlur, value }) => {
                    return (
                      <QuantityInput
                        control={control}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        labelText={
                          <FormattedMessage
                            id="PurchaseEdit.quantity"
                            defaultMessage="Quantity"
                          />
                        }
                        setValue={setValue}
                        name={`commodities[${index}].quantity`}
                        invalid={errors[`commodities[${index}].quantity`]}
                        invalidText={
                          errors[`commodities[${index}].quantity`]?.message
                        }
                        measureUnit={watch(
                          `commodities[${index}].measure_unit`
                        )}
                      />
                    );
                  }}
                  control={control}
                  name={`commodities[${index}].quantity`}
                />

                <Controller
                  render={({ value }) => {
                    return (
                      <TextInput
                        labelText={
                          <FormattedMessage
                            id="PurchaseEdit.unit"
                            defaultMessage="Unit"
                          />
                        }
                        value={value?.name ?? "Kilogram"}
                        disabled
                      ></TextInput>
                    );
                  }}
                  invalid={errors[`commodities[${index}].measure_unit`]}
                  invalidText={
                    errors[`commodities[${index}].measure_unit`]?.message
                  }
                  name={`commodities[${index}].measure_unit`}
                  control={control}
                />

                <Controller
                  render={({ onChange, value }) => (
                    <CurrencyInput
                      value={value}
                      onChange={(onChangeValue) => {
                        onChange(onChangeValue ? onChangeValue : 1);
                        updateGrandTotal(getValues, setValue);
                      }}
                      labelText={
                        <FormattedMessage
                          id="PurchaseEdit.totalPaid"
                          defaultMessage="Total amount paid"
                        />
                      }
                      setValue={setValue}
                      control={control}
                      name={`commodities[${index}].total_paid`}
                      invalid={errors[`commodities[${index}].total_paid`]}
                      invalidText={
                        errors[`commodities[${index}].total_paid`]?.message
                      }
                    />
                  )}
                  control={control}
                  name={`commodities[${index}].total_paid`}
                />
              </div>
              <Controller
                as={
                  <TextInput
                    labelText={
                      <>
                        <FormattedMessage
                          id="PurchaseEdit.comments"
                          defaultMessage="comments"
                        />{" "}
                        <FormattedMessage
                          id="Form.optional"
                          defaultMessage="(optional)"
                        />
                      </>
                    }
                  />
                }
                name={`commodities[${index}].comments`}
              />
            </RepeaterItem>
          ))}
        </Repeater>
        <CommoditiesAddButton
          onClick={() => {
            appendCommodity({
              issues: [],
            });
          }}
        />{" "}
      </div>
      <div className="wfp--form-item wfp--form-item--invalid other-costs">
        <Repeater>
          {purchaseRows.map((purchaseRow, index) => (
            <RepeaterItem
              key={purchaseRow.id}
              removeAction={() => removeOtherCosts(index)}
              disableRemove={purchaseRows.length === 1}
              className="purchase-edit__commodities"
            >
              <div className="container">
                <Controller
                  render={({ onChange, value }) => {
                    return (
                      <Input
                        labelText={
                          <FormattedMessage
                            id="PurchaseEdit.otherCost"
                            defaultMessage="Other cost"
                          />
                        }
                      >
                        {() => (
                          <Select
                            className="wfp--react-select-container"
                            classNamePrefix="wfp--react-select"
                            options={purchaseOtherCostsOptions}
                            defaultValue={value}
                            placeholder="Select ..."
                            control={control}
                            onChange={(e) => {
                              onChange(e.value);
                            }}
                          />
                        )}
                      </Input>
                    );
                  }}
                  name={`other_costs[${index}].other_cost`}
                />
                <Controller
                  className="purchaseEdit-total"
                  render={({ onChange, value }) => (
                    <CurrencyInput
                      value={value}
                      onChange={(onChangeValue) => {
                        onChange(onChangeValue ? onChangeValue : 1);
                        updateGrandTotal(getValues, setValue);
                      }}
                      labelText={
                        <FormattedMessage
                          id="PurchaseEdit.totalPaid"
                          defaultMessage="Total amount paid"
                        />
                      }
                      control={control}
                      setValue={setValue}
                      name={`other_costs[${index}].total_paid`}
                      invalid={errors[`other_costs[${index}].total_paid`]}
                      invalidText={
                        errors[`other_costs[${index}].total_paid`]?.message
                      }
                    />
                  )}
                  name={`other_costs[${index}].total_paid`}
                />
              </div>
              <Controller
                as={
                  <TextInput
                    labelText={
                      <>
                        <FormattedMessage
                          id="PurchaseEdit.comments"
                          defaultMessage="comments"
                        />{" "}
                        <FormattedMessage
                          id="Form.optional"
                          defaultMessage="(optional)"
                        />
                      </>
                    }
                  />
                }
                name={`other_costs[${index}].comments`}
              />
            </RepeaterItem>
          ))}
        </Repeater>
        <RepeaterAddButton
          text={
            <FormattedMessage
              id="PurchaseEdit.addOther"
              defaultMessage="Add other cost"
            />
          }
          onClick={() => {
            appendOtherCosts({});
          }}
        />
      </div>
      <div className="grand-total">
        <Controller
          render={({ value, onChange }) => (
            <CurrencyInput
              defaultValue={watch(`total_paid`)}
              value={watch(`total_paid`)}
              key={refreshKey}
              control={control}
              setValue={setValue}
              labelText={
                <FormattedMessage
                  id="PurchaseEdit.grandTotal"
                  defaultMessage="Grand total"
                />
              }
              name={"total_paid"}
              invalid={errors?.total_paid}
              invalidText={errors?.total_paid?.message}
            />
          )}
          readonly
          name={`total_paid`}
        />
      </div>
    </div>
  );
};

export default CommoditiesEdit;
