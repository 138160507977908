import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faEdit,
  faShare,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { Button, InlineLoading } from "@wfp/ui";

import { getCurrentUser, getLoggedInOffline } from "data-handler/ducks/auth";

import { getFilteredReportSignatures } from "data-handler/ducks/reportSignatures";
import {
  getIsAdmin,
  getIsCountryAdmin,
  getIsPrincipal,
  getIsSchoolGroupAdmin,
  getIsStoreKeeper,
} from "helpers/users";
import { Report, ReportAction } from "data-handler/ducks/stores";

export enum OfflineReportAction {
  SIGN = "sign",
  CLOSE = "close",
  SUBMIT = "submit",
}

/** Translatable labels for each of the allowed report actions */
const ACTION_LABELS: Record<ReportAction | OfflineReportAction, JSX.Element> = {
  amend: <FormattedMessage id="Report.actionAmend" defaultMessage="Amend" />,
  approve: (
    <FormattedMessage id="Report.actionApprove" defaultMessage="Approve" />
  ),
  close: <FormattedMessage id="Report.actionClose" defaultMessage="Close" />,
  reject: <FormattedMessage id="Report.actionReject" defaultMessage="Reject" />,
  reopen: <FormattedMessage id="Report.actionReopen" defaultMessage="Reopen" />,
  reopen_approved: (
    <FormattedMessage
      id="Report.actionReopenApproved"
      defaultMessage="Reopen"
    />
  ),
  reopen_validated: (
    <FormattedMessage
      id="Report.actionReopenValidated"
      defaultMessage="Reopen"
    />
  ),
  sign: <FormattedMessage id="Report.actionSign" defaultMessage="Sign" />,
  submit: (
    <FormattedMessage id="Report.actionButtonSubmit" defaultMessage="Submit" />
  ),
  update: <></>,
};

/** Appropriate icons for each of the allowed report actions */
const ACTION_ICONS: Record<ReportAction | OfflineReportAction, JSX.Element> = {
  amend: <FontAwesomeIcon icon={faEdit} />,
  approve: <FontAwesomeIcon icon={faCheck} />,
  close: <FontAwesomeIcon icon={faCheck} />,
  reject: <FontAwesomeIcon icon={faTimes} />,
  reopen: <FontAwesomeIcon icon={faUndo} />,
  reopen_approved: <FontAwesomeIcon icon={faUndo} />,
  reopen_validated: <FontAwesomeIcon icon={faUndo} />,
  sign: <FontAwesomeIcon icon={faEdit} />,
  submit: <FontAwesomeIcon icon={faShare} />,
  update: <></>,
};

/**
 * Fetches allowed actions for the given report, if possible
 */
const useFetchReportActions = (report: Report) => {
  const canFetch = !useSelector(getLoggedInOffline);
  const [isFetchingActions, setIsFetchingActions] = useState(false);
  const [actions, setActions] = useState<Array<ReportAction>>([]);

  // Fetch
  useEffect(() => {
    setActions([]); // Immediately clear actions

    const fetchActions = () => {
      if (!canFetch) {
        return;
      }
      setActions([]);
      setIsFetchingActions(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}/reports/${report.id}/actions/`)
        .then((response) => {
          setIsFetchingActions(false);
          // If 200, `response` contains data.actions, but let's check anyway
          if (response.data?.actions) {
            // Actions come from the BE as {label, command} records,
            // and must be unwrapped.
            const commands = response.data.actions.map(
              ({ command }: { command: ReportAction }) => command
            );
            setActions(commands);
          }
        });
    };

    if (report.id && !report.isPreview) {
      fetchActions();
    }
  }, [canFetch, report.id, report.isPreview, report.state]);

  // `update` action is BE-internal
  const actionsMinusUpdate = (actions || []).filter(
    (action) => action !== "update"
  );

  return { actions: actionsMinusUpdate, isFetchingActions };
};

/**
 * Covering the Burundi case, some "low accountability" report actions are available
 * to offline users sharing a device.
 */
const useLocalReportActions = (
  report: Report
): { localReportActions: OfflineReportAction[] } => {
  const currentUser = useSelector(getCurrentUser);
  const currentUserSignature = useSelector(
    getFilteredReportSignatures({
      userId: currentUser.id,
      reportMonth: report.month,
      reportYear: report.year,
      reportStartDay: report.start_day,
      reportEndDay: report.end_day,
    } as any)
  );

  const localReportActions: OfflineReportAction[] = [];

  const currentUserHasSigned = currentUserSignature.length !== 0;
  const isStoreKeeper = getIsStoreKeeper(currentUser);
  const isPrincipal = getIsPrincipal(currentUser);
  const isSchoolGroupAdmin = getIsSchoolGroupAdmin(currentUser);

  const signActionAvailable =
    (isPrincipal || isStoreKeeper || isSchoolGroupAdmin) &&
    report.state === "closed" &&
    !currentUserHasSigned;

  if (signActionAvailable) {
    localReportActions.push(OfflineReportAction.SIGN);
  }

  return {
    localReportActions,
  };
};

/**
 * Buttons which link to modals for each of the allowed report actions
 */

const ReportActionButtons = ({ report }: { report: Report }) => {
  const params = useParams<{
    item: string;
    schoolId: string;
  }>();
  const { actions, isFetchingActions } = useFetchReportActions(report);
  const { localReportActions } = useLocalReportActions(report);
  const currentUser = useSelector(getCurrentUser);

  let availableActions = [...actions, ...localReportActions];
  availableActions = availableActions.filter(
    (item, pos) => availableActions.indexOf(item) === pos
  );

  if (isFetchingActions) {
    return <InlineLoading />;
  }
  // If user is approver then always show available actions
  let showActions: Boolean =
    currentUser.is_approver ||
    getIsAdmin(currentUser) ||
    getIsCountryAdmin(currentUser)
      ? true
      : !(availableActions.length === 0 || report.negative_stock);

  if (!showActions) {
    return (
      <FormattedMessage
        id="Report.noActionsAvailable"
        defaultMessage="No actions available"
      />
    );
  }

  return (
    <>
      {availableActions.map((action) => (
        <NavLink
          key={action}
          to={`/school/${params.schoolId}/report/${params.item}/${action}`}
        >
          <Button icon={ACTION_ICONS[action]} style={{ marginLeft: 4 }}>
            {ACTION_LABELS[action]}
          </Button>
        </NavLink>
      ))}
    </>
  );
};

export default ReportActionButtons;
