import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faQuestion } from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Module,
  ModuleBody,
  List,
  ListItem,
  InlineLoading,
} from "@wfp/ui";

import { getReportedStores } from "helpers/report";

import CommodityShow from "components/CommodityShow";
import SubTitle from "components/SubTitle";
import Empty from "components/Empty";
import MainContent from "components/MainContent";
import DeleteModalButton from "components/DeleteModalButton";
import {
  getCurrentSchoolYear,
  getPreviousSchoolYears,
  getSchoolYearByDate,
  Incident,
} from "data-handler/ducks/stores";
import "./_incident-show.scss";
import Gate from "../Gate";
import { UserPermissionRoles } from "../../SCConstants";
import { getIsSyncableLocked } from "../../data-handler/ducks/stores";
import incidentCausesDuck from "data-handler/ducks/incidentCauses";
import { getModelDateFormat } from "components/UneditableMessage";
import { RootState } from "data-handler/rootReducer";
import {
  getIsReportLocked,
  getReportByMoment,
} from "data-handler/ducks/reports";

type IncidentShowProps = {
  currentStoreData: Incident;
};

type IncidentShowParams = {
  schoolId: string;
  item: string;
};

const IncidentShow: React.ElementType<IncidentShowProps> = ({
  currentStoreData,
}) => {
  const { schoolId, item }: IncidentShowParams = useParams();

  const history = useHistory();

  const reportedStores = useSelector(getReportedStores);

  const incidentCauses = useSelector(incidentCausesDuck.getList);
  const isReported = reportedStores[item];

  const incidentDateLabel = moment(currentStoreData.occurred_at).format(
    "dddd, DD.MM.YYYY"
  );
  const isLocked = useSelector((state: RootState) =>
    getIsSyncableLocked(state, currentStoreData)
  );
  const selectedIncidentSchoolYear = useSelector(
    getSchoolYearByDate(
      getModelDateFormat(currentStoreData) || moment().format("YYYY-MM-DD")
    )
  );

  const currentYear = useSelector(getCurrentSchoolYear); //users current year

  const yearStartsOn = currentYear && moment(currentYear.starts_on);
  const firstReport = useSelector((state: RootState) =>
    getReportByMoment(state, yearStartsOn)
  );

  const previousSchoolYears = useSelector(getPreviousSchoolYears);
  // TODO: define app URLs in a single place, this is unmaintainable
  const editUrl = `/school/${schoolId}/deliveries/${item}/incident`;

  if (!currentStoreData) {
    return (
      <Empty
        kind="section"
        icon={<FontAwesomeIcon icon={faQuestion} size="1x" />}
        title="Incidents"
        button={
          <Gate forbidRoles={[UserPermissionRoles.is_viewer]}>
            <NavLink to={editUrl}>
              {" "}
              <Button
                kind="accent"
                icon={<FontAwesomeIcon icon={faChevronRight} />}
                disabled={isReported}
              >
                <FormattedMessage
                  id="IncidentShow.provideIncidents"
                  defaultMessage="Provide incidents"
                />
              </Button>{" "}
            </NavLink>
          </Gate>
        }
      >
        <FormattedMessage
          id="IncidentShow.noIncidentsToday"
          defaultMessage="No incidents today"
        />
      </Empty>
    );
  }

  const causes = currentStoreData?.causes?.map((cause) =>
    incidentCauses?.find((e) => e?.id === cause)
  );

  const isInitialStockIncident = !!currentStoreData?.is_initial_stock_incident;
  const isIncidentInCurrentYear =
    currentStoreData && currentYear?.starts_on === currentStoreData.occurred_at;

  // We let users edit inital stock if
  // - They did not close the report for the first month.
  // - They are updating current school year.
  const isInitialIncidentEditable =
    currentStoreData &&
    isInitialStockIncident &&
    isIncidentInCurrentYear &&
    !getIsReportLocked(firstReport)!;

  // incident is editable if
  // - it is not an initial stock incident
  // - it is an editable initial stock incident that decided by rules above.
  const isIncidentEditable =
    isInitialIncidentEditable || !isInitialStockIncident;
  if (!causes) {
    return <InlineLoading />;
  }

  return (
    <MainContent title={incidentDateLabel} className="deliveries-content">
      <SubTitle
        additional={
          <Gate
            forbidRoles={[UserPermissionRoles.is_viewer]}
            isForbidden={
              isLocked ||
              (currentYear !== null &&
                currentYear !== selectedIncidentSchoolYear) ||
              (currentYear === null &&
                previousSchoolYears[0] !== selectedIncidentSchoolYear)
            }
          >
            {currentStoreData.type !== "delete" && (
              <DeleteModalButton
                currentStoreData={currentStoreData}
                disabled={!isIncidentEditable}
              />
            )}
            <Button
              className="edit-incident-button"
              kind="secondary"
              disabled={!isIncidentEditable}
              icon={<FontAwesomeIcon icon={faChevronRight} />}
              onClick={() => history.push(editUrl)}
            >
              <FormattedMessage
                id="IncidentShow.editIncident"
                defaultMessage="Edit incident"
              />
            </Button>
          </Gate>
        }
      >
        <FormattedMessage
          id="IncidentShow.incidentFrom"
          defaultMessage="Incident from {date}"
          values={{ date: incidentDateLabel }}
        />
      </SubTitle>
      <Module noMargin>
        <ModuleBody>
          <>
            <List kind="details" colon>
              <ListItem title="Date">{incidentDateLabel}</ListItem>
              <ListItem
                title={
                  <FormattedMessage
                    id="IncidentShow.reasons"
                    defaultMessage="Reasons"
                  />
                }
                className="reason-list"
              >
                {causes.map((cause) => (
                  <>
                    <span key={cause.id} className="reason-list__item">
                      {cause.name}
                    </span>
                  </>
                ))}
                {currentStoreData.causes.length === 0 && (
                  <span className="reason-list__item">
                    <FormattedMessage
                      id="Common.noReason"
                      defaultMessage="No reason"
                    />
                  </span>
                )}
              </ListItem>

              {currentStoreData.other_cause && (
                <ListItem
                  title={
                    <FormattedMessage
                      id="IncidentShow.other_cause"
                      defaultMessage="Other reason"
                    />
                  }
                >
                  {currentStoreData.other_cause}
                </ListItem>
              )}
            </List>
          </>
        </ModuleBody>
      </Module>

      <CommodityShow
        title={
          <FormattedMessage
            id="IncidentShow.affectedCommodities"
            defaultMessage="Affected commodities"
          />
        }
        emptyTitle={
          <FormattedMessage
            id="IncidentShow.noAffectedCommodities"
            defaultMessage="No affected commodities"
          />
        }
        model={currentStoreData.model}
        commodities={currentStoreData.commodities as any}
      />
    </MainContent>
  );
};

export default IncidentShow;
