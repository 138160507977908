import React from "react";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChalkboardTeacher } from "@fortawesome/free-solid-svg-icons";
import { Blockquote, InlineLoading } from "@wfp/ui";

import Empty from "components/Empty";
import ModalExtended from "components/ModalExtended";
import { RepeaterItem } from "components/Repeater";
import ItemSwitcher from "components/ItemSwitcher";
import SyncError from "components/SyncSelect/SyncError";

import {
  getUnsyncedStores,
  POSTStores,
  storesIsFetching,
  getAllAttendanceStoresByDay,
} from "data-handler/ducks/stores";

import {
  isMealAttendance,
  isStudentAttendance,
  isTakeHomeRationAttendance,
} from "helpers/stores";

import { getCurrentSchoolProfile } from "data-handler/ducks/schools";

import { getLoggedInOffline } from "data-handler/ducks/auth";
import { getIsWFPUser } from "data-handler/ducks/auth";

import "./_syncSelect.scss";

const SyncSelect = ({ history, match }) => {
  const dispatch = useDispatch();
  const isFetching = useSelector(storesIsFetching);
  const unsyncedStores = useSelector(getUnsyncedStores);
  const isOffline = useSelector(getLoggedInOffline);
  const currentSchoolProfile = useSelector(getCurrentSchoolProfile);
  const attendanceStoresByDay = useSelector(getAllAttendanceStoresByDay);

  const isWFP = useSelector(getIsWFPUser);
  const intl = useIntl();

  const currentSchoolEnabledStudentAttendance =
    currentSchoolProfile.enable_student_attendance;

  const currentSchoolEnabledTHR = currentSchoolProfile.enable_take_home_rations;

  const urlParams = queryString.parse(history.location.search);
  if (urlParams.select !== "sync") {
    return null;
  }

  const closeWindow = () => {
    history.push(`${history.location.pathname}?nav=true`);
  };

  const submit = () => {
    dispatch(POSTStores(unsyncedStores, match.params.schoolId));
  };

  const hasUnsuccessfulSyncs =
    unsyncedStores.filter((datum) => {
      return Object.keys(datum).includes("error");
    }).length !== 0;

  const hasPreviousMonthsSyncs =
    unsyncedStores.find((item) => {
      if ("occurred_on" in item) {
        return moment(item.occurred_on).isBefore(
          moment().format("YYYY-MM-DD"),
          "month"
        );
      } else if ("occurred_at" in item) {
        return moment(item.occurred_at).isBefore(
          moment().format("YYYY-MM-DD"),
          "month"
        );
      } else if ("delivered_at" in item) {
        return moment(item.delivered_at).isBefore(
          moment().format("YYYY-MM-DD"),
          "month"
        );
      } else if ("starts_on" in item) {
        return moment(item.starts_on).isBefore(
          moment().format("YYYY-MM-DD"),
          "month"
        );
      }
      return undefined;
    }) !== undefined;

  return (
    <ModalExtended
      open
      onRequestClose={closeWindow}
      onRequestSubmit={submit}
      modalLabel={
        <FormattedMessage
          id="SyncSelect.sync_data"
          defaultMessage="Sync data"
        />
      }
      modalHeading={
        <FormattedMessage
          id="SyncSelect.outstandingSyncs"
          defaultMessage="{unsyncedStoresLength} outstanding syncs"
          values={{ unsyncedStoresLength: unsyncedStores.length }}
        />
      }
      wide
      primaryButtonDisabled={isFetching || isOffline || isWFP}
      primaryButtonText={
        isFetching
          ? intl.formatMessage({
              id: "SyncSelect.loading",
              defaultMessage: "Loading",
            })
          : intl.formatMessage({
              id: "SyncSelect.sync_now",
              defaultMessage: "Sync now",
            })
      }
      secondaryButtonText={intl.formatMessage({
        id: "SyncSelect.close",
        defaultMessage: "Close",
      })}
      selectorPrimaryFocus={false}
    >
      <div>
        {isOffline && (
          <Blockquote kind="warning">
            <FormattedMessage
              id="SyncSelect.youAreOffline"
              defaultMessage="You're currently in offline mode. If you want to sync, you must login online."
            />
          </Blockquote>
        )}
        {hasUnsuccessfulSyncs && (
          <Blockquote kind="warning">
            <FormattedMessage
              id="SyncSelect.hasUnsuccessfulSyncs"
              defaultMessage="Data will not be updated until sync is successful."
            />
          </Blockquote>
        )}

        {/* Nothing to sync! */}
        {unsyncedStores.length === 0 && (
          <Empty
            title={intl.formatMessage({
              id: "SyncSelect.everything_synced",
              defaultMessage: "Everything synced",
            })}
            icon={<FontAwesomeIcon icon={faChalkboardTeacher} size="1x" />}
          >
            <FormattedMessage
              id="SyncSelect.update_something"
              defaultMessage="Update something"
            />
          </Empty>
        )}
        {/* Previous months syncs */}
        {hasPreviousMonthsSyncs && (
          <Blockquote kind="warning">
            <FormattedMessage
              id="SyncSelect.hasPreviousMonthsSync"
              defaultMessage="You are about to sync data for old months which could cause some changes for the next months"
            />
          </Blockquote>
        )}

        {/* Something to sync */}
        {unsyncedStores.map((store) => {
          let additional = null;
          if (isFetching) {
            additional = <InlineLoading />;
          }

          if (
            isMealAttendance(store) ||
            isStudentAttendance(store) ||
            isTakeHomeRationAttendance(store)
          ) {
            const data = attendanceStoresByDay[store.occurred_on];
            return (
              <RepeaterItem key={store.client_id} className="sync-select">
                <ItemSwitcher
                  store={data.mealAttendance}
                  studentStore={data.studentAttendance}
                  thrStore={data.thrAttendance}
                  storeToChooseType={store}
                  currentSchoolEnabledStudentAttendance={
                    currentSchoolEnabledStudentAttendance
                  }
                  currentSchoolEnabledTHR={currentSchoolEnabledTHR}
                  key={store.client_id}
                  additionalRight={additional}
                />
                <SyncError syncable={store} />
              </RepeaterItem>
            );
          } else {
            return (
              <RepeaterItem key={store.client_id} className="sync-select">
                <ItemSwitcher store={store} additionalRight={additional} />
                <SyncError syncable={store} />
              </RepeaterItem>
            );
          }
        })}
      </div>
    </ModalExtended>
  );
};

export default withRouter(SyncSelect);
