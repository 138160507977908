/*
 * I added this file to convert schools.js to typescript.
 * schools.js turns out to be too big to convert so I will create this ts version.
 * After whole schools.js is converted this file sould be renamed into schools.ts
 */

import axios, { AxiosResponse } from "axios";
import { RootState } from "data-handler/rootReducer";
import { call, put, takeLatest } from "redux-saga/effects";

export const REQUEST_COMMODITY_SOURCES_FOR_SCHOOL =
  "schoolconnect/schools/REQUEST_COMMODITY_SOURCES_FOR_SCHOOL";
export const SUCCESS_COMMODITY_SOURCES_FOR_SCHOOL =
  "schoolconnect/schools/SUCCESS_COMMODITY_SOURCES_FOR_SCHOOL";
export const FAILURE_COMMODITY_SOURCES_FOR_SCHOOL =
  "schoolconnect/schools/FAILURE_COMMODITY_SOURCES_FOR_SCHOOL";
export const SET_COMMODITY_SOURCES_FOR_SCHOOL_FROM_CACHE =
  "schoolconnect/schools/SET_COMMODITY_SOURCES_FOR_SCHOOL_FROM_CACHE";

export const REQUEST_COMMODITY_SOURCES_FOR_USER =
  "schoolconnect/schools/REQUEST_COMMODITY_SOURCES_FOR_USER";
export const SUCCESS_COMMODITY_SOURCES_FOR_USER =
  "schoolconnect/schools/SUCCESS_COMMODITY_SOURCES_FOR_USER";
export const FAILURE_COMMODITY_SOURCES_FOR_USER =
  "schoolconnect/schools/FAILURE_COMMODITY_SOURCES_FOR_USER";

export const REQUEST_ALL_COMMODITY_SOURCES =
  "schoolconnect/schools/REQUEST_ALL_COMMODITY_SOURCES";
export const SUCCESS_ALL_COMMODITY_SOURCES =
  "schoolconnect/schools/SUCCESS_ALL_COMMODITY_SOURCES";
export const FAILURE_ALL_COMMODITY_SOURCES =
  "schoolconnect/schools/FAILURE_ALL_COMMODITY_SOURCES";

interface FetchCommoditySourcesArgs {
  schoolId: number;
}

export type CommoditySource = {
  code: string;
  name: string;
  order: number;
};

export type CommodityIdToSourceMapping = { [commodityId: number]: string[] };
export type SchoolToCommoditySources = {
  [schools: number]: CommodityIdToSourceMapping;
};

/*
 * CommoditySources
 */
// ***************************************************************************************************************
function fetchCommoditySourcesForSchool(action: FetchCommoditySourcesArgs) {
  const url = `${process.env.REACT_APP_API_URL}/schools/${action.schoolId}/commodity-sources/`;
  return axios({
    method: "GET",
    url: url,
  });
}

function fetchCommoditySourcesForUser() {
  const url = `${process.env.REACT_APP_API_URL}/schools/commodity-sources/`;
  return axios({
    method: "GET",
    url: url,
  });
}

export function* workerCommoditySourcesForSchoolSaga(
  action: FetchCommoditySourcesArgs
) {
  try {
    const response: AxiosResponse = yield call(
      fetchCommoditySourcesForSchool,
      action
    );
    const data = {
      ...response.data,
    };
    yield put({ type: SUCCESS_COMMODITY_SOURCES_FOR_SCHOOL, data });
  } catch (error) {
    yield put({ type: FAILURE_COMMODITY_SOURCES_FOR_SCHOOL, error });
  }
}

export function* workerCommoditySourcesForUserSaga(action: {}) {
  try {
    const response: AxiosResponse = yield call(fetchCommoditySourcesForUser);
    const data = {
      ...response.data,
    };
    yield put({ type: SUCCESS_COMMODITY_SOURCES_FOR_USER, data });
  } catch (error) {
    yield put({ type: FAILURE_COMMODITY_SOURCES_FOR_USER, error });
  }
}

export function* commoditySourcesForSchoolSagaWatcher() {
  yield takeLatest(
    REQUEST_COMMODITY_SOURCES_FOR_SCHOOL as any,
    workerCommoditySourcesForSchoolSaga
  );
}

export function* commoditySourcesForUserSagaWatcher() {
  yield takeLatest(
    REQUEST_COMMODITY_SOURCES_FOR_USER as any,
    workerCommoditySourcesForUserSaga
  );
}

export const requestCommoditySourcesForSchool = (schoolId: number) => ({
  type: REQUEST_COMMODITY_SOURCES_FOR_SCHOOL,
  schoolId,
});

export const requestCommoditySourcesForUser = () => ({
  type: REQUEST_COMMODITY_SOURCES_FOR_USER,
});

export const setCommoditySourcesFromSchoolCache = (schoolId: number) => ({
  type: SET_COMMODITY_SOURCES_FOR_SCHOOL_FROM_CACHE,
  schoolId,
});

export const getCommoditySources = (
  state: RootState
): CommodityIdToSourceMapping => state.schools.commoditySources.data;

export const getCommoditySourcesFetching = (state: RootState): boolean =>
  state.schools.commoditySources.fetching;

export const getCommoditySourcesError = (state: RootState): any =>
  state.schools.commoditySources.error;

export const getCommoditySourcesForUser = (
  state: RootState
): SchoolToCommoditySources => state.schools.userCommoditySources.data;

export const getCommoditySourcesForUserFetching = (state: RootState): boolean =>
  state.schools.userCommoditySources.fetching;

export const getCommoditySourcesForUserError = (state: RootState): any =>
  state.schools.userCommoditySources.error;

// ***************************************************************************************************************

/*
 * AllCommoditySources
 */
// ***************************************************************************************************************
function fetchAllCommoditySources() {
  const url = `${process.env.REACT_APP_API_URL}/schools/all-commodity-sources/`;
  return axios({
    method: "GET",
    url: url,
  });
}

export function* workerAllCommoditySourcesSaga() {
  try {
    const response: AxiosResponse = yield call(fetchAllCommoditySources);
    const data: CommoditySource[] = response.data;
    yield put({ type: SUCCESS_ALL_COMMODITY_SOURCES, data });
  } catch (error) {
    yield put({ type: FAILURE_ALL_COMMODITY_SOURCES, error });
  }
}

export function* allCommoditySourcesSagaWatcher() {
  yield takeLatest(
    REQUEST_ALL_COMMODITY_SOURCES as any,
    workerAllCommoditySourcesSaga
  );
}

export const requestAllCommoditySources = () => ({
  type: REQUEST_ALL_COMMODITY_SOURCES,
});

export const getAllCommoditySources = (state: RootState): CommoditySource[] =>
  state.schools.allCommoditySources.data;

export const getAllCommoditySourcesFetching = (state: RootState): boolean =>
  state.schools.allCommoditySources.fetching;

export const getAllCommoditySourcesError = (state: RootState): any =>
  state.schools.allCommoditySources.error;

// ***************************************************************************************************************
