import React, { useState, useRef } from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "@wfp/ui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faCheck,
  faSyncAlt,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";

import { Camera } from "react-camera-pro";

import "./_content.scss";

const CameraComponent = ({ onChange, image }) => {
  const [cameraOpened, setCameraOpened] = useState(false);

  const camera = useRef(null);
  const [tempImage, setTempImage] = useState(image);
  const [numberOfCameras, setNumberOfCameras] = useState(0);

  return (
    <>
      <Button
        kind="secondary"
        icon={<FontAwesomeIcon icon={faCamera} />}
        onClick={() => setCameraOpened(true)}
        className="camera-component-button"
      >
        {(tempImage && !cameraOpened) || image ? (
          <FormattedMessage
            id="camera.viewPicture"
            defaultMessage="View picture"
          />
        ) : (
          <FormattedMessage
            id="camera.takePicture"
            defaultMessage="Take picture"
          />
        )}
      </Button>
      {cameraOpened && (
        <div className="camera-container">
          <div className="inner-camera-container">
            <Button
              kind="inverse--primary"
              className="close-button"
              small
              onClick={() => {
                if (!image && tempImage) {
                  setTempImage(undefined);
                } else {
                  onChange(tempImage);
                }
                setCameraOpened(false);
              }}
            >
              <FormattedMessage
                id="QrCodeReader.close"
                defaultMessage="Close"
              />
            </Button>
            {tempImage ? (
              <>
                <img src={tempImage} alt="Delivery info" />
                <div className="main-buttons">
                  <Button
                    className="accept-button"
                    large
                    kind="inverse--primary"
                    icon={<FontAwesomeIcon icon={faCheck} />}
                    onClick={() => {
                      onChange(tempImage);
                      setCameraOpened(false);
                    }}
                  />
                  <Button
                    className="reset-button"
                    large
                    kind="inverse--primary"
                    icon={<FontAwesomeIcon icon={faUndo} />}
                    onClick={() => {
                      setTempImage(undefined);
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <Camera
                  ref={camera}
                  facingMode="environment"
                  numberOfCamerasCallback={setNumberOfCameras}
                />
                {numberOfCameras === 0 && (
                  <label className="init-message wfp--label">
                    <FormattedMessage
                      id="camera.initializingCamera"
                      defaultMessage="Initializing camera..."
                    />
                  </label>
                )}
                <Button
                  className="photo-button"
                  large
                  kind="inverse--primary"
                  icon={<FontAwesomeIcon icon={faCamera} />}
                  onClick={() => {
                    const photo = camera.current.takePhoto();
                    setTempImage(photo);
                  }}
                  disabled={numberOfCameras === 0}
                />
                {numberOfCameras > 1 && (
                  <Button
                    className="switch-button"
                    kind="inverse"
                    small
                    onClick={() => {
                      camera.current.switchCamera();
                    }}
                    icon={<FontAwesomeIcon icon={faSyncAlt} />}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CameraComponent;
