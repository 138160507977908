import React, { useEffect, useState } from "react";
import { InlineLoading } from "@wfp/ui";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUser,
  getLoggedInOffline,
  getRequireInitialization,
  setRequireInitialization,
} from "data-handler/ducks/auth";
import {
  getAllCommoditySourcesFetching,
  getCommoditySourcesForUserFetching,
  getCompactSchoolsFetching,
  getExtendedSchoolsFetching,
  requestAllCommoditySources,
  requestCommoditySourcesForUser,
  requestCompactSchools,
  requestExtendedSchools,
} from "data-handler/ducks/schools";
import { getRolesFetching, requestRoles } from "data-handler/ducks/roles";
import { getCanUseOfflineMode, getIsInternal } from "helpers/users";
import { requestUserStores, storesIsFetching } from "data-handler/ducks/stores";
import commoditiesDuck from "data-handler/ducks/commodities";
import { requestLinks, getLinksFetching } from "data-handler/ducks/links";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

type InitializerProps = {};

/**
 * If requireInitialization is triggered, initialize the data before rendering the children.
 */
const Initializer: React.ComponentType<InitializerProps> = ({ children }) => {
  const dispatch = useDispatch();

  const [isFetchingStarted, setFetchingStarted] = useState<boolean>(false);

  const currentUser = useSelector(getCurrentUser);
  const canUseOfflineMode = getCanUseOfflineMode(currentUser);
  const isOffline = useSelector(getLoggedInOffline);
  const requireInitialization = useSelector(getRequireInitialization);
  const isInternal = getIsInternal(currentUser?.email);
  const isLinksFetching = useSelector(getLinksFetching);

  // Get fetching related data
  const isCompactSchoolsFetching = useSelector(getCompactSchoolsFetching);
  const isExtendedSchoolsFetching = useSelector(getExtendedSchoolsFetching);
  const isRolesFetching = useSelector(getRolesFetching);
  const isCommoditiesFetching: boolean = useSelector(
    commoditiesDuck.isFetching()
  );
  const isCommoditySourcesForUserFetching = useSelector(
    getCommoditySourcesForUserFetching
  );
  const isAllCommoditySourcesFetching = useSelector(
    getAllCommoditySourcesFetching
  );

  const isStoresFetching = useSelector(storesIsFetching);

  const fetching =
    isStoresFetching ||
    isCompactSchoolsFetching ||
    isExtendedSchoolsFetching ||
    isRolesFetching ||
    isCommoditiesFetching ||
    isCommoditySourcesForUserFetching ||
    isAllCommoditySourcesFetching ||
    isLinksFetching;

  useEffect(() => {
    // If user is not currently running in offline mode
    // and requireInitialization was triggered,
    // Initialize data we need for offline mode to work
    if (isOffline || !requireInitialization) {
      dispatch(setRequireInitialization(false));
    } else {
      setFetchingStarted(true);
      // Fetch Roles as both Country Admin and Schools Require it.
      dispatch(requestRoles());
      // getAllCommodities
      dispatch(requestAllCommoditySources());
      dispatch(requestCommoditySourcesForUser());
      dispatch(requestLinks({ type: "dashboard", internal: isInternal }));
      // Fetch all info needed for the offline capability for admin users
      // that is not used in this particular page.
      if (canUseOfflineMode) {
        // Fetch all schools on opening modal
        dispatch(requestCompactSchools());
        // dispatch(requestUserSchools());
        dispatch(requestExtendedSchools());
        // This will download all Stores user has access to and
        // save it to state.stores.userStores
        // Later we use this in setUserCachedStores function.
        dispatch(requestUserStores());
        dispatch(commoditiesDuck.fetchList());
      }
    }
  }, [
    canUseOfflineMode,
    dispatch,
    isInternal,
    isOffline,
    requireInitialization,
  ]);

  useEffect(() => {
    if (requireInitialization && isFetchingStarted && !fetching) {
      dispatch(setRequireInitialization(false));
    }
  });

  const OK = <FontAwesomeIcon icon={faCheck} size="2x" color="green" />;

  if (requireInitialization) {
    return (
      <table>
        <tbody>
          <tr>
            <td>Download user stores</td>
            <td>{isStoresFetching ? <InlineLoading /> : OK}</td>
          </tr>
          <tr>
            <td>Download compact schools</td>
            <td>{isCompactSchoolsFetching ? <InlineLoading /> : OK}</td>
          </tr>
          <tr>
            <td>Download extended schools</td>
            <td>{isExtendedSchoolsFetching ? <InlineLoading /> : OK}</td>
          </tr>
          <tr>
            <td>Download roles</td>
            <td>{isRolesFetching ? <InlineLoading /> : OK}</td>
          </tr>
          <tr>
            <td>Download commodities</td>
            <td>{isCommoditiesFetching ? <InlineLoading /> : OK}</td>
          </tr>
          <tr>
            <td>Download commodity sources for user</td>
            <td>
              {isCommoditySourcesForUserFetching ? <InlineLoading /> : OK}
            </td>
          </tr>
          <tr>
            <td>Download commodity source translations</td>
            <td>{isAllCommoditySourcesFetching ? <InlineLoading /> : OK}</td>
          </tr>
          <tr>
            <td>Download dashboard links</td>
            <td>{isLinksFetching ? <InlineLoading /> : OK}</td>
          </tr>
        </tbody>
      </table>
    );
  } else {
    return <>{children}</>;
  }
};

export default Initializer;
