import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import QrReader from "react-qr-reader";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";

import pako from "pako";

import { Button } from "@wfp/ui";

import { getCurrentSchoolProfileWfpCommodities } from "data-handler/ducks/schools";
import { TOAST_ERROR, popToast } from "data-handler/ducks/toast";

interface commodityOption {
  name: String;
  id: Number;
  is_wfp: Boolean;
  commodity_gateway: {
    code: string;
  };
}
interface commodity {
  batch_no: String;
  commodity: String;
  netUnitWeight: String;
}

interface QRCodeReaderProps {
  getValues: any;
  reset: any;
  qrCodeParam: Boolean;
}

const QrCodeReader: React.FC<QRCodeReaderProps> = ({
  getValues,
  reset,
  qrCodeParam,
}: QRCodeReaderProps) => {
  const dispatch = useDispatch();
  const [useQrCode, setQrCode] = useState(qrCodeParam);
  const commodityListWfp: any = useSelector(
    getCurrentSchoolProfileWfpCommodities
  );

  const activateQrCode = () => {
    setQrCode(true);
  };

  const handleError = (e: any) => {
    dispatch(popToast(TOAST_ERROR, e, {}, {}));
    setQrCode(false);
  };

  const handleScan = (e: string | null) => {
    try {
      // When no Code is present, e is null
      if (e !== null) {
        // Decode base64 (convert ascii to binary)
        let strData = atob(e);
        // Convert binary string to character-number array
        let charData = strData.split("").map(function (x) {
          return x.charCodeAt(0);
        });
        // Turn number array into byte-array
        let binData = new Uint8Array(charData);
        // Pako magic
        let data = pako.inflate(binData);
        // Convert gunzipped byteArray back to ascii string:
        strData = String.fromCharCode.apply(null, new Uint16Array(data) as any);

        const parsedStrData = strData.split("#");
        const header = parsedStrData.shift() || "";
        const waybillNo = header.split(";")[0];
        const qrCommodities: any[] = [];
        const errorCommodities: any[] = [];

        parsedStrData.forEach((item, index) => {
          const qrData = item.split(";");

          const { batch_no, commodity, netUnitWeight }: commodity = {
            batch_no: qrData[1],
            commodity: qrData[3],
            netUnitWeight: (Number(qrData[4]) * Number(qrData[6])).toFixed(3), // Number of units * Net Unit Weight
          };

          const matchingCommmodity:
            | commodityOption
            | undefined = commodityListWfp.find((e: commodityOption) => {
            return e.commodity_gateway?.code === commodity;
          });

          if (!batch_no) {
            errorCommodities.push(
              `Index ${index} - cannot read batch number from QR code`
            );
          } else if (batch_no && !matchingCommmodity) {
            if (commodity) {
              errorCommodities.push(
                `Index ${index} - Batch number ${batch_no} - no matching commodity in the school profile for code ${commodity}`
              );
            } else {
              errorCommodities.push(
                `Index ${index} - Batch number ${batch_no} - cannot read commodity from QR code`
              );
            }
          } else if (batch_no && matchingCommmodity && netUnitWeight) {
            qrCommodities.push({
              batch_no: { label: batch_no, value: batch_no },
              quantity: netUnitWeight,
              commodity: matchingCommmodity.id,
            });
          } else {
            errorCommodities.push(
              `Index ${index} - Cannot import batch number ${batch_no}`
            );
          }
        });

        // Need to reset the default values of the Form in order to have the fields load the QR Code data.
        const resetValues = {
          ...getValues(),
          waybill_no: waybillNo,
        };
        if (qrCommodities.length > 0) {
          resetValues.commodities = qrCommodities;
        }
        reset(resetValues);

        // Display error if any of the entries cannot be loaded
        if (errorCommodities.length > 0) {
          let errorString = "";
          errorCommodities.forEach((e) => {
            if (errorString === "") {
              errorString = e;
            } else {
              errorString = e + ", " + errorString;
            }
          });
          const errorMessage: any = () => {
            return (
              <FormattedMessage
                id="qrCode.errorEntry"
                defaultMessage="Unable to import delivery: {error}. Please enter manually"
                values={{ error: errorString }}
              />
            );
          };
          dispatch(popToast(TOAST_ERROR, errorMessage, {}, {}));
        }

        setQrCode(false);
      }
    } catch (e) {
      dispatch(popToast(TOAST_ERROR, e, {}, {}));
      setQrCode(false);
    }
  };

  return (
    <>
      <Button
        icon={<FontAwesomeIcon icon={faQrcode} />}
        kind="secondary"
        onClick={activateQrCode}
      >
        <FormattedMessage
          id="QrCodeReader.scanQrCode"
          defaultMessage="Scan QR code"
        />
      </Button>
      {useQrCode ? (
        <div className="qr-code">
          <QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: "100%" }}
          />
          <Button kind="inverse" small onClick={() => setQrCode(false)}>
            <FormattedMessage id="QrCodeReader.close" defaultMessage="Close" />
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default QrCodeReader;
