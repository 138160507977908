import React, { BaseSyntheticEvent } from "react";
import { FormattedMessage } from "react-intl";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import { Button } from "@wfp/ui";

import styles from "./styles.module.scss";
type ButtonProps = {
  onClick?: (event: BaseSyntheticEvent) => void;
};

type RepeaterAddButtonProps = ButtonProps & {
  additional?: React.ReactNode;
  text?: string;
};

const RepeaterAddButton: React.ComponentType<RepeaterAddButtonProps> = ({
  additional = null,
  text = null,
  onClick,
  ...otherProps
}) => {
  return (
    <div className={styles.addButtonWrapper}>
      <Button
        {...otherProps}
        className={styles.addButton}
        type="button"
        onClick={onClick}
        icon={(<FontAwesomeIcon icon={faPlusCircle} />) as any}
      >
        {text ? (
          text
        ) : (
          <FormattedMessage
            id="RepeaterAddButton.addACommodity"
            defaultMessage="Add a commodity"
          />
        )}
      </Button>
      {additional}
    </div>
  );
};

export default RepeaterAddButton;
