import React, { useEffect, useState } from "react";
import { Button, InlineLoading, Select, SelectItem, TextInput } from "@wfp/ui";
import { FormattedMessage, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { getLoginState } from "../../../../data-handler/ducks/auth";

import styles from "components/Registration/registration.module.scss";
import PageTitle from "components/PageTitle";

import { languagesOptions } from "SCConstants";

const CountryAdminRegistrationForm = (props) => {
  const intl = useIntl();

  const { handleSubmit, errors, register, setValue, watch } = useForm();
  const { userData, onBackToRoleSelection, countries, onSubmit } = props;
  const { fetching } = useSelector((state) => getLoginState(state));

  const [submitErrorMessage, setSubmitErrorMessage] = useState("");

  // Display the required fields message if there are errors
  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      setSubmitErrorMessage(
        intl.formatMessage({
          id: "Registration.allFieldsMustBeFilledMessage",
          defaultMessage:
            "Please fill all the fields marked with red before proceeding",
        })
      );
    } else {
      setSubmitErrorMessage("");
    }
  }, [errors, intl]);

  // Used to populate the country.
  // eslint-disable-next-line no-unused-vars
  const selectedCountry = watch("country");

  useEffect(() => {
    if (userData.country) {
      setValue("country", userData.country.iso2, {
        shouldDirty: true,
      });
    } else if (userData.countries) {
      setValue("country", userData.countries, {
        shouldDirty: true,
      });
    } else {
      setValue("country", "BI", {
        shouldDirty: true,
      });
    }
  }, [setValue, userData.countries, userData.country]);

  const invalidStyle = { borderColor: "#c5192d" };

  return (
    <div className="role-registration-form">
      {!userData ? (
        <p>
          <FormattedMessage
            id="User.RegisterUnavailable"
            defaultMessage="This page is not available"
          />
        </p>
      ) : (
        <form>
          <Button
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
            iconReverse
            kind="secondary"
            className={styles.backLink}
            onClick={() => onBackToRoleSelection()}
          >
            <FormattedMessage
              id="Registration.backToAccessTypeSelectionButton"
              defaultMessage="Back to access type selection"
            />
          </Button>
          <PageTitle>
            <FormattedMessage
              id="User.RegisterToSchoolConnectAsCountryAdmin"
              defaultMessage="Register to School Connect as a Country Admin"
            />
          </PageTitle>

          <div className={styles.nameWrapper}>
            <div style={{ marginBottom: "24px" }}>
              <span className="wfp--label">
                <FormattedMessage
                  id="User.RegistrationName"
                  defaultMessage="Name"
                />
              </span>
              <br />
              {`${userData.other_names} ${userData.last_name}`}
            </div>
          </div>
          <div className={styles.contactWrapper}>
            <TextInput
              style={errors.email ? invalidStyle : {}}
              autocorrect="off"
              autoCapitalize="off"
              defaultValue={userData.email}
              disabled
              labelText={
                <FormattedMessage
                  id="user.emailFieldLabel"
                  defaultMessage="Email"
                />
              }
              helperText={intl.formatMessage({
                id: "User.RegistrationRequiredEmailHelper",
                defaultMessage: "Your email is required",
              })}
              inputRef={register({ required: true })}
              name="email"
              id="email"
            />
          </div>

          <div className={styles.schoolWrapper}>
            <Select
              style={errors.country ? invalidStyle : {}}
              inputRef={register({ required: true })}
              className="country"
              name="country"
              id="country"
              labelText={intl.formatMessage({
                id: "User.RegistrationSelectCountry",
                defaultMessage: "Select country",
              })}
              helperText={intl.formatMessage({
                id: "User.RegistrationSelectCountryHelper",
                defaultMessage: "Select your country",
              })}
            >
              <SelectItem
                value=""
                text={intl.formatMessage({
                  id: "User.RegistrationSelectCountry",
                  defaultMessage: "Select country",
                })}
              />
              {countries &&
                countries.map((country) => (
                  <SelectItem
                    key={country.iso2}
                    value={country.iso2}
                    text={intl.formatMessage({
                      id: `User.RegistrationSelectCountry.${country.iso2}`,
                      defaultMessage: `${country.name}`,
                    })}
                  />
                ))}
            </Select>
            <Select
              style={errors.language ? invalidStyle : {}}
              inputRef={register({ required: true })}
              className="custom_language"
              name="custom_language"
              id="custom_language"
              labelText={intl.formatMessage({
                id: "User.RegistrationSelectLanguage",
                defaultMessage: "Select language",
              })}
              helperText={intl.formatMessage({
                id: "User.RegistrationSelectLanguageHelper",
                defaultMessage: "Select your language",
              })}
              defaultValue={userData.custom_language}
            >
              <SelectItem
                value=""
                text={intl.formatMessage({
                  id: "User.RegistrationSelectLanguage",
                  defaultMessage: "Select language",
                })}
              />
              {languagesOptions.map((custom_language) => (
                <SelectItem
                  key={custom_language.value}
                  value={custom_language.value}
                  text={intl.formatMessage({
                    id: `User.RegistrationSelectLanguage.${custom_language.label}`,
                    defaultMessage: `${custom_language.label}`,
                  })}
                />
              ))}
            </Select>
          </div>
          {submitErrorMessage && (
            <p className={styles.submitErrorMessage}>{submitErrorMessage}</p>
          )}
          <div className={styles.submitWrapper}>
            <div className={styles.button}>
              <Button type="submit" onClick={handleSubmit(onSubmit)}>
                <FormattedMessage
                  id="User.RegistrationSubmit"
                  defaultMessage="Submit registration"
                />
              </Button>
              {fetching && <InlineLoading />}
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default CountryAdminRegistrationForm;
