import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, NavLink, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import bcryptjs from "bcryptjs";

import attendanceIcon from "images/corn-bag.svg";

import { Blockquote, Button, InlineLoading } from "@wfp/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

import { getCurrentSchool } from "data-handler/ducks/schools";
import { LOGIN_LOCAL } from "data-handler/ducks/auth";
import { getLocalUserById } from "data-handler/ducks/localAuth";

import PageTitle from "components/PageTitle";
import Empty from "components/Empty";
import CodeInput from "components/CodeInput";
import loginBackground from "images/IMG_5995.jpg";
import LoginWrapper from "./LoginWrapper";

import styles from "./login.module.scss";
import "./login.module.scss";
import { getCanUseOfflineMode } from "helpers/users";

const LocalLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const auth = useSelector((state) => state.auth);
  const currentSchool = useSelector(getCurrentSchool);
  const [error, setError] = useState(false);

  const localUser = useSelector((state) =>
    getLocalUserById(state, params.username)
  );

  if (auth.user) {
    dispatch({ type: "LOGOUT_LOCAL" });
  }

  const { control, handleSubmit, errors } = useForm();

  const onSubmit = async (values) => {
    if (
      localUser &&
      localUser.passcode &&
      bcryptjs.compareSync(values.password, localUser.passcode)
    ) {
      dispatch({
        type: LOGIN_LOCAL,
        data: {
          ...localUser,
          //school: currentSchool,
          id: params.username,
        },
      });
      /**
       * Redirect:
       *   - school users to the school's page
       *   - non-school users to the home page (so that they can select the school)
       */
      let redirectPath = `/school/${currentSchool?.id}`;
      if (getCanUseOfflineMode(localUser)) redirectPath = "/";
      history.push(redirectPath);
    } else {
      setError(true);
    }
  };

  // Do not allow local login for school users if there is no current school
  if (!currentSchool && !getCanUseOfflineMode(localUser)) {
    return (
      <Empty
        button={
          <>
            <NavLink to={`/login`}>
              <Button iconReverse icon={<FontAwesomeIcon icon={faPlus} />}>
                Login
              </Button>
            </NavLink>
          </>
        }
        title="Local login not available"
        kind="large"
        icon={
          <img
            alt="attendance empty illustration a bag of rice"
            src={attendanceIcon}
            style={{
              marginLeft: "1%",
              marginRight: "3%",
              marginBottom: "1%",
            }}
          />
        }
      >
        Please use the online login
      </Empty>
    );
  }
  return (
    <LoginWrapper
      rightSide={
        <img
          alt="School connect users looking at tablet devices"
          className={styles.loginContentImage}
          src={loginBackground}
        />
      }
      showBackLink
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          {error && (
            <>
              <Blockquote warning title="Login failed">
                <FormattedMessage
                  id="Login.pleaseTryAnotherCode"
                  defaultMessage="Please try again or use the online login"
                />
              </Blockquote>
              <br />
            </>
          )}
          <PageTitle>
            {currentSchool?.name || (
              <FormattedMessage
                id="Login.noSchoolSelected"
                defaultMessage="No school selected"
              />
            )}
            <br />
            <span className={styles.subTitle}>
              {localUser?.other_names} {localUser?.last_name}
            </span>
          </PageTitle>
          <ErrorMessage errors={errors} name="username" />
          <Controller
            as={<CodeInput />}
            name="password"
            labelText={
              <FormattedMessage id="Login.code" defaultMessage="Code" />
            }
            helperText={
              <FormattedMessage
                id="Login.enterCode"
                defaultMessage="Enter your passcode"
              />
            }
            control={control}
          />
          <div className={styles.submitWrapper}>
            <div className={styles.button}>
              <Button type="submit">
                <FormattedMessage id="Login.login" defaultMessage="Login" />
              </Button>
              {
                // TODO: Show InlineLoading when loading
                false && <InlineLoading />
              }
            </div>
            <Link
              to={`/corporateLogin/${params.username}`}
              className={styles.password}
            >
              <FormattedMessage
                id="Login.useOnlineLogin"
                defaultMessage="Use online login"
              />
            </Link>
          </div>
        </div>
      </form>
    </LoginWrapper>
  );
};

export default LocalLogin;
