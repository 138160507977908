import React, { useState, useRef } from "react";
import { useHistory, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import withSizes from "react-sizes";
import { Controller, useForm, FormProvider } from "react-hook-form";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import {
  getSchoolYearByDate,
  getSchoolYearLevelsBySchoolYearId,
} from "data-handler/ducks/stores";
import { updateStore } from "data-handler/ducks/stores";
import { getCurrentSchoolProfile } from "data-handler/ducks/schools";
import { deliveryCategory, studentKinds } from "SCConstants";
import {
  buildAttendanceUrl,
  getFilteredAttendanceTotal,
} from "helpers/attendance";
import { mapSizesToProps } from "helpers/mapSizesToProps";

import { Blockquote } from "@wfp/ui";
import LevelTitle from "components/LevelTitle";
import CompactNumberInput from "components/CompactNumberInput";
import TableExtended from "components/TableExtended";
import ModalExtended from "components/ModalExtended";
import ButtonRouter from "components/ButtonRouter";

import styles from "./attendance-edit.module.scss";
import { displayStaffLevel } from "components/Attendance/AttendanceContent";

export const attendanceRowsDesktop = (
  levelData,
  storePreview,
  showMorningFields,
  showAfternoonFields,
  defaultValues
) => {
  return levelData.map((level, i) => (
    <tr key={i}>
      <td className="wfp--table__title">
        <LevelTitle level={level} />
        <div className="wfp--table__title__total">
          <FormattedMessage id="AttendanceEdit.total" defaultMessage="total" />:{" "}
          {getFilteredAttendanceTotal({
            attendance: storePreview,
            levels: [level.level],
          })}
        </div>
      </td>
      {showMorningFields && (
        <AttendanceInputCells
          level={level.level}
          shift="morning"
          defaultValues={defaultValues}
        />
      )}
      {showAfternoonFields && (
        <AttendanceInputCells
          level={level.level}
          shift="afternoon"
          defaultValues={defaultValues}
        />
      )}
      <td className="wfp--table__show-total-value">
        {getFilteredAttendanceTotal({
          attendance: storePreview,
          levels: [level.level],
        })}
      </td>
    </tr>
  ));
};

export const studentTotals = (row, shifts, storePreview) => {
  return studentKinds.reduce((acc, kind) => {
    const total = getFilteredAttendanceTotal({
      attendance: storePreview,
      levels: row?.levelValues,
      kinds: [kind.value],
      shifts: shifts,
    });
    acc = acc + total;
    return acc;
  }, 0);
};

export const attendanceTotalsRowDesktop = (
  schoolYear,
  schoolYearLevels,
  showMorningFields,
  showAfternoonFields,
  storePreview
) => {
  return displayStaffLevel(schoolYearLevels).map((row, i) => (
    <tr key={i} className={i === 0 ? "diff" : ""}>
      <td className="wfp--table__title">{row.title}</td>
      {showMorningFields &&
        studentKinds.map((kind) => (
          <td className="wfp--table__total">
            {getFilteredAttendanceTotal({
              attendance: storePreview,
              levels: row.levelValues,
              kinds: [kind.value],
              shifts: ["morning"],
            })}
          </td>
        ))}
      {showAfternoonFields &&
        studentKinds.map((kind) => (
          <td className="wfp--table__total">
            {getFilteredAttendanceTotal({
              attendance: storePreview,
              levels: row.levelValues,
              kinds: [kind.value],
              shifts: ["afternoon"],
            })}
          </td>
        ))}
      <td className="wfp--table__total">
        {studentTotals(row, ["morning", "afternoon"], storePreview)}
      </td>
    </tr>
  ));
};

export const attendanceLevelData = (schoolYearLevels) => {
  const collator = new Intl.Collator([], { numeric: true });
  const levelsList = new Set();
  schoolYearLevels.forEach((level) => levelsList.add(level.level));
  const levelData = [...levelsList].sort(collator.compare).map((level) => {
    return {
      level,
    };
  });

  return levelData;
};

export const attendanceDesktop = (
  showMorningFields,
  showAfternoonFields,
  rowsDesktop,
  totalsDesktop
) => {
  return (
    <TableExtended>
      <thead>
        <tr>
          <th></th>
          {showMorningFields && (
            <th colSpan={studentKinds.length}>
              <FormattedMessage
                id="AttendanceEdit.morning"
                defaultMessage="Morning"
              />
            </th>
          )}
          {showAfternoonFields && (
            <th colSpan={studentKinds.length}>
              <FormattedMessage
                id="AttendanceEdit.afternoon"
                defaultMessage="Afternoon"
              />
            </th>
          )}
          <th className="wfp--table__row-total"> </th>
        </tr>
        <tr>
          <th></th>
          {showMorningFields &&
            studentKinds.map((kind) => <th>{kind.labelTrans}</th>)}
          {showAfternoonFields &&
            studentKinds.map((kind) => <th>{kind.labelTrans}</th>)}

          <th className="wfp--table__row-total">
            <FormattedMessage
              id="AttendanceEdit.Total"
              defaultMessage="Total"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {rowsDesktop}
        {totalsDesktop}
      </tbody>
    </TableExtended>
  );
};

export const attendanceMobile = (
  showMorningFields,
  showAfternoonFields,
  rowsMobileMorning,
  totalsMobileMorning,
  rowsMobileAfternoon,
  totalsMobileAfternoon
) => {
  return (
    <TableExtended className={styles.tableMobile}>
      <thead>
        <tr>
          <th></th>
          {studentKinds.map((kind) => (
            <th>{kind.labelTrans}</th>
          ))}
          <th>
            <FormattedMessage
              id="AttendanceShow.total"
              defaultMessage="Total"
            />
          </th>
        </tr>
      </thead>
      {showMorningFields && (
        <>
          <thead>
            <tr>
              <th></th>
              <th colSpan={studentKinds.length}>
                <FormattedMessage
                  id="AttendanceEdit.morning"
                  defaultMessage="Morning"
                />
              </th>
              <th className="wfp--table__row-total"></th>
            </tr>
          </thead>
          <tbody>{rowsMobileMorning}</tbody>
          <tbody>{totalsMobileMorning}</tbody>
        </>
      )}
      {showAfternoonFields && (
        <>
          <thead>
            <tr>
              <th></th>
              <th colSpan={studentKinds.length}>
                <FormattedMessage
                  id="AttendanceEdit.afternoon"
                  defaultMessage="Afternoon"
                />
              </th>
              <th className="wfp--table__row-total">
                <FormattedMessage
                  id="AttendanceEdit.Total"
                  defaultMessage="Total"
                />
              </th>
            </tr>
          </thead>
          <tbody>{rowsMobileAfternoon}</tbody>
          <tfoot>{totalsMobileAfternoon}</tfoot>
        </>
      )}
    </TableExtended>
  );
};

const populateEnrolment = (
  _store,
  enrolmentData,
  hasSameStudents,
  hasMorning,
  hasAfternoon
) => {
  _store.levels.forEach((level) => {
    const otherShift = _store.levels.find(
      (shiftlevel) =>
        shiftlevel.shift === level.shift && shiftlevel.level === level.level
    );

    const morningShift = _store.levels.find(
      (shiftlevel) =>
        shiftlevel.shift === "morning" && shiftlevel.level === level.level
    );

    const enrolmentObject = enrolmentData.rows.find((row) => {
      return row.level === level.level;
    });

    const female = enrolmentObject?.female ? enrolmentObject.female : 0;
    const male = enrolmentObject?.male ? enrolmentObject.male : 0;

    const halfStudentValues = () => {
      level.female = `${parseInt(female / 2)}`;
      level.male = `${parseInt(male / 2)}`;
      otherShift.male = `${parseInt(male / 2)}`;
      otherShift.female = `${parseInt(female / 2)}`;
    };

    if (!hasSameStudents && hasMorning && hasAfternoon) {
      if (female % 2 === 0 && male % 2 === 0) {
        halfStudentValues();
      } else if (female % 2 !== 0 && male % 2 === 0) {
        halfStudentValues();
        morningShift.female = `${parseInt(female / 2) + 1}`;
      } else if (female % 2 === 0 && male % 2 !== 0) {
        halfStudentValues();
        morningShift.male = `${parseInt(male / 2) + 1}`;
      } else if (female % 2 !== 0 && male % 2 !== 0) {
        halfStudentValues();
        morningShift.female = `${parseInt(female / 2) + 1}`;
        morningShift.male = `${parseInt(male / 2) + 1}`;
      }
    } else {
      level.female = female;
      level.male = male;
    }
  });
  return _store;
};
const populateAttendance = (_store, attendanceStore) => {
  _store.levels.forEach((level) => {
    const attendanceObject = attendanceStore?.levels?.find((row) => {
      return row.level === level.level && row.shift === level.shift;
    });
    const female = attendanceObject?.female ? attendanceObject.female : 0;
    const male = attendanceObject?.male ? attendanceObject.male : 0;

    level.female = female;
    level.male = male;
  });
  return _store;
};

const storeToFormValues = (store) => {
  let _store = JSON.parse(JSON.stringify(store)); // Deep copy
  _store.levels.forEach((level) => {
    studentKinds.forEach((kind) => {
      // cast to str (may become `"undefined"` - no problem for formValuesToStore)
      level[kind.value] = `${level[kind.value]}`;
    });
  });
  return _store;
};

const formValuesToStore = (values) => {
  values.levels.forEach((level) => {
    studentKinds.forEach((kind) => {
      // cast to int
      level[kind.value] = parseInt(level[kind.value]) || 0;
    });
  });
  return values;
};

/**
 * Returns one <td> per studentKind, mapped to the correct input `name`
 */
const AttendanceInputCells = ({ level, shift, control, defaultValues }) => {
  const levels_i = defaultValues.levels.findIndex(
    (attendanceLevel) =>
      attendanceLevel.level === level && attendanceLevel.shift === shift
  );

  return (
    <>
      {studentKinds.map((kind, i) => {
        const key = `levels[${levels_i}].${kind.value}`;
        return (
          <td key={i}>
            <Controller
              as={<CompactNumberInput />}
              hideLabel={true}
              placeholder="0"
              min={0}
              max={10000}
              name={key}
              key={key}
              control={control}
            />
          </td>
        );
      })}
    </>
  );
};

const AttendanceEdit = ({
  currentStoreData,
  isMobile,
  enrolmentData,
  previousAttendanceStoreWithAttendanceData,
  studentAttendanceData,
}) => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const occurredOnDate =
    currentStoreData.occurred_on || moment().format("YYYY-MM-DD");
  const schoolYear = useSelector(getSchoolYearByDate(occurredOnDate));
  const schoolYearLevels = useSelector(
    getSchoolYearLevelsBySchoolYearId(schoolYear.object_id)
  );
  const currentSchoolProfile = useSelector(getCurrentSchoolProfile);
  const currentSchoolEnabledStudentAttendance =
    currentSchoolProfile.enable_student_attendance;

  const [storePreview, setStorePreview] = useState();

  const id = (currentStoreData && currentStoreData.client_id) || undefined;
  let defaultValues = {
    occurred_on: currentStoreData.occurred_on || moment().format("YYYY-MM-DD"),
    levels:
      currentStoreData.levels === undefined
        ? []
        : JSON.parse(JSON.stringify(currentStoreData.levels)),
  };
  if (studentAttendanceData && studentAttendanceData.levels) {
    defaultValues.levels = studentAttendanceData.levels.map((level) => ({
      ...level,
    }));
  }

  // Update defaultValues to account for all current levels and shifts
  schoolYearLevels.forEach((level) => {
    if (
      schoolYear.has_morning_classes &&
      !defaultValues.levels.find(
        (attendanceLevel) =>
          attendanceLevel.level === level.level &&
          attendanceLevel.shift === "morning"
      )
    ) {
      defaultValues.levels.push({ level: level.level, shift: "morning" });
    }
    if (
      schoolYear.has_afternoon_classes &&
      !schoolYear.has_same_students_in_both_shifts &&
      !defaultValues.levels.find(
        (attendanceLevel) =>
          attendanceLevel.level === level.level &&
          attendanceLevel.shift === "afternoon"
      )
    ) {
      defaultValues.levels.push({ level: level.level, shift: "afternoon" });
    }
  });

  if (enrolmentData) {
    defaultValues = populateEnrolment(
      defaultValues,
      enrolmentData,
      schoolYear.has_same_students_in_both_shifts,
      schoolYear.has_morning_classes,
      schoolYear.has_afternoon_classes
    );
  } else if (previousAttendanceStoreWithAttendanceData) {
    defaultValues = populateAttendance(
      defaultValues,
      previousAttendanceStoreWithAttendanceData
    );
  }

  const submitBtnRef = useRef();
  const submitViaBtnRefClick = () => {
    submitBtnRef.current.click();
  };

  const methods = useForm({
    defaultValues: storeToFormValues(defaultValues),
  });

  const { getValues, handleSubmit } = methods;

  const closeModal = (newId) => {
    history.push(
      buildAttendanceUrl(
        occurredOnDate,
        params.schoolId,
        newId ? newId : id,
        params.thrItem,
        params.studentItem
      )
    );
  };

  const onSubmit = (values) => {
    const store = dispatch(
      updateStore({
        id: id,
        values: {
          ...currentStoreData,
          ...formValuesToStore(values, schoolYearLevels),
        },
        model: "attendance",
        type: id ? "update" : "create",
        section: "attendance",
        category: deliveryCategory,
      })
    );
    closeModal(store.data.id);
  };

  const showMorningFields = schoolYear.has_morning_classes === true;
  const showAfternoonFields =
    schoolYear.has_afternoon_classes === true &&
    schoolYear.has_same_students_in_both_shifts !== true;

  let previewTimeout;
  const updateStorePreview = () => {
    if (previewTimeout) clearTimeout(previewTimeout);
    previewTimeout = setTimeout(() => {
      setStorePreview(getValues({ nest: true }));
    }, 200);
  };
  if (!storePreview) updateStorePreview();

  const totalsDesktop = attendanceTotalsRowDesktop(
    schoolYear,
    schoolYearLevels,
    showMorningFields,
    showAfternoonFields,
    storePreview
  );

  const totalsMobileMorning = displayStaffLevel(schoolYearLevels).map(
    (row, i) => (
      <tr key={i} className={i === 0 ? "diff" : ""}>
        <td className="wfp--table__title">{row.title}</td>
        {studentKinds.map((kind) => (
          <td className="wfp--table__total">
            {getFilteredAttendanceTotal({
              attendance: storePreview,
              levels: row.levelValues,
              kinds: [kind.value],
              shifts: ["morning"],
            })}
          </td>
        ))}
        <td className="wfp--table__total">
          {studentTotals(row, ["morning"], storePreview)}
        </td>
      </tr>
    )
  );

  const totalsMobileAfternoon = displayStaffLevel(schoolYearLevels).map(
    (row, i) => (
      <tr key={i} className={i === 0 ? "diff" : ""}>
        <td className="wfp--table__title">{row.title}</td>
        {showAfternoonFields &&
          studentKinds.map((kind) => (
            <td className="wfp--table__total">
              {getFilteredAttendanceTotal({
                attendance: storePreview,
                levels: row.levelValues,
                kinds: [kind.value],
                shifts: ["afternoon"],
              })}
            </td>
          ))}
        <td className="wfp--table__total">
          {studentTotals(row, ["afternoon"], storePreview)}
        </td>
      </tr>
    )
  );

  const rowsDesktop = attendanceRowsDesktop(
    attendanceLevelData(schoolYearLevels),
    storePreview,
    showMorningFields,
    showAfternoonFields,
    defaultValues
  );

  const rowsMobileMorning = attendanceLevelData(schoolYearLevels).map(
    (level, i) => (
      <tr key={i}>
        <td className="wfp--table__title">
          <LevelTitle level={level} />
          <div className="wfp--table__title__total">
            <FormattedMessage
              id="AttendanceEdit.total"
              defaultMessage="total"
            />
            :{" "}
            {getFilteredAttendanceTotal({
              attendance: storePreview,
              levels: [level.level],
            })}
          </div>
        </td>
        <AttendanceInputCells
          level={level.level}
          shift="morning"
          defaultValues={defaultValues}
        />
        <td className="wfp--table__show-total-value">
          {getFilteredAttendanceTotal({
            attendance: storePreview,
            levels: [level.level],
            shifts: ["morning"],
          })}
        </td>{" "}
      </tr>
    )
  );

  const rowsMobileAfternoon = attendanceLevelData(schoolYearLevels).map(
    (level, i) => (
      <tr key={i}>
        <td className="wfp--table__title">
          <LevelTitle level={level} />
          <div className="wfp--table__title__total">
            <FormattedMessage
              id="AttendanceEdit.total"
              defaultMessage="total"
            />
            :{" "}
            {getFilteredAttendanceTotal({
              attendance: storePreview,
              levels: [level.level],
            })}
          </div>
        </td>
        <AttendanceInputCells
          level={level.level}
          shift="afternoon"
          defaultValues={defaultValues}
        />
        <td className="wfp--table__show-total-value">
          {getFilteredAttendanceTotal({
            attendance: storePreview,
            levels: [level.level],
            shifts: ["afternoon"],
          })}
        </td>
      </tr>
    )
  );
  const paramDetailsSplit = params.details.split("-");
  const currentParam =
    paramDetailsSplit[0] === "new" ? "new-attendance" : "attendance";
  const totalStudents = getFilteredAttendanceTotal({
    attendance: storePreview,
  });

  const attendanceModalLabel = currentSchoolEnabledStudentAttendance ? (
    <FormattedMessage
      id="AttendanceEdit.attendanceModalLabelWithStudentAttendanceEnabled"
      defaultMessage="Canteen Attendance"
    />
  ) : (
    <FormattedMessage
      id="AttendanceEdit.attendanceModalLabel"
      defaultMessage="Attendance"
    />
  );

  return (
    <ModalExtended
      open
      onRequestSubmit={submitViaBtnRefClick}
      onRequestClose={() => closeModal(params.item)}
      modalLabel={attendanceModalLabel}
      modalHeading={`${moment(defaultValues.occurred_on).format(
        "dddd, DD.MM.YYYY"
      )}`}
      wide
      primaryButtonText={
        <FormattedMessage
          id="AttendanceEdit.saveAttendanceButton"
          defaultMessage="Save"
        />
      }
      primaryButtonDisabled={totalStudents === 0}
      secondaryButtonText={
        <FormattedMessage
          id="AttendanceEdit.cancelButton"
          defaultMessage="Cancel"
        />
      }
      selectorPrimaryFocus={false}
    >
      {totalStudents === 0 && (
        <Blockquote kind="warning">
          <FormattedMessage
            id="info.noAttendanceNoClassInfo"
            defaultMessage="If no students came to class today, please do not fill this table and choose No class today, Holiday or Pedagogical Day-off as no-meal reason."
          />
        </Blockquote>
      )}
      <FormProvider {...methods}>
        <form
          onChange={updateStorePreview}
          onSubmit={handleSubmit(onSubmit)}
          className="attendance-edit"
        >
          <Controller as={<input type="hidden" />} name={`occurred_on`} />
          {defaultValues.levels.map((level, i) => (
            /** Hidden fields to preserve level and shift info when submitting */
            <>
              <Controller
                as={<input type="hidden" />}
                name={`levels[${i}].level`}
              />
              <Controller
                as={<input type="hidden" />}
                name={`levels[${i}].shift`}
              />
            </>
          ))}
          {!isMobile
            ? attendanceDesktop(
                showMorningFields,
                showAfternoonFields,
                rowsDesktop,
                totalsDesktop
              )
            : attendanceMobile(
                showMorningFields,
                showAfternoonFields,
                rowsMobileMorning,
                totalsMobileMorning,
                rowsMobileAfternoon,
                totalsMobileAfternoon
              )}
          <button className="hidden-btn" ref={submitBtnRef}>
            <FormattedMessage
              id="AttendanceEdit.submitNormallyButton"
              defaultMessage="Submit Normally"
            />
          </button>
        </form>
      </FormProvider>
      <div style={{ marginTop: "3%" }}>
        <ButtonRouter
          id="insert-all-button"
          kind="primary"
          style={{ marginRight: "1.5%", marginBottom: "5px" }}
          iconReverse
          icon={<FontAwesomeIcon icon={faPlus} />}
          disabled={
            params.details.split("-")[1] === "prepopulate" ||
            params.details.split("-")[2] === "prepopulate"
          }
          to={buildAttendanceUrl(
            occurredOnDate,
            params.schoolId,
            params.item,
            params.thrItem,
            params.studentItem,
            currentSchoolEnabledStudentAttendance
              ? `${currentParam}-prepopulate-student-attendance`
              : `${currentParam}-prepopulate-enrolment`
          )}
        >
          {currentSchoolEnabledStudentAttendance ? (
            <FormattedMessage
              id="AttendanceEdit.prepopulateAttendanceEnrolemnt"
              defaultMessage="Insert from attendance"
            />
          ) : (
            <FormattedMessage
              id="AttendanceEdit.prepopulateEnrolemnt"
              defaultMessage="Insert all students"
            />
          )}
        </ButtonRouter>
        <ButtonRouter
          id="insert-previous-button"
          kind="primary"
          style={{ marginRight: "1.5%" }}
          iconReverse
          icon={<FontAwesomeIcon icon={faPlus} />}
          disabled={
            params.details.split("-")[1] === "previous" ||
            params.details.split("-")[2] === "previous"
          }
          to={buildAttendanceUrl(
            occurredOnDate,
            params.schoolId,
            params.item,
            params.thrItem,
            params.studentItem,
            `${currentParam}-previous-attendance`
          )}
        >
          {currentSchoolEnabledStudentAttendance ? (
            <FormattedMessage
              id="AttendanceEdit.AutoPopulationCanteenPrev"
              defaultMessage="Insert previous data"
            />
          ) : (
            <FormattedMessage
              id="AttendanceEdit.AutoPopulationPrev"
              defaultMessage="Insert previous data"
            />
          )}
        </ButtonRouter>
      </div>
      {!previousAttendanceStoreWithAttendanceData &&
        (params.details.split("-")[1] === "previous" ||
          params.details.split("-")[2] === "previous") && (
          <Blockquote kind="warning">
            <FormattedMessage
              id="attendance.populationWarning"
              defaultMessage="There is no previous attendance to import.1"
            />
          </Blockquote>
        )}
      {!studentAttendanceData &&
        currentSchoolEnabledStudentAttendance &&
        (params.details.split("-")[1] === "prepopulate" ||
          params.details.split("-")[2] === "prepopulate") && (
          <Blockquote kind="warning">
            <FormattedMessage
              id="attendance.prepopulateWarning"
              defaultMessage="There is no attendance data available to import for the current day."
            />
          </Blockquote>
        )}
    </ModalExtended>
  );
};

export default withSizes(mapSizesToProps)(AttendanceEdit);
