import React from "react";
import MainContent from "components/MainContent";
import { Module, ModuleBody, Button, InlineLoading, Tag } from "@wfp/ui";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import UserEdit from "components/UserView/UserEdit";
import { connect } from "react-redux";
import { getUsers, requestUsersFromSchool } from "data-handler/ducks/users";
import { faPlusCircle, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { getLocalUsersList } from "data-handler/ducks/localAuth";
import { getRoles } from "data-handler/ducks/roles";
import { GetRoleLabel } from "helpers/roles";
import { getCanUseOfflineMode } from "helpers/users";

function UserEntry({ entry, params }) {
  const editUrl = `/school/${params.schoolId}/info/${params.item}/${entry.id}`;
  const userRoleLabel = GetRoleLabel({
    user_role: entry.role?.replace("_at", ""),
  });

  return (
    <div className={styles.userEntry}>
      <div>
        <h3 className={styles.userTitle}>
          {entry.other_names} {entry.last_name}
        </h3>
        <div className={styles.userWorkTitle}>
          {entry.isCiamUser && (
            <>
              <Tag type="info">CIAM user</Tag>
            </>
          )}
        </div>
        {userRoleLabel}
      </div>
      <div className={styles.userEntryButtons}>
        <NavLink to={editUrl}>
          <Button
            kind="secondary"
            icon={<FontAwesomeIcon icon={faEdit} />}
            iconReverse
            small
          >
            <FormattedMessage id="UserView.edit" defaultMessage="edit" />
          </Button>
        </NavLink>
      </div>
    </div>
  );
}

function UserView({ fetching }) {
  let params = useParams();

  // Don't allow users to edit offline admin users
  const users = useSelector(getLocalUsersList).filter(
    (user) => !getCanUseOfflineMode(user)
  );

  const { roles, countryRoles } = useSelector(getRoles);

  const newUserUrl = `/school/${params.schoolId}/info/${params.item}/newuser`;
  const schoolYearSubTitle = (
    <FormattedMessage id="UserView.school_year" defaultMessage="School year" />
  );
  const userSettingsTitle = (
    <FormattedMessage
      id="UserView.user_settings"
      defaultMessage="User settings"
    />
  );

  return (
    <>
      {params.details && (
        <UserEdit user={users[params.details]} params={params} />
      )}
      <MainContent subTitle={schoolYearSubTitle} title={userSettingsTitle}>
        {!users && fetching && <InlineLoading />}
        {users && (
          <Module noMargin>
            <ModuleBody noPadding>
              {users.map((e, i) => (
                <UserEntry
                  key={i}
                  params={params}
                  entry={e}
                  roles={roles}
                  countryRoles={countryRoles}
                />
              ))}
            </ModuleBody>
          </Module>
        )}
        <div>
          <NavLink to={newUserUrl}>
            <Button
              className={styles.newUserButton}
              icon={<FontAwesomeIcon icon={faPlusCircle} />}
            >
              <FormattedMessage
                id="UserView.new_user"
                defaultMessage="New user"
              />
            </Button>
          </NavLink>
        </div>
      </MainContent>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    fetching: state.fetching,
    users: getUsers(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestUsersFromSchool: (id) => dispatch(requestUsersFromSchool(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserView);
