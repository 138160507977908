import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

import {
  buildAttendanceUrl,
  getFilteredAttendanceTotal,
} from "helpers/attendance";
import CommodityShowSmall from "components/CommodityShowSmall";
import SidebarItem from "components/SidebarItem";
import { AttendanceItemProps } from "./types";
import { getModelDateFormat } from "components/UneditableMessage";

type THRAttendanceItemParams = {
  thrItem: string;
  schoolId: string;
};

const THRAttendanceItem: React.ComponentType<AttendanceItemProps> = ({
  currentStoreData,
  store,
  studentStore,
  thrStore,
  storeToChooseType,
  currentSchoolEnabledStudentAttendance,
  ...extraProps
}) => {
  const params: THRAttendanceItemParams = useParams();

  // Date from one of the available dates.
  const storeDate: string =
    getModelDateFormat(store) ||
    getModelDateFormat(studentStore) ||
    getModelDateFormat(thrStore) ||
    getModelDateFormat(currentStoreData);

  const selected =
    (currentStoreData && currentStoreData.client_id === thrStore.client_id) ||
    (currentStoreData === undefined && thrStore.client_id === params.thrItem);

  let subTitle: any;
  subTitle = (
    <>
      {getFilteredAttendanceTotal({
        attendance: thrStore,
        kinds: ["male"],
      })}{" "}
      <FormattedMessage id="app.boys" defaultMessage="boys" />,{" "}
      {getFilteredAttendanceTotal({
        attendance: thrStore,
        kinds: ["female"],
      })}{" "}
      <FormattedMessage id="app.girls" defaultMessage="girls" />
    </>
  );
  const title = (
    <>
      {getFilteredAttendanceTotal({
        attendance: thrStore,
      })}{" "}
      <FormattedMessage id="app.students" defaultMessage="students" />
    </>
  );

  return (
    <SidebarItem
      {...extraProps}
      store={thrStore}
      selected={selected}
      subTitle={subTitle}
      id={thrStore.client_id}
      title={title}
      to={buildAttendanceUrl(
        storeDate,
        params.schoolId,
        store?.client_id,
        thrStore?.client_id,
        studentStore?.client_id
      )}
    >
      <CommodityShowSmall
        commodities={store?.consumption?.commodities!}
        className="MealsStock"
      />
    </SidebarItem>
  );
};

export default THRAttendanceItem;
