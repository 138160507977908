import React, { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Module, ModuleBody, ModuleHeader } from "@wfp/ui";

import AttendanceHistoryChart from "components/AttendanceHistoryChart";
import ItemSwitcher from "components/ItemSwitcher";
import MainContent from "components/MainContent";
import HistoryFilter from "components/HistoryFilter";
import SubTitle from "components/SubTitle";
import StartASchoolYearFirst from "components/StartASchoolYearFirst";

import {
  getAllSchoolYears,
  getCurrentSchoolYear,
  syncableSortFnDecDateTime,
  getAllEnrolmentUpdates,
  getAllAttendances,
  getAllAttendanceStoresByDay,
} from "data-handler/ducks/stores";

import { getCurrentSchoolProfile } from "data-handler/ducks/schools";

import { getFilteredAttendanceTotal } from "helpers/attendance";
import { getFilteredEnrolmentTotalOnDate } from "helpers/enrolment";
import {
  isMealAttendance,
  isStudentAttendance,
  isTakeHomeRationAttendance,
} from "helpers/stores";

import styles from "./styles.module.scss";

const _enrolmentChartEntryByDate = (schoolYears, enrolmentUpdates, date) => {
  // TODO: avoid faking redux state
  const pseudoReduxState = {
    stores: { stores: [...schoolYears, ...enrolmentUpdates] },
  };
  return {
    date: moment(date),
    formatDate: moment(date).format("X"),
    enrolment: getFilteredEnrolmentTotalOnDate({ date })(pseudoReduxState),
  };
};

const _attendanceChartEntry = (attendance) => {
  const male = getFilteredAttendanceTotal({ attendance, kinds: ["male"] });
  const female = getFilteredAttendanceTotal({
    attendance,
    kinds: ["female"],
  });
  return {
    date: attendance.occurred_on,
    formatDate: moment(attendance.occurred_on).format("X"),
    maleValue: male,
    femaleValue: female,
    value: male + female,
  };
};

/** Attendance Overview Content wrapper */
const AttendanceMainContent = ({ children }) => (
  <MainContent
    title={
      <FormattedMessage
        id="AttendanceHistory.attendanceTitle"
        defaultMessage="Attendance"
      />
    }
  >
    {children}
  </MainContent>
);

export default function AttendanceHistory() {
  const [fromDate, setFromDate] = useState(moment().subtract(1, "year"));
  const schoolYears = useSelector(getAllSchoolYears);
  const currentSchoolYear = useSelector(getCurrentSchoolYear);
  const attendanceStores = useSelector(getAllAttendances);
  const allEnrolmentUpdates = useSelector(getAllEnrolmentUpdates);
  const attendanceStoresByDay = useSelector(getAllAttendanceStoresByDay);
  const currentSchoolProfile = useSelector(getCurrentSchoolProfile);

  const currentSchoolEnabledStudentAttendance =
    currentSchoolProfile.enable_student_attendance;

  const currentSchoolEnabledTHR = currentSchoolProfile.enable_take_home_rations;

  if (currentSchoolYear === null) {
    return (
      <AttendanceMainContent>
        <StartASchoolYearFirst />
      </AttendanceMainContent>
    );
  }

  const attendancesByDateRange = attendanceStores.filter((attendanceReport) =>
    moment(fromDate).isSameOrBefore(attendanceReport.occurred_on, "day")
  );

  const enrolmentUpdatesByDateRange = allEnrolmentUpdates.filter(
    (enrolmentUpdate) =>
      moment(fromDate).isSameOrBefore(enrolmentUpdate.occurred_on, "day")
  );

  // TODO: show enrolment chart entries for school years (initial enrolment)
  // const schoolYearsByDateRange = schoolYears.filter((schoolYear) =>
  //   moment(fromDate).isBefore(schoolYear.starts_on)
  // );

  const attendanceChartEntries = attendancesByDateRange.map(
    _attendanceChartEntry
  );

  const today = moment();

  /**
   * Chart entries for each enrolment update/schoolYear,
   * plus one entry today and one on `fromDate`
   */
  const enrolmentChartEntries = [
    _enrolmentChartEntryByDate(schoolYears, allEnrolmentUpdates, fromDate),
    ...enrolmentUpdatesByDateRange.map((enrolmentUpdate) =>
      _enrolmentChartEntryByDate(
        schoolYears,
        allEnrolmentUpdates,
        enrolmentUpdate.occurred_on
      )
    ),
    ...schoolYears.map((schoolYear) =>
      _enrolmentChartEntryByDate(
        schoolYears,
        allEnrolmentUpdates,
        schoolYear.starts_on
      )
    ),
    ...schoolYears.map((schoolYear) =>
      _enrolmentChartEntryByDate(
        schoolYears,
        allEnrolmentUpdates,
        schoolYear.ends_on
      )
    ),
    _enrolmentChartEntryByDate(schoolYears, allEnrolmentUpdates, today),
  ];

  // Lump all chart points together, Recharts will take care of plotting them correctly
  const chartEntries = [...attendanceChartEntries, ...enrolmentChartEntries]
    .filter((entry) =>
      moment(entry.date).isBetween(fromDate, today, "day", "[]")
    )
    .sort((a, b) => (moment(b.date).isAfter(a.date) ? 1 : -1));

  const sortedItems = [
    ...attendancesByDateRange,
    ...enrolmentUpdatesByDateRange,
  ].sort(syncableSortFnDecDateTime);

  return (
    <AttendanceMainContent>
      <div className={styles.overview}>
        <Module noMargin className={styles.chart}>
          <ModuleHeader filter={<HistoryFilter setFromDate={setFromDate} />}>
            {
              <FormattedMessage
                id="AttendanceHistory.attendanceHeader"
                defaultMessage="Attendance & enrolment"
              />
            }
          </ModuleHeader>
          <ModuleBody noPadding>
            <AttendanceHistoryChart data={chartEntries} />
          </ModuleBody>
        </Module>
      </div>
      <SubTitle>
        <FormattedMessage
          id="AttendanceHistory.historySubtitle"
          defaultMessage="History"
        />
      </SubTitle>
      <Module noMargin className={styles.list}>
        <ModuleBody noPadding>
          {sortedItems.map((store) => {
            if (
              isMealAttendance(store) ||
              isStudentAttendance(store) ||
              isTakeHomeRationAttendance(store)
            ) {
              const data = attendanceStoresByDay[store.occurred_on];

              return (
                <ItemSwitcher
                  store={data.mealAttendance}
                  studentStore={data.studentAttendance}
                  thrStore={data.thrAttendance}
                  storeToChooseType={store}
                  currentSchoolEnabledStudentAttendance={
                    currentSchoolEnabledStudentAttendance
                  }
                  currentSchoolEnabledTHR={currentSchoolEnabledTHR}
                  key={store.client_id}
                />
              );
            } else {
              return <ItemSwitcher store={store} key={store.client_id} />;
            }
          })}
        </ModuleBody>
      </Module>
    </AttendanceMainContent>
  );
}
