import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";

import { Module, ModuleBody, List, ListItem } from "@wfp/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { getReportedStores } from "helpers/report";
import { studentKinds } from "SCConstants";
import DeleteModalButton from "components/DeleteModalButton";

import ButtonRouter from "components/ButtonRouter";
import LevelTitle from "components/LevelTitle";
import MainContent from "components/MainContent";
import SubTitle from "components/SubTitle";
import TableExtended from "components/TableExtended";

import {
  getIsSyncableLocked,
  getCurrentOrLatestSchoolYear,
} from "../../data-handler/ducks/stores";
import { UserPermissionRoles } from "../../SCConstants";
import Gate from "../Gate";

const EnrolmentShow = ({ currentStoreData }) => {
  const params = useParams();
  const reportedStores = useSelector(getReportedStores);
  const isReported = reportedStores[params.item] !== undefined;
  const isLocked = useSelector((state) =>
    getIsSyncableLocked(state, currentStoreData)
  );

  const editUrl = `/school/${params.schoolId}/info/${params.item}/enrolment`;

  const title = (
    <FormattedMessage
      id="EnrolmentShow.title"
      defaultMessage="Enrolment update {date}"
      values={{
        date: moment(currentStoreData.occurred_on).format("DD.MM.YYYY"),
      }}
    />
  );

  const schoolYear = useSelector(getCurrentOrLatestSchoolYear);

  const enrolmentUpdateDate = moment(currentStoreData.occurred_on).format(
    "YYYY-MM-DD"
  );

  return (
    <MainContent title={title} className="deliveries-content">
      <SubTitle
        additional={
          <Gate
            forbidRoles={[UserPermissionRoles.is_viewer]}
            isForbidden={
              isLocked ||
              !moment(enrolmentUpdateDate).isBetween(
                schoolYear?.starts_on,
                schoolYear?.ends_on,
                undefined,
                "[]"
              )
            }
          >
            {currentStoreData.type !== "delete" && (
              <DeleteModalButton currentStoreData={currentStoreData} />
            )}
            <ButtonRouter
              className="edit-nav-link"
              kind="secondary"
              icon={<FontAwesomeIcon icon={faChevronRight} />}
              disabled={isReported}
              to={editUrl}
            >
              <FormattedMessage
                id="EnrolmentShow.editEnrolment"
                defaultMessage="Edit Enrolment"
              />
            </ButtonRouter>
          </Gate>
        }
      >
        <FormattedMessage id="Common.enrolment" defaultMessage="Enrolment" />
      </SubTitle>

      <Module noMargin>
        <ModuleBody>
          <>
            <List kind="details" colon>
              <ListItem
                title={
                  <FormattedMessage
                    id="EnrolmentShow.enrolmentDate"
                    defaultMessage="Enrolment Date"
                  />
                }
              >
                {moment(currentStoreData.occurred_on).format(
                  "dddd, DD.MM.YYYY"
                )}
              </ListItem>

              <ListItem
                title={
                  <FormattedMessage
                    id="EnrolmentEdit.comments"
                    defaultMessage="Comments"
                  />
                }
                style={{ overflowWrap: "break-word" }}
              >
                {currentStoreData.comments}
              </ListItem>
            </List>
          </>
        </ModuleBody>
      </Module>

      <Module noMargin>
        <ModuleBody noPadding>
          <TableExtended>
            <thead>
              <tr>
                <th></th>
                <th colSpan={studentKinds.length}>
                  {" "}
                  <FormattedMessage
                    id="Common.newcomers"
                    defaultMessage="Newcomers"
                  />
                </th>
                <th colSpan={studentKinds.length}>
                  <FormattedMessage
                    id="Common.dropouts"
                    defaultMessage="Dropouts"
                  />
                </th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th></th>
                {studentKinds.map((kind) => (
                  <th>{kind.labelTrans}</th>
                ))}
                {studentKinds.map((kind) => (
                  <th>{kind.labelTrans}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentStoreData.levels.map((level, i) => (
                <tr key={i}>
                  <td>
                    <LevelTitle level={level} />
                  </td>
                  {studentKinds.map((kind) => (
                    <td>{level[`${kind.value}_newcomers`]}</td>
                  ))}
                  {studentKinds.map((kind) => (
                    <td>{level[`${kind.value}_dropouts`]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </TableExtended>
        </ModuleBody>
      </Module>
    </MainContent>
  );
};

export default EnrolmentShow;
