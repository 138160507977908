import React from "react";
import moment, { Moment } from "moment";
import { useSelector } from "react-redux";

import MeasureUnitValue from "components/MeasureUnitValue";
import { stockValueByDateByCommodityByCategory } from "helpers/stock";
import { getAllStores } from "data-handler/ducks/stores";

import "./_stock-value.scss";
import { nonWfpCategory } from "SCConstants";

type CurrentStockProps = {
  commodityId: string;
  category: string;
  date?: Moment;
  measureUnit: { id: number; name: string; symbol: string; decimals: number };
};

const CurrentStock = ({
  commodityId,
  category,
  date,
  measureUnit,
}: CurrentStockProps) => {
  const allStores = useSelector(getAllStores);

  const value = stockValueByDateByCommodityByCategory(
    allStores,
    (date || moment()).format("YYYY-MM-DD"),
    commodityId as any,
    category
  );

  return (
    <div className="stock-value">
      {!isNaN(value) && category !== nonWfpCategory && (
        <>
          Current stock:{" "}
          <MeasureUnitValue measureUnit={measureUnit}>{value}</MeasureUnitValue>
        </>
      )}
    </div>
  );
};

export default CurrentStock;
