import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Select from "react-select";
import CreateableSelect from "react-select/creatable";

import {
  Module,
  ModuleBody,
  ModuleHeader,
  Wrapper,
  TextInput,
  Button,
  InlineLoading,
  colors,
} from "@wfp/ui";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { countriesDuck } from "data-handler/ducks/countries";
import {
  getAllAndPendingSchools,
  requestCurrentAdminSchool,
  getSchoolsFetching,
  createSchool,
  editSchool,
} from "data-handler/ducks/schools";
import {
  getAllSchoolProfilesNonPaginated,
  requestSchoolProfilesNonPaginated,
} from "data-handler/ducks/schoolProfile";
import {
  requestCountryAdminLevels,
  requestSchoolAdmins,
  getCountryAdminLevels,
  getSchoolAdmins,
} from "data-handler/ducks/adminLevels";
import {
  requestCountryAuthorityTypes,
  requestEducationAuthorities,
  getCountryAuthorityTypes,
  getEducationAuthoritiesByAuthorityType,
} from "data-handler/ducks/authorityLevels";
import distributionSitesDuck from "data-handler/ducks/distributionSites";
import { getCurrentUser } from "data-handler/ducks/auth";

import { textInputFields, selectFields, stateOptions } from "./constants";
import { getCreateAdminFields, getAdminLevelFields } from "./utils";

import ConfirmationModal from "components/CountryAdmin/CountryAdminContent/ConfirmationModal";
import { selectContainerStyles } from "SCConstants";
import "./_content.scss";

const CountryAdminSchoolPage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { create, prevPath } = props;

  const [confirmationModalState, setConfirmationModalState] = useState(false);
  const [adminLevelErrorState, setAdminLevelErrorState] = useState(false);
  const [modalType, setModalType] = useState("submit");

  const submitBtnRef = useRef();

  const { schoolId } = useParams();

  const methods = useForm();
  const {
    handleSubmit,
    register,
    errors,
    setValue,
    watch,
    getValues,
  } = methods;

  const fetching = useSelector(getSchoolsFetching);
  const currentSchool = useSelector(getAllAndPendingSchools)[schoolId];
  const allSchoolProfilesNonPaginated = useSelector(
    getAllSchoolProfilesNonPaginated
  );
  const countries = useSelector(countriesDuck.getList);
  const countriesIsFetching = useSelector(countriesDuck.isFetching());
  const currentUser = useSelector(getCurrentUser);
  const countryAdminLevels = useSelector(getCountryAdminLevels);
  const schoolAdmins = useSelector(getSchoolAdmins);
  const countryAuthorityTypes = useSelector(getCountryAuthorityTypes);
  const educationAuthorities = useSelector(
    getEducationAuthoritiesByAuthorityType
  );
  const distributionSites = useSelector(distributionSitesDuck.getList);
  const availableDistributionSites = distributionSites?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  availableDistributionSites.unshift({
    value: null,
    label: "None",
  });

  const existingSchool = currentSchool?.state !== "new";

  const adminFields = countryAdminLevels
    .map((item) => "adm_" + item.level)
    .sort();

  const setAdminsValue = (value, parent) => {
    const parentIndex = parent ? adminFields.indexOf(parent) : -1;
    adminFields.slice(parentIndex + 1).forEach((item) => {
      if (value === "matchingValue") {
        setValue(
          item,
          currentSchool[item]
            ? {
                value: currentSchool[item].id,
                label: currentSchool[item].name,
              }
            : null
        );
      } else {
        setValue(item, value);
      }
    });
  };

  const selectedCountry = watch("country");

  // Redirect to the allSchools path if needed when the school is accessed via the url
  useEffect(() => {
    if (prevPath.includes("newSchools")) {
      if (currentSchool && currentSchool.state !== "new") {
        history.push(`/countryAdmin/schools/allSchools/${currentSchool.id}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSchool]);

  // Fetch the distribution sites
  useEffect(() => {
    dispatch(distributionSitesDuck.fetchList());
  }, [dispatch]);

  useEffect(() => {
    if (
      currentUser.is_admin &&
      countries.length === 0 &&
      !countriesIsFetching
    ) {
      dispatch(countriesDuck.fetchList());
    }
  }, [currentUser, countries, countriesIsFetching, dispatch]);

  useEffect(() => {
    if (!create) {
      if (!currentSchool) {
        dispatch(requestCurrentAdminSchool(schoolId, history));
      } else if (!selectedCountry) {
        // Set the country value if it's not loaded from the school
        setValue("country", {
          value: currentSchool.country.id,
          label: currentSchool.country.name,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolId, create, currentSchool, dispatch]);

  useEffect(() => {
    if (selectedCountry) {
      // Request all the data that depends on the country
      dispatch(requestSchoolProfilesNonPaginated(selectedCountry.value));
      dispatch(requestCountryAuthorityTypes(selectedCountry.value));
      dispatch(requestEducationAuthorities(selectedCountry.value));
      dispatch(requestCountryAdminLevels(selectedCountry.value));
      dispatch(requestSchoolAdmins(1, selectedCountry.value));
      // Prevent the profile, authorities and admin fields from resetting when accessing
      // a school that already has these fields
      if (selectedCountry.value !== currentSchool?.country.id) {
        setValue("profile", null);
        setValue("authorities", null);
        setAdminsValue(null);
      } else {
        // Request the admin fields children for the already selected fields
        // skip the first admin field and the last one
        if (currentSchool) {
          const schoolAdminFields = Object.keys(currentSchool).filter((key) =>
            key.startsWith("adm_")
          );
          for (
            let adminField = 0;
            adminField < schoolAdminFields.length - 1;
            adminField++
          ) {
            const value = schoolAdminFields[adminField];
            if (currentSchool[value]) {
              const id = currentSchool[value].id;
              const nextAdminLevelIndex = schoolAdminFields.indexOf(value) + 1;
              const nextAdminLevel = schoolAdminFields[
                nextAdminLevelIndex
              ].split("_")[1];
              dispatch(
                requestSchoolAdmins(nextAdminLevel, selectedCountry.value, id)
              );
            }
          }
        }
        setAdminsValue("matchingValue");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, dispatch]);

  function requestSchoolAdminsChildren(field, fieldname) {
    if (field && adminFields.indexOf(fieldname) !== adminFields.length - 1) {
      const nextAdminLevelIndex = adminFields.indexOf(fieldname) + 1;
      const nextAdminLevel = adminFields[nextAdminLevelIndex].split("_")[1];
      if (!field.__isNew__) {
        dispatch(
          requestSchoolAdmins(
            nextAdminLevel,
            selectedCountry.value,
            field.value
          )
        );
      } else {
        // Dispatch the request even if the admin field is new so that the form can refresh
        // This request will return all the admins of level = current admin level + 1
        // However, no admins will be displayed in the options for the next admin level
        // due to the condition in the formFields for the countryAdminLevel fields
        dispatch(requestSchoolAdmins(nextAdminLevel, selectedCountry.value));
      }
      setAdminsValue(null, fieldname);
    }
  }

  function adminFieldChange(fieldname) {
    requestSchoolAdminsChildren(getValues()[fieldname], fieldname);
  }

  const getAuthorityName = (id) => {
    // Return the name of the country authority type related to the id param
    const authorityType = countryAuthorityTypes.find((item) => item.id === id);
    return authorityType?.name;
  };

  const groupedEducationAuthorities = Object.keys(educationAuthorities).map(
    (i) => ({
      label: (
        <FormattedMessage
          id="CountryAdmin.authorityLabel"
          defaultMessage="{name}"
          values={{
            name: getAuthorityName(
              educationAuthorities[i][0]?.country_authority_type
            ),
          }}
        />
      ),
      options: educationAuthorities[i].map((item) => ({
        label: item.name,
        value: item.id,
      })),
    })
  );

  /* Set style for when errors contain an error set by the register or rule prop within the controller.
    also Recreated the wfp--react-select-container as this was overriding the styles 
     which prevented changing the border colour in the event of an error.
  */
  const standardStyleController = (disabled) => {
    return {
      control: (styles) => ({
        ...styles,
        border: "1px solid rgba(140, 164, 181, 1)",
        opacity: disabled ? "0.5" : "",
      }),
    };
  };

  const invalidStyle = { borderColor: colors["support-01"].hex };

  const invalidAdminLevelLabel = (
    <FormattedMessage
      id="countryAdmin.invalidAdminLevel"
      defaultMessage="Admin Level 1 and 4 is required, Please configure Admin levels for your country within the country profile"
    />
  );

  // Check if ADM 4 is present
  const correctAdminLevel = countryAdminLevels.filter(
    (level) => level.level === 4 || level.level === 1
  );

  const onSubmit = (data) => {
    let submissionData = {};
    // Get the admin fields
    const adminLevelFields = getAdminLevelFields(data, adminFields);
    // Check if there is a created admin field
    const createdAdmins = adminLevelFields.filter(
      (item) => item.isNew === true
    );
    if (createdAdmins && createdAdmins.length > 0) {
      // Create the object needed for creating the admin fields on the backend
      submissionData["adms_for_create"] = getCreateAdminFields(
        createdAdmins,
        countryAdminLevels
      );
    } else {
      submissionData["adm_4"] = data["adm_4"]?.value;
      submissionData["adm_1"] = data["adm_1"]?.value;
    }
    // Extract the select fields from data
    const {
      state,
      authorities,
      country,
      profile,
      adm_1,
      adm_2,
      adm_3,
      adm_4,
      ...remainingData
    } = data;
    // Extract the select fields from data
    submissionData = {
      country: country?.value,
      profile: profile?.value,
      authorities: authorities ? authorities.map((item) => item.value) : [],
      state: state?.value,
      ...submissionData,
      ...remainingData,
    };

    if (correctAdminLevel.length <= 1) {
      // Dispatch error is ADM 4 is not present
      setAdminLevelErrorState(true);
    } else {
      if (create) {
        dispatch(createSchool(submissionData, history));
      } else {
        dispatch(editSchool(submissionData, schoolId, history, prevPath));
      }
    }
  };

  const textInputFormFields = textInputFields.map(
    ({ id, name, labelText, required }) => {
      return {
        type: "text",
        id: id,
        name: name,
        labelText: labelText,
        defaultValue: currentSchool ? currentSchool[name] : "",
        required: required,
        errors: errors[name],
      };
    }
  );

  const selectOptions = {
    country: {
      options: countries?.map((country) => ({
        label: country.name,
        value: country.id,
      })),
      defaultValue: create
        ? currentUser?.country
          ? {
              value: currentUser.country.id,
              label: currentUser.country.name,
            }
          : null
        : currentSchool?.country
        ? {
            value: currentSchool.country.id,
            label: currentSchool.country.name,
          }
        : null,
      required: currentUser.is_admin ? true : false,
      disabled: currentUser.is_admin ? false : true,
    },
    profile: {
      options: allSchoolProfilesNonPaginated?.map((profile) => ({
        label: profile.name,
        value: profile.id,
      })),
      defaultValue: currentSchool?.profile
        ? {
            value: currentSchool.profile.id,
            label: currentSchool.profile.name,
          }
        : null,
      required: true,
      disabled:
        selectedCountry && allSchoolProfilesNonPaginated !== null
          ? false
          : true,
    },
    authorities: {
      options: groupedEducationAuthorities,
      defaultValue:
        currentSchool?.authorities && currentSchool?.authorities.length !== 0
          ? currentSchool.authorities.map((item) => ({
              value: item.id,
              label: item.name,
            }))
          : null,
      required: false,
      disabled: false,
    },
  };

  const selectFormFields = selectFields.map(
    ({ id, name, labelText, isMulti }) => {
      return {
        type: "select",
        id: id,
        name: name,
        labelText: labelText,
        defaultValue: selectOptions[name].defaultValue,
        options: selectOptions[name].options,
        isMulti: isMulti,
        required: selectOptions[name].required,
        disabled: selectOptions[name].disabled,
        errors: errors[name],
        Component: Select,
      };
    }
  );

  let formFields = [
    ...textInputFormFields,
    ...selectFormFields,
    // Add the state field if the school is not in the new state
    existingSchool && !create
      ? {
          type: "select",
          id: "state",
          name: "state",
          labelText: (
            <FormattedMessage id="countryAdmin.state" defaultMessage="State" />
          ),
          defaultValue: currentSchool?.state
            ? stateOptions.find((item) => item.value === currentSchool.state)
            : null,
          options: stateOptions,
          isMulti: false,
          required: true,
          disabled: false,
          errors: errors.state,
          Component: Select,
        }
      : {},
  ];

  if (selectedCountry) {
    formFields.push(
      ...countryAdminLevels.map((item) => {
        const fieldname = "adm_" + item.level;
        const previousAdminLevelIndex = adminFields.indexOf(fieldname) - 1;
        const previousAdminLevel = adminFields[previousAdminLevelIndex];
        const disabled = () => {
          if (correctAdminLevel.length <= 1 && adminLevelErrorState === false) {
            // Dispatch error is ADM 4 is not present
            setAdminLevelErrorState(true);
          }
          if (correctAdminLevel.length > 1 && adminLevelErrorState === true) {
            // Dispatch error is ADM 4 is not present
            setAdminLevelErrorState(false);
          }
          if (!selectedCountry) {
            return true;
          }
          return item.level === 1
            ? false
            : getValues()[previousAdminLevel]
            ? false
            : true;
        };
        return {
          type: "select",
          id: fieldname,
          name: fieldname,
          labelText: item.name,
          defaultValue:
            selectedCountry?.value === currentSchool?.country?.id &&
            currentSchool
              ? currentSchool[fieldname]
                ? {
                    value: currentSchool[fieldname].id,
                    label: currentSchool[fieldname].name,
                  }
                : null
              : null,
          options:
            getValues() && getValues()[previousAdminLevel]?.__isNew__
              ? []
              : schoolAdmins[item.level]?.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
          isMulti: false,
          required: true,
          disabled: disabled(),
          errors: errors[fieldname],
          Component: CreateableSelect,
        };
      })
    );
  }

  const openConfirmationModal = (type) => {
    setModalType(type);
    setConfirmationModalState(true);
  };

  // In order to submit form data. Hidden button is created within the form.
  // As the primary button within the modal cannot be set to type="submit"
  // When the modal submit button is clicked. This subsequently clicks the designated form submit button.
  const onConfirmationModalSubmit = () => {
    setConfirmationModalState(false);
    if (modalType === "delete") {
      onSubmit();
    } else {
      submitBtnRef.current.click();
    }
  };

  if (!create && (!currentSchool || fetching)) {
    return (
      <div className="inlineLoading">
        <InlineLoading />
      </div>
    );
  }
  return (
    <div className="admin-school-page">
      <Wrapper
        pageWidth="sm"
        spacing="md"
        background="lighter"
        className="table"
      >
        <Module>
          <ModuleHeader className="country-admin-school-form-row">
            <Link exact="true" to={prevPath} style={{ paddingRight: "10px" }}>
              <FontAwesomeIcon icon={faChevronLeft} size="lg" />
            </Link>
            {currentSchool ? currentSchool.name : "New school"}
          </ModuleHeader>
          <ModuleBody>
            <div className="country-admin-school-form">
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {formFields.map((item) => {
                    switch (item.type) {
                      case "text":
                        return (
                          <div
                            key={item.id}
                            className="country-admin-school-form-row"
                          >
                            <TextInput
                              name={item.name}
                              id={item.id}
                              style={item.errors ? invalidStyle : {}}
                              labelText={item.labelText}
                              defaultValue={item.defaultValue}
                              inputRef={register({ required: item.required })}
                            />
                          </div>
                        );
                      case "select":
                        return (
                          <div
                            key={item.id}
                            className="country-admin-school-form-row"
                          >
                            <label
                              className="wfp--label"
                              style={{
                                opacity: item.disabled ? "0.5" : "",
                              }}
                            >
                              {item.labelText}
                            </label>
                            <Controller
                              render={(props) => (
                                <item.Component
                                  styles={
                                    item.errors
                                      ? selectContainerStyles.invalid
                                      : standardStyleController(item.disabled)
                                  }
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  defaultValue={item.defaultValue}
                                  options={item.options}
                                  isMulti={item.isMulti}
                                  isDisabled={item.disabled}
                                  value={props.value}
                                  onChange={(value) => {
                                    props.onChange(value);
                                    if (adminFields.includes(item.name)) {
                                      adminFieldChange(item.name);
                                    }
                                  }}
                                />
                              )}
                              id={item.id}
                              name={item.name}
                              defaultValue={item.defaultValue}
                              rules={{ required: item.required }}
                            />
                          </div>
                        );
                      default:
                        return null;
                    }
                  })}

                  <div
                    className="country-admin-school-form-row"
                    style={{ color: colors["support-01"].hex }}
                  >
                    {
                      // Only display if there are errors.
                      // Unable to use useForm message within "errors" variable as this does not support our translation method
                      Object.keys(errors).length === 0 ? (
                        ""
                      ) : (
                        <FormattedMessage
                          id="countryAdmin.schoolProfileErrors"
                          defaultMessage="Please enter a valid value within the highlighted fields"
                        />
                      )
                    }
                    <div className="admin-levels-error">
                      {adminLevelErrorState && invalidAdminLevelLabel}
                    </div>
                  </div>
                  <ConfirmationModal
                    confirmationModalState={confirmationModalState}
                    modalType={modalType}
                    setConfirmationModalState={setConfirmationModalState}
                    onConfirmationModalSubmit={onConfirmationModalSubmit}
                    submitMessage={
                      <FormattedMessage
                        id="countyAdmin.schoolProfileSubmit"
                        defaultMessage="Are you sure you want to {type} the data of {name}?"
                        values={{
                          name: currentSchool?.name ? (
                            currentSchool.name
                          ) : (
                            <FormattedMessage
                              id="countyAdmin.newSchool"
                              defaultMessage="this new school"
                            />
                          ),
                          type: modalType === "submit" ? "submit" : "delete",
                        }}
                      />
                    }
                  />
                  <div className="country-admin-submit-button">
                    <Button
                      style={{
                        marginRight: 15,
                      }}
                      kind="primary"
                      onClick={() => openConfirmationModal("submit")}
                    >
                      <FormattedMessage
                        id="Report.actionSubmit"
                        defaultMessage="Submit"
                      />
                    </Button>
                    {/* Fake Button to submit form data 
                      Check onConfirmationModalSubmit
                      That function controls this button.                  
                    */}
                    <button
                      type="submit"
                      className="hidden-btn"
                      ref={submitBtnRef}
                    />
                  </div>
                </form>
              </FormProvider>
            </div>
          </ModuleBody>
        </Module>
      </Wrapper>
    </div>
  );
};

export default CountryAdminSchoolPage;
