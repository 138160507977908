import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { Button, User, Tabs } from "@wfp/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBug,
  faUser,
  faUserFriends,
  faChartBar,
  faIdBadge,
} from "@fortawesome/free-solid-svg-icons";

import TabLink from "components/TabLink";

import "./_content.scss";

class MobileNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.child = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.location && this.props.location) {
      this.closeMenu();
    }
  }

  closeMenu = () => {
    //this.child.current.onChangeSub("close");
  };

  render() {
    const homeScreen = window.innerHeight / window.screen.height > 0.9;
    // Some probability of this being a standalone app.

    const classes = classnames(
      "country-admin-mobile-nav-bottom",
      "safari_only",
      {
        "country-admin-mobile-nav-bottom--homescreen": homeScreen,
      }
    );

    return (
      <>
        <div className={classes}>
          <Tabs customTabContent>
            <TabLink exact to={"/countryAdmin/"} renderListElement={TabLink}>
              <div>
                <FontAwesomeIcon icon={faChartBar} />
                <FontAwesomeIcon icon={faChartBar} />
              </div>
              <FormattedMessage
                id="CountryAdmin.countryProfile"
                defaultMessage="Country Profile"
              />
            </TabLink>
            <TabLink
              exact
              to={"/countryAdmin/distributionReport/"}
              renderListElement={TabLink}
            >
              <div>
                <FontAwesomeIcon icon={faChartBar} />
                <FontAwesomeIcon icon={faChartBar} />
              </div>
              <FormattedMessage
                id="CountryAdmin.distributionReport"
                defaultMessage="Distribution Report"
              />
            </TabLink>
            <TabLink
              exact
              to={"/countryAdmin/users/"}
              renderListElement={TabLink}
            >
              <div>
                <FontAwesomeIcon icon={faUserFriends} />
                <FontAwesomeIcon icon={faUserFriends} />
              </div>
              <FormattedMessage
                id="CountryAdmin.users"
                defaultMessage="Users"
              />
            </TabLink>
            <TabLink
              exact
              to={"/countryAdmin/schools/"}
              renderListElement={TabLink}
            >
              <div>
                <FontAwesomeIcon icon={faIdBadge} />
                <FontAwesomeIcon icon={faIdBadge} />
              </div>
              <FormattedMessage
                id="CountryAdmin.schools"
                defaultMessage="Schools"
              />
            </TabLink>
            <TabLink
              exact
              to={"/countryAdmin/schoolProfiles/"}
              renderListElement={TabLink}
            >
              <div>
                <FontAwesomeIcon icon={faIdBadge} />
                <FontAwesomeIcon icon={faIdBadge} />
              </div>
              <FormattedMessage
                id="CountryAdmin.schoolProfiles"
                defaultMessage="School Profiles"
              />
            </TabLink>
            <TabLink
              exact
              to={"/countryAdmin/vendors/"}
              renderListElement={TabLink}
            >
              <div>
                <FontAwesomeIcon icon={faIdBadge} />
                <FontAwesomeIcon icon={faIdBadge} />
              </div>
              <FormattedMessage
                id="CountryAdmin.vendors"
                defaultMessage="Vendors"
              />
            </TabLink>
          </Tabs>
        </div>
        <div className="country-admin-mobile-nav">
          <div className="country-admin-mobile-nav__content">
            <NavLink exact to={"?select=school&nav=true"}>
              <User ellipsis />
            </NavLink>

            <div className="country-admin-mobile-nav__title">
              <FormattedMessage
                id={`CountryAdmin.${
                  this.props.match.params.page
                    ? this.props.match.params.page
                    : "countryProfile"
                }`}
                defaultMessage={
                  this.props.match.params.page
                    ? this.props.match.params.page
                    : "Country Profile"
                }
              />
            </div>
            <NavLink
              exact
              to={`?select=user&nav=true`}
              data-testid="mobile-nav-user"
            >
              <Button
                className="country-admin-mobile-nav__sync-btn"
                small
                kind="ghost"
                icon={<FontAwesomeIcon icon={faUser} />}
              ></Button>
            </NavLink>
            <NavLink exact to={`?select=debug&nav=true`}>
              <Button
                className="country-admin-mobile-nav__sync-btn"
                small
                kind="ghost"
                icon={<FontAwesomeIcon icon={faBug} />}
              ></Button>
            </NavLink>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    currentCountry: state.auth.currentUser?.country,
    currentUser: state.auth.currentUser,
  };
};

export default compose(withRouter, connect(mapStateToProps))(MobileNav);
