import React from "react";

import moment from "moment";

import { ListItem, List } from "@wfp/ui";
import "./_chart-tooltip.scss";

const Entry = ({ children }) => {
  if (children) {
    return <>{children}</>;
  } else {
    return <>-</>;
  }
};

export const ChartTooltipContent = (props) => {
  const { active, payload, labels } = props;
  if (active && payload && payload[0]) {
    return (
      <div className="chart-tooltip">
        <h3>{moment(payload[0].payload.date).format("dddd")}</h3>
        <h4>{moment(payload[0].payload.date).format("DD.MM.YYYY")}</h4>

        <List kind="simple-inline" colon small>
          {payload.map((e, i) => (
            <ListItem
              key={i}
              title={labels[e.dataKey] ? labels[e.dataKey] : e.dataKey}
              style={{ color: e.stroke }}
            >
              <Entry>{e.value}</Entry>
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
  return null;
};
