import "react-dates/initialize";

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import {
  useForm,
  useFieldArray,
  Controller,
  FormProvider,
} from "react-hook-form";

import moment from "moment";

import {
  RadioButtonGroup,
  RadioButton,
  Select,
  SelectItem,
  Blockquote,
  Modal,
} from "@wfp/ui";

import {
  buildAttendanceUrl,
  getFilteredAttendanceTotal,
} from "helpers/attendance";

import {
  getSchoolYearByDate,
  getAllStores,
  updateStore,
} from "data-handler/ducks/stores";
import commoditiesDuck from "data-handler/ducks/commodities";
import {
  getCurrentSchoolProfileWfpCommodities,
  getCurrentSchoolProfileOtherCommodities,
} from "data-handler/ducks/schools";
import {
  hasPurchaseStore,
  hasTHRStore,
  getSchoolYearLevelsBySchoolYearId,
} from "data-handler/ducks/stores";

import { RepeaterItem } from "components/Repeater";
import CommoditySelect from "components/CommoditySelect";
import QuantityInput from "components/QuantityInput";
import RepeaterAddButton from "components/RepeaterAddButton";
import ErrorList from "components/ErrorList";
import {
  validationResolver,
  requiredLabelCommodities,
  getCategoryInStock,
} from "components/MealsEdit";
import TaheHomeRationsWizard from "components/TakeHomeRations/TaheHomeRationsWizard";
import {
  attendanceDesktop,
  attendanceLevelData,
  attendanceRowsDesktop,
  attendanceTotalsRowDesktop,
} from "components/AttendanceEdit";

import {
  studentKinds,
  commoditySourceOptions,
  takeHomeRationCategory,
  nonWfpCategory,
  purchaseDetailCategory,
} from "SCConstants";

import "./_content.scss";

const storeToFormValues = (store) => {
  const mealsValues = store.consumption
    ? JSON.parse(JSON.stringify(store.consumption)) // deep copy
    : {};
  const attendanceValues = JSON.parse(JSON.stringify(store));
  if (attendanceValues.levels) {
    attendanceValues.levels.forEach((level) => {
      studentKinds.forEach((kind) => {
        // cast to str (may become `"undefined"` - no problem for formValuesToStore)
        level[kind.value] = `${level[kind.value]}`;
      });
    });
  }
  return { ...mealsValues, ...attendanceValues };
};

const formValuesToStore = (values) => {
  // Convert checkboxes to arrays of ints (foreign keys)

  if (values.commodities) {
    values.category = takeHomeRationCategory;
  }
  if (values.levels) {
    values.levels.forEach((level) => {
      studentKinds.forEach((kind) => {
        // cast to int
        level[kind.value] = parseInt(level[kind.value]) || 0;
      });
    });
  }

  return { ...values };
};

const TakeHomeRations = ({
  children,
  currentStoreData,
  currentSchoolProfile,
}) => {
  const intl = useIntl();
  const allStores = useSelector(getAllStores);
  const commodityListOther = useSelector(
    getCurrentSchoolProfileOtherCommodities
  );
  const commoditiesListWfpFromSchoolProfile = useSelector(
    getCurrentSchoolProfileWfpCommodities
  );

  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const newDate = currentStoreData?.occurred_on
    ? moment(currentStoreData?.occurred_on)
    : params.thrItem && params.thrItem.split("+").length === 2
    ? moment(params.thrItem.split("+")[1])
    : params.studentItem && params.studentItem.split("+").length === 2
    ? moment(params.studentItem.split("+")[1])
    : undefined;
  const occurredOnDate = newDate.format("YYYY-MM-DD");
  const schoolYear = useSelector(
    getSchoolYearByDate(newDate?.format("YYYY-MM-DD"))
  );

  const schoolYearLevels = useSelector(
    getSchoolYearLevelsBySchoolYearId(schoolYear.object_id)
  );

  let defaultValues = {
    occurred_on: newDate?.format("YYYY-MM-DD"),
    levels:
      currentStoreData?.levels === undefined
        ? []
        : JSON.parse(JSON.stringify(currentStoreData?.levels)),
    consumption:
      currentStoreData?.consumption === undefined
        ? {}
        : JSON.parse(JSON.stringify(currentStoreData?.consumption)),
  };

  if (schoolYear) {
    // Update defaultValues to account for all current levels and shifts
    schoolYear.levels.forEach((level) => {
      if (
        schoolYear.has_morning_classes &&
        !defaultValues.levels.find(
          (attendanceLevel) =>
            attendanceLevel.level === level.level &&
            attendanceLevel.shift === "morning"
        )
      ) {
        defaultValues.levels.push({ level: level.level, shift: "morning" });
      }
      if (
        schoolYear.has_afternoon_classes &&
        !schoolYear.has_same_students_in_both_shifts &&
        !defaultValues.levels.find(
          (attendanceLevel) =>
            attendanceLevel.level === level.level &&
            attendanceLevel.shift === "afternoon"
        )
      ) {
        defaultValues.levels.push({ level: level.level, shift: "afternoon" });
      }
    });
  }

  const currentSchoolEnabledPurchases =
    (useSelector(hasPurchaseStore) &&
      getCategoryInStock(
        allStores,
        commoditiesListWfpFromSchoolProfile,
        purchaseDetailCategory
      )) ||
    currentSchoolProfile.enable_cash_purchases;

  const currentSchoolEnabledTHR =
    (useSelector(hasTHRStore) &&
      getCategoryInStock(
        allStores,
        commoditiesListWfpFromSchoolProfile,
        takeHomeRationCategory
      )) ||
    currentSchoolProfile.enable_take_home_rations;
  const methods = useForm({
    resolver: validationResolver,
    defaultValues: storeToFormValues(defaultValues),
    context: {
      allStores,
      currentStoreData,
      commodityListOther,
      newDate,
      currentSchoolEnabledPurchases,
      currentSchoolEnabledTHR,
    },
  });

  const [page, setPage] = useState(0);
  const [storePreview, setStorePreview] = useState();
  const [formState, setFormState] = useState();

  const previous = () => {
    setPage(Math.max(page - 1, 0));
  };

  const isLastPage = page === React.Children.count(children) - 1;
  const attendanceLabel = intl.formatMessage({
    id: "takeHomeRations.attendanceLabel",
    defaultMessage: "Enter take-home ration participants",
  });

  const mealsLabel = intl.formatMessage({
    id: "takeHomeRations.mealsLabel",
    defaultMessage: "Enter take-home ration consumption",
  });

  const { control, watch, errors, setValue, register, getValues } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "commodities",
  });

  let previewTimeout;

  const updateStorePreview = () => {
    if (previewTimeout) clearTimeout(previewTimeout);
    previewTimeout = setTimeout(() => {
      setStorePreview({ ...storePreview, ...getValues({ nest: true }) });
    }, 200);
  };

  if (!storePreview) updateStorePreview();

  const showMorningFields = schoolYear
    ? schoolYear.has_morning_classes === true
    : undefined;
  const showAfternoonFields = schoolYear
    ? schoolYear.has_afternoon_classes === true &&
      schoolYear.has_same_students_in_both_shifts !== true
    : undefined;
  const totalsDesktop = attendanceTotalsRowDesktop(
    schoolYear,
    schoolYearLevels,
    showMorningFields,
    showAfternoonFields,
    storePreview
  );

  const rowsDesktop = attendanceRowsDesktop(
    attendanceLevelData(schoolYearLevels),
    storePreview,
    showMorningFields,
    showAfternoonFields,
    defaultValues
  );

  const categoryOptions = [
    {
      value: takeHomeRationCategory,
      name: takeHomeRationCategory,
      label: intl.formatMessage({
        id: "categoryOption.takeHomeRation",
        defaultMessage: "Take-home ration commodity",
      }),
    },
  ];
  const commoditiesIndex = useSelector(commoditiesDuck.getIndex);
  const commoditiesWatch = watch("commodities");
  const categoryWatch = (category, index) =>
    watch(`commodities[${index}].category`) === category ? true : false;

  const totalStudents = getFilteredAttendanceTotal({
    attendance: storePreview,
  });

  const id = (currentStoreData && currentStoreData.client_id) || undefined;

  const closeModal = (newId) => {
    history.push(
      buildAttendanceUrl(
        occurredOnDate,
        params.schoolId,
        params.item,
        newId ? newId : id,
        params.studentItem
      )
    );
  };

  const onSubmit = (values) => {
    // TODO: Adjust Sync object based on new Category Models
    const toBeSaved = {
      ...currentStoreData,
      consumption: {
        ...formValuesToStore(values[2]),
        category: takeHomeRationCategory,
        meal_provided: true,
      },
      ...formValuesToStore(values[1]),
    };

    const store = dispatch(
      updateStore({
        id: id,
        values: toBeSaved,
        occurred_on: defaultValues.occurred_on,
        model: "attendance",
        type: currentStoreData?.client_id ? "update" : "create",
        category: takeHomeRationCategory,
      })
    );
    closeModal(store.data.id);
  };

  if (fields.length === 0) {
    append({});
  }

  return (
    <Modal
      open
      onPreviousClick={previous}
      onRequestClose={() => closeModal(params.thrItem)}
      previousHidden={page > 0 ? false : true}
      nextHidden={isLastPage}
      submitHidden={!isLastPage}
      modalLabel={
        <FormattedMessage
          id="AttendanceEdit.thrAttendanceModalLabel"
          defaultMessage="Take-home ration data"
        />
      }
      secondaryButtonText={intl.formatMessage({
        id: "AttendanceEdit.cancel",
        defaultMessage: "Cancel",
      })}
      modalHeading={`${moment(defaultValues.occurred_on).format(
        "dddd, DD.MM.YYYY"
      )}`}
      wide
      selectorPrimaryFocus={false}
      className="thr-modal"
    >
      <TaheHomeRationsWizard
        methods={methods}
        onSubmit={onSubmit}
        setFormState={setFormState}
        formState={formState}
        nextDisabled={!process.env.REACT_APP_E2E && totalStudents === 0}
      >
        {
          // First page, thr attendance
        }
        <TaheHomeRationsWizard.Page label={attendanceLabel}>
          <FormProvider {...methods}>
            <form onChange={updateStorePreview} className="attendance-edit">
              {totalStudents === 0 && (
                <Blockquote kind="warning">
                  <FormattedMessage
                    id="info.addParticipantsBeforeProceedingWarning"
                    defaultMessage="You need to add participants before proceeding to the next page."
                  />
                </Blockquote>
              )}
              <Controller as={<input type="hidden" />} name={`occurred_on`} />
              {defaultValues.levels.map((level, i) => (
                /** Hidden fields to preserve level and shift info when submitting */
                <>
                  <Controller
                    as={<input type="hidden" />}
                    name={`levels[${i}].level`}
                  />
                  <Controller
                    as={<input type="hidden" />}
                    name={`levels[${i}].shift`}
                  />
                </>
              ))}
              <div className="row">
                {attendanceDesktop(
                  showMorningFields,
                  showAfternoonFields,
                  rowsDesktop,
                  totalsDesktop
                )}
              </div>
            </form>
          </FormProvider>
        </TaheHomeRationsWizard.Page>
        {
          //Second Page, thr meals
        }
        <TaheHomeRationsWizard.Page label={mealsLabel}>
          <FormProvider {...methods}>
            <form onChange={updateStorePreview} className="attendance-edit">
              <>
                {totalStudents === 0 && (
                  <Blockquote kind="warning">
                    <FormattedMessage
                      id="info.noAttendance"
                      defaultMessage="You haven't inputted attendance data therefore we can't
                  populate the total quantity field according to the quantity
                  per student automatically."
                    />
                  </Blockquote>
                )}
                <ErrorList errors={errors} />

                <div>
                  {fields.map((field, index) => (
                    <RepeaterItem
                      key={field.id}
                      removeAction={() => remove(index)}
                      disableRemove={fields.length === 1}
                    >
                      <div>
                        <>
                          <RadioButtonGroup
                            labelText={
                              <FormattedMessage
                                id="MealsEdit.commoditySource"
                                defaultMessage="Commodity Source"
                              />
                            }
                          >
                            {categoryOptions.map((category) => (
                              <RadioButton
                                key={category.value}
                                labelText={category.label}
                                id={`commodities[${index}][${category.value}]`}
                                name={`commodities[${index}].category`}
                                value={category.value}
                                defaultChecked={
                                  category.value === takeHomeRationCategory
                                }
                                onChange={(e) => {
                                  setValue(
                                    `commodities[${index}].category`,
                                    e.target.value,
                                    {
                                      shouldDirty: true,
                                    }
                                  );
                                  setValue(
                                    `commodities[${index}].commodity`,
                                    null,
                                    {
                                      shouldDirty: true,
                                    }
                                  );
                                }}
                                ref={
                                  currentSchoolEnabledPurchases
                                    ? register({ required: true })
                                    : register({ required: false })
                                }
                              />
                            ))}
                          </RadioButtonGroup>
                        </>
                      </div>
                      <Controller
                        render={({ onChange, value }) => (
                          <CommoditySelect
                            category={watch(`commodities[${index}].category`)}
                            onChange={(e) => {
                              setValue(
                                `commodities[${index}].measure_unit`,
                                e.measure_unit
                              );
                              onChange(e.value, e.category);
                            }}
                            value={value}
                            showStock={true}
                            invalidOverride={
                              errors[`commodities[${index}].commodity`] !==
                              undefined
                            }
                            invalidTextOverride={requiredLabelCommodities}
                            date={currentStoreData?.occurred_on}
                          />
                        )}
                        name={`commodities[${index}].commodity`}
                        control={control}
                      />
                      {
                        // Only show `source` field when selected commodity is not from WFP
                        commoditiesWatch &&
                          commoditiesWatch[index] &&
                          commoditiesWatch[index].commodity &&
                          !commoditiesIndex[commoditiesWatch[index].commodity]
                            ?.is_wfp &&
                          categoryWatch(nonWfpCategory, index) && (
                            <Controller
                              className="meals__source"
                              as={
                                <Select
                                  labelText={
                                    <FormattedMessage
                                      id="MealsEdit.source"
                                      defaultMessage="Source"
                                    />
                                  }
                                >
                                  {commoditySourceOptions.map((source) => (
                                    <SelectItem
                                      value={source.value}
                                      text={intl.formatMessage({
                                        id: `Common.${source.key}`,
                                        defaultMessage: source.label,
                                      })}
                                    />
                                  ))}
                                </Select>
                              }
                              name={`commodities[${index}].source`}
                              placeholder=""
                              control={control}
                            />
                          )
                      }

                      <Controller
                        render={({ onChange, onBlur, value }) => {
                          return (
                            <QuantityInput
                              control={control}
                              value={value}
                              onChange={(e) => {
                                if (totalStudents) {
                                  setValue(
                                    `commodities[${index}].quantity`,
                                    parseFloat(
                                      e.target.value * totalStudents
                                    ).toFixed(2),
                                    { shouldDirty: true }
                                  );
                                }
                                return onChange(e);
                              }}
                              onBlur={onBlur}
                              labelText={
                                <FormattedMessage
                                  id="MealsEdit.quantityPerStudents"
                                  defaultMessage="Quantity per student"
                                />
                              }
                              setValue={setValue}
                              name={`commodities[${index}].quantityPerStudent`}
                              invalid={
                                errors[
                                  `commodities[${index}].quantityPerStudent`
                                ]
                              }
                              invalidText={
                                errors[
                                  `commodities[${index}].quantityPerStudent`
                                ]?.message
                              }
                              measureUnit={watch(
                                `commodities[${index}].measure_unit`
                              )}
                            />
                          );
                        }}
                        control={control}
                        name={`commodities[${index}].quantityPerStudent`}
                      />

                      <Controller
                        render={({ onChange, onBlur, value }) => {
                          return (
                            <QuantityInput
                              control={control}
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              labelText={
                                <FormattedMessage
                                  id="MealsEdit.TotalQuantity"
                                  defaultMessage="Total quantity"
                                />
                              }
                              setValue={setValue}
                              name={`commodities[${index}].quantity`}
                              invalid={errors[`commodities[${index}].quantity`]}
                              invalidText={
                                errors[`commodities[${index}].quantity`]
                                  ?.message
                              }
                              measureUnit={watch(
                                `commodities[${index}].measure_unit`
                              )}
                            />
                          );
                        }}
                        control={control}
                        name={`commodities[${index}].quantity`}
                      />

                      <Controller
                        name={`commodities[${index}].measure_unit`}
                        control={control}
                      />
                    </RepeaterItem>
                  ))}
                </div>
                <RepeaterAddButton
                  onClick={() => append({ name: "commodities" })}
                />
              </>
            </form>
          </FormProvider>
        </TaheHomeRationsWizard.Page>
      </TaheHomeRationsWizard>
    </Modal>
  );
};

export default TakeHomeRations;
