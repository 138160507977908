import React from "react";
import { useSelector } from "react-redux";
import { useParams, NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import moment from "moment";

import { Button, Blockquote } from "@wfp/ui";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  getCurrentSchoolYear,
  getStoreByClientId,
  getSchoolYearByDate,
  getPreviousSchoolYears,
  Attendance,
} from "data-handler/ducks/stores";
import { getIsDateLocked } from "data-handler/ducks/stores";

import { isReportOpenByDate } from "helpers/report";
import { getEnrolmentAggregateOnDate } from "helpers/enrolment";
import {
  getPreviousAttendanceStoreWithConsumptionDataByDate,
  getPreviousAttendanceStoreWithAttendanceDataByDate,
  buildAttendanceUrl,
  getPreviousStudentAttendanceStoreWithAttendanceDataByDate,
} from "helpers/attendance";

import Empty from "components/Empty";
import MainContent from "components/MainContent";
import AttendanceShow from "components/AttendanceShow";
import StudentAttendanceShow from "components/StudentAttendanceShow";
import MealsShow from "components/MealsShow";
import MealsEdit from "components/MealsEdit";
import AttendanceEdit from "components/AttendanceEdit";
import StudentAttendanceEdit from "components/StudentAttendanceEdit";
import Section from "components/Section";
import UneditableMessage, {
  getModelDateFormat,
} from "components/UneditableMessage";
import DeleteModalButton from "components/DeleteModalButton";
import ThrAttendanceShow from "components/TakeHomeRationsShow/ThrAttendanceShow";
import ThrMealsShow from "components/TakeHomeRationsShow/ThrMealsShow";
import TakeHomeRations from "components/TakeHomeRations";

import { studentLevelTotals, total, UserPermissionRoles } from "SCConstants";

import attendanceIcon from "images/corn-bag.svg";
import Gate from "../Gate";
import { RootState } from "data-handler/rootReducer";

// This is here because ThrAttendanceShow is untyped and
// type inference does not work.
const TypedThrAttendanceShow = ThrAttendanceShow as any;
const TypedThrMealsShow = ThrMealsShow as any;
const TypedAttendanceEdit = AttendanceEdit as any;
const TypedStudentAttendanceEdit = StudentAttendanceEdit as any;
const TypedTakeHomeRations = TakeHomeRations as any;

export const displayStaffLevel = (schoolYearLevels: { level: string }[]) => {
  const isStaffInLevels = !!schoolYearLevels.find(
    (level) => level.level === "staff"
  );
  return !isStaffInLevels ? studentLevelTotals : total;
};

type SchoolProfile = {
  enable_take_home_rations: boolean;
  enable_student_attendance: boolean;
};

type AttendanceContentProps = {
  currentSchoolProfile: SchoolProfile;
};

type AttendanceContentParams = {
  item: string;
  thrItem: string;
  studentItem: string;
  schoolId: string;
  details: string;
};

const AttendanceContent: React.ComponentType<AttendanceContentProps> = ({
  currentSchoolProfile,
}) => {
  const params: AttendanceContentParams = useParams();

  const currentStoreData = useSelector(
    getStoreByClientId(params.item)
  ) as Attendance;
  const currentThrStoreData = useSelector(getStoreByClientId(params.thrItem));
  const currentStudentStoreData = useSelector(
    getStoreByClientId(params.studentItem)
  );
  const currentSchoolEnabledTHR = currentSchoolProfile.enable_take_home_rations;
  const currentSchoolEnabledStudentAttendance =
    currentSchoolProfile.enable_student_attendance;

  /**
   * Remove this logic for now, until we clarify the whole flow
   */
  // const showAttendanceDeleteButton =
  //   currentStudentStoreData === null &&
  //   !currentStoreData?.consumption &&
  //   currentThrStoreData === null;

  let content = null;
  let title = null;
  let right = null;

  const newDate =
    params.item && params.item.split("+").length === 2
      ? moment(params.item.split("+")[1])
      : params.thrItem && params.thrItem.split("+").length === 2
      ? moment(params.thrItem.split("+")[1])
      : params.studentItem && params.studentItem.split("+").length === 2
      ? moment(params.studentItem.split("+")[1])
      : undefined;

  const getDate: string =
    getModelDateFormat(currentStoreData) ||
    getModelDateFormat(currentThrStoreData) ||
    getModelDateFormat(currentStudentStoreData);

  const currentDate =
    getDate ||
    (newDate && newDate.format("YYYY-MM-DD")) ||
    moment().format("YYYY-MM-DD");

  const currentYear = useSelector((state: RootState) =>
    getCurrentSchoolYear(state)
  ); //users current year
  const previousSchoolYears = useSelector(getPreviousSchoolYears)[0];
  const selectedAttendanceSchoolYear = useSelector(
    getSchoolYearByDate(currentDate)
  );
  const enrolmentData = useSelector(
    getEnrolmentAggregateOnDate(
      moment(currentStoreData ? (currentStoreData as any).occurred_on : newDate)
    )
  );
  const previousAttendanceStoreWithConsumptionData = useSelector(
    getPreviousAttendanceStoreWithConsumptionDataByDate(
      moment(currentStoreData ? (currentStoreData as any).occurred_on : newDate)
    )
  );
  const previousAttendanceStoreWithAttendanceData = useSelector(
    getPreviousAttendanceStoreWithAttendanceDataByDate(
      moment(currentStoreData ? (currentStoreData as any).occurred_on : newDate)
    )
  );
  const previousStudentAttendanceStoreWithAttendanceData = useSelector(
    getPreviousStudentAttendanceStoreWithAttendanceDataByDate(
      moment(currentStoreData ? (currentStoreData as any).occurred_on : newDate)
    )
  );

  const creatingNewItemsIsLocked = useSelector((state: RootState) =>
    getIsDateLocked(state, newDate)
  );

  const isReportOpen = useSelector(isReportOpenByDate(getDate as any));

  const deleteEntireDayDataLabel = (
    <FormattedMessage
      id="sync.deleteAttendanceAndConsumption"
      defaultMessage="Delete both attendance and consumption"
    />
  );

  const enterMealAttendanceButtonLabel = currentSchoolEnabledStudentAttendance ? (
    <FormattedMessage
      id="AttendanceContent.enterMealAttendanceWithStudentAttendanceEnabled"
      defaultMessage="Enter Canteen Attendance"
    />
  ) : (
    <FormattedMessage
      id="AttendanceContent.enterMealAttendance"
      defaultMessage="Enter Attendance"
    />
  );

  if (
    !currentStoreData &&
    !currentThrStoreData &&
    !currentStudentStoreData &&
    !newDate
  ) {
    content = null;
  } else if (
    !currentStoreData &&
    !currentThrStoreData &&
    !currentStudentStoreData &&
    newDate
  ) {
    title = newDate.format("dddd, DD.MM.YYYY");
    content = (
      <Empty
        button={
          <div>
            <Gate
              forbidRoles={[UserPermissionRoles.is_viewer]}
              isForbidden={
                creatingNewItemsIsLocked ||
                (currentYear !== null &&
                  currentYear !== selectedAttendanceSchoolYear) ||
                (currentYear === null &&
                  previousSchoolYears !== selectedAttendanceSchoolYear)
              }
            >
              {currentSchoolEnabledStudentAttendance && (
                <NavLink
                  to={buildAttendanceUrl(
                    currentDate,
                    params.schoolId,
                    params.item,
                    params.thrItem,
                    params.studentItem,
                    "new-student-attendance"
                  )}
                >
                  <Button
                    kind="accent"
                    iconReverse
                    icon={<FontAwesomeIcon icon={faPlus} />}
                  >
                    <FormattedMessage
                      id="AttendanceContent.enterStudentAttendanceButton"
                      defaultMessage="Enter Attendance"
                    />
                  </Button>
                </NavLink>
              )}

              <NavLink
                to={buildAttendanceUrl(
                  currentDate,
                  params.schoolId,
                  params.item,
                  params.thrItem,
                  params.studentItem,
                  "new-attendance"
                )}
              >
                <Button
                  kind="accent"
                  iconReverse
                  icon={<FontAwesomeIcon icon={faPlus} />}
                >
                  {enterMealAttendanceButtonLabel}
                </Button>
              </NavLink>
              <NavLink
                to={buildAttendanceUrl(
                  currentDate,
                  params.schoolId,
                  params.item,
                  params.thrItem,
                  params.studentItem,
                  "new-meals"
                )}
              >
                <Button kind="secondary">
                  <FormattedMessage
                    id="AttendanceContent.enterMealsButton"
                    defaultMessage="Enter Meals"
                  />
                </Button>
              </NavLink>
              {currentSchoolEnabledTHR && (
                <NavLink
                  to={buildAttendanceUrl(
                    currentDate,
                    params.schoolId,
                    params.item,
                    params.thrItem,
                    params.studentItem,
                    "new-take-home-ration-data"
                  )}
                >
                  <Button
                    kind="accent"
                    iconReverse
                    icon={<FontAwesomeIcon icon={faPlus} />}
                  >
                    <FormattedMessage
                      id="AttendanceContent.enterTakeHomeRationAttendanceButton"
                      defaultMessage="Take-home Ration"
                    />
                  </Button>
                </NavLink>
              )}
            </Gate>
            <UneditableMessage
              currentStoreData={{ model: "attendance", occurred_on: newDate }}
            />
          </div>
        }
        title={newDate.format("dddd, DD.MM.YYYY")}
        kind="large"
        icon={
          <img
            alt="attendance empty illustration a bag of rice"
            src={attendanceIcon}
            style={{
              marginLeft: "1%",
              marginRight: "3%",
              marginBottom: "1%",
            }}
          />
        }
      >
        <Section>
          <Gate
            forbidRoles={[UserPermissionRoles.is_viewer]}
            isForbidden={!creatingNewItemsIsLocked}
          >
            <Blockquote kind="warning">
              <p style={{ fontWeight: "bold", color: "#07074E" }}>
                <FormattedMessage
                  id="app.attendance-error-message"
                  defaultMessage="You have closed this months report. In order to provide attendance and meal data, please reopen the report."
                />
              </p>
            </Blockquote>
          </Gate>
        </Section>
        <FormattedMessage
          id="AttendanceContent.noDataEnteredYet"
          defaultMessage="No data entered yet"
        />
      </Empty>
    );
  } else {
    title =
      ((currentStoreData as any)?.occurred_on &&
        moment((currentStoreData as any)?.occurred_on).format(
          "dddd, DD.MM.YYYY"
        )) ||
      ((currentThrStoreData as any)?.occurred_on &&
        moment((currentThrStoreData as any)?.occurred_on).format(
          "dddd, DD.MM.YYYY"
        )) ||
      ((currentStudentStoreData as any)?.occurred_on &&
        moment((currentStudentStoreData as any)?.occurred_on).format(
          "dddd, DD.MM.YYYY"
        ));
    content = (
      <>
        <StudentAttendanceShow
          currentStoreData={currentStoreData as Attendance}
          currentAttendanceStoreData={currentStudentStoreData as Attendance}
          currentSchoolEnabledStudentAttendance={
            currentSchoolEnabledStudentAttendance
          }
          date={getDate}
        />
        <AttendanceShow
          currentStoreData={currentStoreData as Attendance}
          currentStudentStoreData={currentStudentStoreData as Attendance}
          currentSchoolEnabledStudentAttendance={
            currentSchoolEnabledStudentAttendance
          }
          allowDelete={true}
          date={getDate}
        />
        <MealsShow currentStoreData={currentStoreData} date={getDate} />
        <TypedThrAttendanceShow
          currentStoreData={currentThrStoreData}
          currentSchoolEnabledTHR={currentSchoolEnabledTHR}
          date={getDate}
        />
        <TypedThrMealsShow
          currentStoreData={currentThrStoreData}
          currentSchoolEnabledTHR={currentSchoolEnabledTHR}
        />

        {params.details === "attendance" && (
          <TypedAttendanceEdit currentStoreData={currentStoreData} />
        )}
        {params.details === "attendance-prepopulate-enrolment" && (
          <TypedAttendanceEdit
            currentStoreData={currentStoreData}
            enrolmentData={enrolmentData}
          />
        )}
        {params.details === "attendance-previous-attendance" && (
          <TypedAttendanceEdit
            currentStoreData={currentStoreData}
            previousAttendanceStoreWithAttendanceData={
              previousAttendanceStoreWithAttendanceData
            }
          />
        )}
        {currentSchoolEnabledStudentAttendance &&
          params.details === "student-attendance" && (
            <TypedStudentAttendanceEdit
              currentStoreData={currentStudentStoreData}
              currentSchoolProfile={currentSchoolProfile}
            />
          )}
        {currentSchoolEnabledStudentAttendance &&
          params.details === "student-attendance-prepopulate-enrolment" && (
            <TypedStudentAttendanceEdit
              currentStoreData={currentStudentStoreData}
              enrolmentData={enrolmentData}
            />
          )}
        {currentSchoolEnabledStudentAttendance &&
          params.details === "student-attendance-previous-attendance" && (
            <TypedStudentAttendanceEdit
              currentStoreData={currentStudentStoreData}
              previousStudentAttendanceStoreWithAttendanceData={
                previousStudentAttendanceStoreWithAttendanceData
              }
            />
          )}
        {params.details === "meals" && (
          <MealsEdit
            currentStoreData={currentStoreData}
            currentStudentStoreData={currentStudentStoreData}
          />
        )}
        {params.details === "meals-prepopulate-per-student" && (
          <MealsEdit
            currentStoreData={currentStoreData}
            currentStudentStoreData={currentStudentStoreData}
            previousAttendanceStoreWithConsumptionData={
              previousAttendanceStoreWithConsumptionData
            }
            prepopulatePerStudent={true}
          />
        )}
        {params.details === "meals-prepopulate-total-quantity" && (
          <MealsEdit
            currentStoreData={currentStoreData}
            currentStudentStoreData={currentStudentStoreData}
            previousAttendanceStoreWithConsumptionData={
              previousAttendanceStoreWithConsumptionData
            }
            prepopulateTotalQuantity={true}
          />
        )}
        {currentSchoolEnabledTHR &&
          params.details === "take-home-ration-data" && (
            <TypedTakeHomeRations
              currentStoreData={currentThrStoreData}
              currentSchoolProfile={currentSchoolProfile}
            />
          )}
      </>
    );
    right =
      (currentStoreData as any)?.levels &&
      (currentStoreData as any)?.consumption &&
      ((currentYear !== null && currentYear === selectedAttendanceSchoolYear) ||
        (currentYear === null &&
          previousSchoolYears === selectedAttendanceSchoolYear)) &&
      currentStoreData?.type !== "delete" ? (
        <DeleteModalButton
          currentStoreData={currentStoreData!}
          title={deleteEntireDayDataLabel}
          customRedirectPath={buildAttendanceUrl(
            currentDate,
            params.schoolId,
            params.item,
            params.thrItem,
            params.studentItem
          )}
        />
      ) : undefined;
  }

  const newData = {
    occurred_on: newDate
      ? newDate.format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD"),
  };

  //const formattedDate = moment(
  //  currentStudentStoreData && "occurred_on" in currentStudentStoreData
  //    ? currentStudentStoreData.occurred_on
  //    : newDate
  //).format("YYYY-MM-DD");

  return (
    <MainContent className="attendance-content" title={title} right={right}>
      {!isReportOpen &&
        ((currentStoreData as any)?.levels ||
          (currentStoreData as any)?.consumption ||
          (currentThrStoreData as any)?.levels ||
          (currentThrStoreData as any)?.consumption) && (
          <Blockquote kind="warning" className="date-not-valid">
            <FormattedMessage
              id="sideBar.deleteButtonDisabled"
              defaultMessage="The report for this month isn't open, therefore you can't delete your entries. Please open the report."
            />
          </Blockquote>
        )}
      {content}

      {params.details === "new-attendance" && (
        <TypedAttendanceEdit currentStoreData={newData} />
      )}
      {params.details === "new-attendance-prepopulate-enrolment" && (
        <TypedAttendanceEdit
          currentStoreData={newData}
          enrolmentData={enrolmentData}
        />
      )}
      {params.details === "new-attendance-previous-attendance" && (
        <TypedAttendanceEdit
          currentStoreData={newData}
          previousAttendanceStoreWithAttendanceData={
            previousAttendanceStoreWithAttendanceData
          }
        />
      )}
      {currentSchoolEnabledTHR &&
        params.details === "new-take-home-ration-data" && (
          <TypedTakeHomeRations
            currentStoreData={newData}
            currentSchoolProfile={currentSchoolProfile}
          />
        )}

      {currentSchoolEnabledStudentAttendance &&
        params.details === "new-student-attendance" && (
          <TypedStudentAttendanceEdit currentStoreData={newData} />
        )}
      {params.details === "new-student-attendance-prepopulate-enrolment" && (
        <TypedStudentAttendanceEdit
          currentStoreData={newData}
          enrolmentData={enrolmentData}
        />
      )}
      {params.details === "new-student-attendance-previous-attendance" && (
        <TypedStudentAttendanceEdit
          currentStoreData={newData}
          previousStudentAttendanceStoreWithAttendanceData={
            previousStudentAttendanceStoreWithAttendanceData
          }
        />
      )}
      {params.details === "new-meals" && (
        <MealsEdit
          currentStoreData={newData}
          currentStudentStoreData={currentStudentStoreData}
        />
      )}
      {params.details === "new-meals-prepopulate-per-student" && (
        <MealsEdit
          currentStoreData={newData}
          currentStudentStoreData={currentStudentStoreData}
          previousAttendanceStoreWithConsumptionData={
            previousAttendanceStoreWithConsumptionData
          }
          prepopulatePerStudent={true}
        />
      )}
      {params.details === "new-meals-prepopulate-total-quantity" && (
        <MealsEdit
          currentStoreData={newData}
          currentStudentStoreData={currentStudentStoreData}
          previousAttendanceStoreWithConsumptionData={
            previousAttendanceStoreWithConsumptionData
          }
          prepopulateTotalQuantity={true}
        />
      )}
      {params.details === "new-attendance-prepopulate-student-attendance" && (
        <TypedAttendanceEdit
          currentStoreData={newData}
          studentAttendanceData={currentStudentStoreData}
        />
      )}
    </MainContent>
  );
};

export default AttendanceContent;
