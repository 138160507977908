import React from "react";
import { Input } from "@wfp/ui";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export default function DateInput(props) {
  const { min = null, max = null, time } = props;
  return (
    <Input
      type="date"
      {...props}
      value={props.value}
      formItemClassName={styles.dateInput}
    >
      {(props) => {
        return (
          <div className={styles.dateInputWrapper}>
            <div className={styles.icon}>
              <FontAwesomeIcon icon={faCalendarAlt} />
            </div>
            <input
              {...props}
              ref={props.inputRef}
              type="date"
              formItemClassName={styles.dateInput}
              max={
                max !== undefined
                  ? max
                  : time
                  ? undefined
                  : moment().format("YYYY-MM-DD")
              }
              min={
                min !== undefined
                  ? min
                  : time
                  ? undefined
                  : moment().format("YYYY-MM-DD")
              }
              placeholder=""
            />
          </div>
        );
      }}
    </Input>
  );
}
