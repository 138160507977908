import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  TextInput,
  InlineLoading,
  RadioButton,
  RadioButtonGroup,
  SelectItem,
  Select as LanguageSelect,
} from "@wfp/ui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import { getLoginState } from "data-handler/ducks/auth";
import { getIsInternal } from "helpers/users";

import Select from "react-select";

import PageTitle from "components/PageTitle";

import styles from "components/Registration/registration.module.scss";
import "../_content.scss";

import { languagesOptions } from "SCConstants";

const ViewerRegistrationForm = (props) => {
  const intl = useIntl();

  const { fetching } = useSelector((state) => getLoginState(state));

  const [submitErrorMessage, setSubmitErrorMessage] = useState("");

  const {
    userData,
    userRole,
    onBackToRoleSelection,
    countries,
    onSubmit,
    selectCountryOption,
    setSelectCountryOption,
  } = props;
  const isInternal = getIsInternal(userData.email);

  const methods = useForm();
  const { handleSubmit, errors, register, watch } = methods;

  // Display the required fields message if there are errors
  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      setSubmitErrorMessage(
        intl.formatMessage({
          id: "Registration.allFieldsMustBeFilledMessage",
          defaultMessage:
            "Please fill all the fields marked with red before proceeding",
        })
      );
    } else {
      setSubmitErrorMessage("");
    }
  }, [errors, intl]);

  // Used to populate the country.
  // eslint-disable-next-line no-unused-vars
  const selectedCountry = watch("country");

  const invalidStyle = { borderColor: "#c5192d" };

  const reactSelectInvalidStyle = {
    control: (styles) => ({ ...styles, borderColor: "#C5192D" }),
  };

  const reactSelectStandardStyle = {
    control: (styles) => ({ ...styles, border: "1px solid #8ca4b5" }),
  };

  const allCountriesRadioButtons = [
    {
      value: "allCountries",
      label: (
        <FormattedMessage
          id="viewer.allCountries"
          defaultMessage="All Countries"
        />
      ),
      default: selectCountryOption === "allCountries",
    },
    {
      value: "specificCountries",
      label: (
        <FormattedMessage
          id="viewer.specificCountries"
          defaultMessage="Specific Countries"
        />
      ),
      default: !isInternal || selectCountryOption !== "allCountries",
    },
  ];

  return (
    <div className="role-registration-form">
      <FormProvider {...methods}>
        <form>
          <Button
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
            iconReverse
            kind="secondary"
            className={styles.backLink}
            onClick={() => onBackToRoleSelection()}
          >
            <FormattedMessage
              id="Registration.backToAccessTypeSelectionButton"
              defaultMessage="Back to access type selection"
            />
          </Button>
          <PageTitle>
            {userRole === "viewer" ? (
              <FormattedMessage
                id="User.RegisterToSchoolConnectAsViewer"
                defaultMessage="Register to School Connect as a Viewer"
              />
            ) : (
              <FormattedMessage
                id="User.RegisterToSchoolConnectAsSchoolGroupAdmin"
                defaultMessage="Register to School Connect as a School Group Admin"
              />
            )}
          </PageTitle>
          <div className={styles.nameWrapper}>
            <div style={{ marginBottom: "24px" }}>
              <span className="wfp--label">
                <FormattedMessage
                  id="User.RegistrationName"
                  defaultMessage="Name"
                />
              </span>
              <br />
              {`${userData.other_names} ${userData.last_name}`}
            </div>
          </div>
          <div className={styles.contactWrapper}>
            <TextInput
              type="tel"
              style={errors.email ? invalidStyle : {}}
              autocorrect="off"
              autoCapitalize="off"
              defaultValue={userData.email}
              disabled
              labelText={
                <FormattedMessage
                  id="user.emailFieldLabel"
                  defaultMessage="Email"
                />
              }
              helperText={intl.formatMessage({
                id: "User.RegistrationRequiredEmailHelper",
                defaultMessage: "Your email is required",
              })}
              inputRef={register({ required: true })}
              name="email"
              id="email"
            />
          </div>
          <div className={styles.locationWrapper}>
            <LanguageSelect
              style={errors.language ? invalidStyle : {}}
              inputRef={register({ required: true })}
              className="custom_language"
              name="custom_language"
              id="custom_language"
              labelText={intl.formatMessage({
                id: "User.RegistrationSelectLanguage",
                defaultMessage: "Select language",
              })}
              helperText={intl.formatMessage({
                id: "User.RegistrationSelectLanguageHelper",
                defaultMessage: "Select your language",
              })}
              defaultValue={userData.custom_language}
            >
              <SelectItem
                value=""
                text={intl.formatMessage({
                  id: "User.RegistrationSelectLanguage",
                  defaultMessage: "Select language",
                })}
              />
              {languagesOptions.map((custom_language) => (
                <SelectItem
                  key={custom_language.value}
                  value={custom_language.value}
                  text={intl.formatMessage({
                    id: `User.RegistrationSelectLanguage.${custom_language.label}`,
                    defaultMessage: `${custom_language.label}`,
                  })}
                />
              ))}
            </LanguageSelect>
          </div>
          {isInternal && (
            <div className={styles.allCountiesWrapper}>
              <div className="wfp--input-wrapper">
                <RadioButtonGroup
                  labelText={"Do you want access to all countries"}
                  name="AllCountriesSelection"
                >
                  {allCountriesRadioButtons.map((object) => (
                    <div key={object.value} className="radio-role-selection">
                      <RadioButton
                        key={object.value}
                        labelText={object.label}
                        id={`selection-${object.value}`}
                        name={`${object.label}`}
                        value={object.value}
                        defaultChecked={object.default}
                        onChange={(e) => setSelectCountryOption(e.target.value)}
                        ref={register({ required: true })}
                      />
                    </div>
                  ))}
                </RadioButtonGroup>
              </div>
            </div>
          )}
          {(selectCountryOption !== "allCountries" || !isInternal) && (
            <div style={{ marginBottom: "1rem" }}>
              <div className="country-label-column">
                <label className="wfp--label">
                  {intl.formatMessage({
                    id: "User.RegistrationSelectCountry",
                    defaultMessage: "Select country",
                  })}
                </label>
                <span className="wfp--form__helper-text">
                  {intl.formatMessage({
                    id: "User.RegistrationSelectCountryHelper",
                    defaultMessage: "Select your country",
                  })}
                </span>
              </div>
              <Controller
                render={({ onChange }) => (
                  <Select
                    styles={
                      errors.country
                        ? reactSelectInvalidStyle
                        : reactSelectStandardStyle
                    }
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isMulti={isInternal}
                    options={countries.map((country) => ({
                      value: country.id,
                      label: country.name,
                    }))}
                    onChange={onChange}
                    defaultValue={
                      userData.country
                        ? userData.country
                        : userData.countries
                        ? userData.countries
                        : []
                    }
                  />
                )}
                name="country"
                options={countries.map((country) => ({
                  value: country.id,
                  label: country.name,
                }))}
                rules={{ required: true }}
              />
            </div>
          )}
          {submitErrorMessage && (
            <p className={styles.submitErrorMessage}>{submitErrorMessage}</p>
          )}
          <div className={styles.submitWrapper}>
            <div className={styles.button}>
              <Button type="submit" onClick={handleSubmit(onSubmit)}>
                <FormattedMessage
                  id="User.RegistrationSubmit"
                  defaultMessage="Submit registration"
                />
              </Button>
              {fetching && <InlineLoading />}
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default ViewerRegistrationForm;
