import React from "react";
import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Blockquote } from "@wfp/ui";

import { getCurrentSchool } from "data-handler/ducks/schools";
import { getLocalUsersList } from "data-handler/ducks/localAuth";
import { getCurrentUser, getAuthErrorResponse } from "data-handler/ducks/auth";

import {
  Repeater,
  RepeaterItem,
  RepeaterEntryTitle,
  RepeaterEntrySubTitle,
} from "components/Repeater";

import PageTitle from "components/PageTitle";
import RepeaterAddButton from "components/RepeaterAddButton";

import loginBackground from "images/login-background.jpg";

import LoginWrapper from "./LoginWrapper";
import styles from "./selectLogin.module.scss";

const SelectLogin = () => {
  const localUsersList = useSelector(getLocalUsersList);
  const currentSchool = useSelector(getCurrentSchool);
  const currentUser = useSelector(getCurrentUser);
  const authErrorResponse = useSelector(getAuthErrorResponse);
  const dispatch = useDispatch();
  const history = useHistory();

  if (currentUser) {
    history.push("/");
  }

  const loginRedirect = (data) => {
    data.school = currentSchool;
    dispatch({ type: "LOGIN_LOCAL", data });
    history.push("/");
  };

  return (
    <LoginWrapper
      rightSide={
        <img
          alt="School connect users looking at tablet devices"
          className={styles.loginContentImage}
          src={loginBackground}
        />
      }
    >
      <div>
        {currentSchool && (
          <PageTitle
            subTitle={
              <FormattedMessage
                id="Login.currentSchool"
                defaultMessage="Current school"
              />
            }
          >
            {currentSchool && currentSchool.name}
          </PageTitle>
        )}
        {!currentSchool && (
          <PageTitle>
            <FormattedMessage
              id="Login.welcomeToSchoolConnect"
              defaultMessage="Welcome to School Connect"
            />
          </PageTitle>
        )}

        {authErrorResponse &&
        authErrorResponse.data &&
        authErrorResponse.data.is_active === false ? (
          <Blockquote
            kind="warning"
            title={
              <FormattedMessage
                id="Login.registrationIsPendingApproval"
                defaultMessage="Dear User, your registration is pending approval."
              />
            }
          >
            <FormattedMessage
              id="Login.waitForApproval"
              defaultMessage="You will be able to log in once your request has been approved. For more information, please contact your WFP focal point."
            />
          </Blockquote>
        ) : !currentSchool && Object.keys(localUsersList).length === 0 ? (
          <FormattedMessage
            id="Login.pleaseLoginUsingTheLinkBelow"
            defaultMessage="Please login using the button below."
          />
        ) : null}
        <Repeater>
          {localUsersList.map((e) => {
            return (
              <Link
                to={e.passcode ? `../localLogin/${e.id}` : undefined}
                onClick={() => !e.passcode && loginRedirect(e)}
                className={styles.userListLink}
              >
                <RepeaterItem
                  className={styles.userListItem}
                  horizontalLayout={false}
                >
                  <RepeaterEntryTitle className={styles.userListItemTitle}>
                    {e.other_names} {e.last_name}
                  </RepeaterEntryTitle>
                  <RepeaterEntrySubTitle>{e.email}</RepeaterEntrySubTitle>
                </RepeaterItem>
              </Link>
            );
          })}
        </Repeater>
        {authErrorResponse &&
        authErrorResponse.data &&
        authErrorResponse.data.is_active === false ? (
          <RepeaterAddButton
            text={
              <FormattedMessage
                id="Login.useAnotherAccount"
                defaultMessage="Use another account"
              />
            }
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_CIAM_LOGOUT_URL}?id_token_hint=${authErrorResponse.data.token}&post_logout_redirect_uri=${process.env.REACT_APP_CIAM_REDIRECT_URL}`,
                "_self"
              )
            }
          />
        ) : (
          <Link to="/corporateLogin">
            <RepeaterAddButton
              text={
                <FormattedMessage
                  id="Login.useOnlineLogin"
                  defaultMessage="Use online login"
                />
              }
            />
          </Link>
        )}
      </div>
    </LoginWrapper>
  );
};

export default SelectLogin;
