import endpointDuckFactory from "./_endpointDuckFactory";
import moment, { Moment, MomentInput } from "moment";
import { RootState } from "data-handler/rootReducer";
import {
  Report,
  MeasureUnit,
  ReportCommodity,
} from "data-handler/ducks/stores";

const reportsDuck = endpointDuckFactory({ endpoint: "reports" });
export default reportsDuck;

/**
 * True if report is not undefined and its is_editable flag is false. False otherwise.
 */
export const getIsReportLocked = (report: Report | undefined) =>
  report ? !report.is_editable : false;

export const getReportByMoment = (
  state: RootState,
  date: Moment | MomentInput
): Report | undefined => {
  const reports = reportsDuck.getList(state) as Report[];
  const month = moment(date).month() + 1;
  const year = moment(date).year();
  const day = moment(date).date();
  return reports.find(
    (report) =>
      report.month === month &&
      report.year === year &&
      report.start_day <= day &&
      report.end_day >= day
  );
};

export const getReportByYearMonthDay = (
  state: RootState,
  year: number,
  month: number,
  day: number
) => {
  const reports = reportsDuck.getList(state) as Report[];
  return reports.find(
    (report) =>
      report.month === month &&
      report.year === year &&
      report.start_day <= day &&
      report.end_day >= day
  );
};

export const kilogramMeasureUnit: MeasureUnit = {
  id: -1,
  name: "Kilogram",
  symbol: "Kg",
  decimals: 3,
};

export const emptyMeasureUnit: MeasureUnit = {
  id: -1,
  name: "",
  symbol: "",
  decimals: 3,
};

export const missingCommodity: ReportCommodity = {
  id: -1,
  name: "Unidentified",
  measure_unit: emptyMeasureUnit,
};
