import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { Button } from "@wfp/ui";

import Empty from "components/Empty";
import MainContent from "components/MainContent";
import DeliveriesEdit from "components/DeliveriesEdit";
import IncidentEdit from "components/IncidentEdit";
import DeliveriesShow from "components/DeliveriesShow";
import IncidentShow from "components/IncidentShow";

import deliveryIcon from "images/Moving_van.svg";

import { getCurrentSchoolProfile } from "data-handler/ducks/schools";
import { getStoreByClientId } from "data-handler/ducks/stores";
import Gate from "../Gate";
import { UserPermissionRoles } from "../../SCConstants";
import DeliveriesModal from "components/Deliveries/DeliveriesModal";
import PurchasesShow from "components/CashToSchoolShow";
import CashToSchoolEdit from "components/CashToSchool";

const DeliveriesContent = () => {
  const params = useParams();
  const currentStoreData = useSelector(getStoreByClientId(params.item));
  const currentSchool = useSelector(getCurrentSchoolProfile);
  const currentSchoolEnabledPurchases = currentSchool.enable_cash_purchases;
  const [deliveriesModalState, setDeliveriesModalState] = useState(false);

  return (
    <>
      {currentStoreData ? (
        <>
          {currentStoreData?.model === "delivery" && (
            <DeliveriesShow currentStoreData={currentStoreData} />
          )}
          {currentStoreData.model === "incident" && (
            <IncidentShow currentStoreData={currentStoreData} />
          )}
          {currentStoreData?.model === "purchasedetail" && (
            <PurchasesShow purchaseCurrentStoreData={currentStoreData} />
          )}
        </>
      ) : (
        <MainContent>
          <Empty
            button={
              <Gate forbidRoles={[UserPermissionRoles.is_viewer]}>
                {currentSchoolEnabledPurchases && (
                  <NavLink
                    to={`/school/${params.schoolId}/deliveries/${
                      currentStoreData ? currentStoreData.client_id : undefined
                    }/new-purchase`}
                  >
                    <Button kind="primary">
                      <FormattedMessage
                        id="Deliveries.newPurchase"
                        defaultMessage="New purchase"
                      />
                    </Button>
                  </NavLink>
                )}

                <Button
                  kind="accent"
                  onClick={() => setDeliveriesModalState(true)}
                >
                  <FormattedMessage
                    id="DeliveriesSidebar.newDelivery"
                    defaultMessage="New delivery"
                  />
                </Button>

                <NavLink
                  to={`/school/${params.schoolId}/deliveries/${
                    currentStoreData ? currentStoreData.client_id : undefined
                  }/new-incident`}
                >
                  <Button kind="secondary">
                    {" "}
                    <FormattedMessage
                      id="Deliveries.newIncident"
                      defaultMessage="New incident"
                    />
                  </Button>
                </NavLink>
              </Gate>
            }
            title={
              <FormattedMessage
                id="Deliveries.selectAnEntry"
                defaultMessage="Select an entry"
              />
            }
            kind="large"
            icon={
              <img
                alt="deliveries illustration unloading a truck"
                src={deliveryIcon}
                style={{ marginLeft: "-2rem", marginBottom: "1rem" }}
              />
            }
          >
            <FormattedMessage
              id="Deliveries.orCreateANewDeliveryOrIncident"
              defaultMessage="or create a new delivery or incident"
            />
          </Empty>
        </MainContent>
      )}
      {params.details === "purchase-detail" && (
        <CashToSchoolEdit purchaseCurrentStoreData={currentStoreData} />
      )}
      {params.details === "repeat-purchase" && (
        <CashToSchoolEdit
          purchaseCurrentStoreData={currentStoreData}
          purchased_at={true}
        />
      )}
      {params.details === "delivery" && (
        <DeliveriesEdit deliveryCurrentStoreData={currentStoreData} />
      )}
      {params.details === "incident" && (
        <IncidentEdit currentStoreData={currentStoreData} />
      )}
      {params.details === "new-delivery" && <DeliveriesEdit />}
      {params.details === "new-incident" && <IncidentEdit />}
      {params.details === "new-purchase" && currentSchoolEnabledPurchases && (
        <CashToSchoolEdit />
      )}
      <DeliveriesModal
        state={deliveriesModalState}
        setState={setDeliveriesModalState}
      />
    </>
  );
};

export default DeliveriesContent;
