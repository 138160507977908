import { Attendance, Store } from "data-handler/ducks/stores";
import {
  deliveryCategory,
  studentAttendanceCategory,
  takeHomeRationCategory,
} from "SCConstants";

export const getModelValue = (model: string) => {
  let value = 0;
  switch (model) {
    case "year":
      value = 8;
      break;
    case "enrolment":
      value = 7;
      break;
    case "purchasedetail":
      value = 6;
      break;
    case "delivery":
      value = 5;
      break;
    case "incident":
      value = 4;
      break;
    case "attendance":
      value = 3;
      break;
    case "offlineuser":
      value = 2;
      break;
    default:
      value = 1;
      break;
  }
  return value;
};

export const sortModels = (stores: Store[]) => {
  return stores.sort((a, b) => getModelValue(b.model) - getModelValue(a.model));
};

export const isMealAttendance = (store: Store): store is Attendance =>
  store.model === "attendance" && store.category === deliveryCategory;

export const isStudentAttendance = (store: Store): store is Attendance =>
  store.model === "attendance" && store.category === studentAttendanceCategory;

export const isTakeHomeRationAttendance = (store: Store): store is Attendance =>
  store.model === "attendance" && store.category === takeHomeRationCategory;
