import React from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { Icon } from "@wfp/ui";
import { iconCaretDown, iconCaretUp } from "@wfp/icons";

import ItemSwitcher from "components/ItemSwitcher";
import ReportItem from "components/ItemSwitcher/ReportItem";

import { getSortedMonths } from "helpers/dates";

import "./_dropdown.scss";
import {
  isAttendanceStoreCompleted,
  sidebarStyling,
} from "data-handler/ducks/stores";

const SchoolYearGroupDropdown = (props) => {
  const {
    schoolYears,
    setCollapsedYearOrMonth,
    currentSchoolYear,
    deliveriesAndIncidentsAndPurchasesByMonth,
    attendancesAndPlaceholders,
    reportsAndPreviewsBySchoolYear,
    type,
    byMonths = false,
    currentSchoolEnabledTHR = false,
    currentSchoolEnabledStudentAttendance = false,
  } = props;

  const dateFormatter = (date) => moment(date).format("MMMM\xa0YYYY");

  const sidebarItemsByMonth = (schoolYear, month) => {
    switch (type) {
      case "attendanceAndMeals":
        return attendancesAndPlaceholders(schoolYear, month).map((store) => {
          return (
            <ItemSwitcher
              store={store.attendance}
              studentStore={store.studentAttendance}
              thrStore={store.thrAttendance}
              key={store.client_id}
              currentSchoolEnabledTHR={currentSchoolEnabledTHR}
              currentSchoolEnabledStudentAttendance={
                currentSchoolEnabledStudentAttendance
              }
            />
          );
        });
      case "deliveriesAndIncidentsAndPurchases":
        return deliveriesAndIncidentsAndPurchasesByMonth(
          schoolYear.months[month]
        ).map((store) => {
          return <ItemSwitcher store={store} key={store.client_id} />;
        });
      default:
        return null;
    }
  };

  const sidebarItemsByYear = (schoolYear) => {
    switch (type) {
      case "reports":
        return reportsAndPreviewsBySchoolYear(schoolYear).map((report) => (
          <ReportItem report={report} key={report.client_id} />
        ));
      default:
        return null;
    }
  };

  const hasOpenedOrRejectedReports = (schoolYear) => {
    const openedOrRejectedReport = reportsAndPreviewsBySchoolYear(
      schoolYear
    ).find((report) => report.state === "open" || report.state === "rejected");
    if (openedOrRejectedReport) {
      return true;
    }
    return false;
  };

  const checkSchoolYears = (schoolYear, currentSchoolYear) => {
    if (!schoolYear.id && !currentSchoolYear?.id) {
      // We use the client id if the school years have not been synced
      return schoolYear.client_id === currentSchoolYear?.client_id;
    }
    return schoolYear.id === currentSchoolYear?.id;
  };

  function isMonthAttendanceStoreCompleted(schoolYear, month) {
    let completed = [];
    attendancesAndPlaceholders(schoolYear, month).forEach((store) => {
      completed.push(
        isAttendanceStoreCompleted(
          store.attendance,
          store.studentAttendance,
          store.currentSchoolEnabledStudentAttendance
        )
      );
    });
    return !completed.includes(false);
  }

  const isMonthCompleted = (schoolYear, month) => {
    let completed = isMonthAttendanceStoreCompleted(schoolYear, month);
    return completed ? sidebarStyling.complete : sidebarStyling.incomplete;
  };

  const isYearCompleted = (schoolYear) => {
    let completed = [];
    getSortedMonths(schoolYear.months).forEach((month) => {
      completed.push(isMonthAttendanceStoreCompleted(schoolYear, month));
    });
    return !completed.includes(false)
      ? sidebarStyling.complete
      : sidebarStyling.incomplete;
  };

  const hasOpenedOrRejectedReportsStyling = (schoolYear) => {
    return !hasOpenedOrRejectedReports(schoolYear)
      ? sidebarStyling.complete
      : sidebarStyling.incomplete;
  };

  return (
    <>
      {schoolYears.map(
        (schoolYear) =>
          schoolYear && (
            <div key={schoolYear.id} className="school-year-group">
              <div
                tabIndex={0}
                role="link"
                className="school-year-dropdown"
                onClick={() => {
                  setCollapsedYearOrMonth(schoolYear);
                }}
                onKeyDown={() => {
                  setCollapsedYearOrMonth(schoolYear);
                }}
                style={
                  type === "attendanceAndMeals" && schoolYear?.collapsed
                    ? isYearCompleted(schoolYear)
                    : type === "reports"
                    ? hasOpenedOrRejectedReportsStyling(schoolYear)
                    : {}
                }
              >
                <label className="wfp--label">
                  {checkSchoolYears(schoolYear, currentSchoolYear) ? (
                    <FormattedMessage
                      id="schools.currentSchoolYear"
                      defaultMessage="Current school year ({start_date} to {end_date})"
                      values={{
                        start_date: dateFormatter(schoolYear.starts_on),
                        end_date: dateFormatter(schoolYear.ends_on),
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      id="schools.schoolYear"
                      defaultMessage="School year ({start_date} to {end_date})"
                      values={{
                        start_date: dateFormatter(schoolYear.starts_on),
                        end_date: dateFormatter(schoolYear.ends_on),
                      }}
                    />
                  )}
                </label>
                <Icon
                  icon={schoolYear.collapsed ? iconCaretDown : iconCaretUp}
                  className="icon-dropdown"
                />
              </div>
              <div className="school-year-subgroup">
                {schoolYear.collapsed
                  ? null
                  : !byMonths
                  ? sidebarItemsByYear(schoolYear)
                  : getSortedMonths(schoolYear.months).map((month) => {
                      const currentSidebarByMonth = sidebarItemsByMonth(
                        schoolYear,
                        month
                      );

                      return currentSidebarByMonth.length > 0 ? (
                        <div
                          key={schoolYear.months[month].starts_on}
                          className="month-group"
                        >
                          <div
                            className="month-dropdown"
                            onClick={() => {
                              setCollapsedYearOrMonth(schoolYear, month);
                            }}
                            role="button"
                            tabIndex={0}
                            onKeyDown={() => {
                              setCollapsedYearOrMonth(schoolYear);
                            }}
                            style={
                              schoolYear.months[month].collapsed &&
                              type === "attendanceAndMeals"
                                ? isMonthCompleted(schoolYear, month)
                                : {}
                            }
                          >
                            <label
                              className="wfp--label"
                              htmlFor="school-month"
                            >
                              <FormattedMessage
                                id="schools.monthName"
                                defaultMessage=" - {month}"
                                values={{
                                  month: dateFormatter(
                                    schoolYear.months[month].starts_on
                                  ),
                                }}
                              />
                            </label>
                            <Icon
                              icon={
                                schoolYear.months[month].collapsed
                                  ? iconCaretDown
                                  : iconCaretUp
                              }
                              className="icon-dropdown"
                            />
                          </div>
                          <div className="month-subgroup">
                            {schoolYear.months[month].collapsed
                              ? null
                              : currentSidebarByMonth}
                          </div>
                        </div>
                      ) : null;
                    })}
              </div>
            </div>
          )
      )}
    </>
  );
};

export default SchoolYearGroupDropdown;
