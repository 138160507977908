import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import queryString from "query-string";
import withSizes from "react-sizes";
import classNames from "classnames";
import { InlineLoading } from "@wfp/ui";
import {
  getCurrentSchool,
  requestCurrentSchool,
  getSchoolsFetching,
  clearCurrentSchoolState,
  getSchoolLoadedFromSelect,
  setSchoolLoadedFromSelect,
  setUserCachedSchool,
  getExtendedSchoolsFetching,
} from "data-handler/ducks/schools";
import {
  getCurrentUser,
  getIsViewer,
  getLoggedInOffline,
  getOnlineRequiredError,
} from "data-handler/ducks/auth";
import {
  GETStores,
  clearStores,
  setUserCachedStores,
} from "data-handler/ducks/stores";
import commoditiesDuck from "data-handler/ducks/commodities";
import incidentCausesDuck from "data-handler/ducks/incidentCauses";
import deliveryIssuesDuck from "data-handler/ducks/deliveryIssues";
import { requestUsersFromSchool } from "data-handler/ducks/users";
import { mapSizesToProps } from "helpers/mapSizesToProps";
import Attendance from "components/Attendance";
import SupportModal from "components/SupportModal";
import Deliveries from "components/Deliveries";
import Info from "components/Info";
import MainNav from "components/MainNav";
import MobileNav from "components/MobileNav";
import Report from "components/Report";
import SchoolSelect from "components/SchoolSelect";
import Stock from "components/Stock";
import SyncSelect from "components/SyncSelect";
import UserModal from "components/UserModal";
import UpdateLocalPassword from "components/Login/UpdateLocalPassword";
import UserHasNoSchool from "./UserHasNoSchool";
import SchoolHasNoProfile from "./SchoolHasNoProfile";
import "./_content.scss";
import OnlineRequiredModal from "../OnlineRequiredModal/OnlineRequiredModal";
import reportsDuck from "../../data-handler/ducks/reports";
import { REPORT_FETCHER } from "../Report";
import { requestLevelGroupMappings } from "data-handler/ducks/ageGroups";
import {
  getRoles,
  requestCountryRoleMapping,
  requestRoles,
} from "data-handler/ducks/roles";
import {
  getVendorCategories,
  getVendorCategoriesIsFetching,
  requestAllApprovedVendors,
  requestVendorCategories,
} from "data-handler/ducks/vendors";
import { getIsNonSchoolUser, getCanUseOfflineMode } from "helpers/users";
import Initializer from "components/Initializer";
/**
 * Automatically fetch all School Connect primitives (commodities etc.)
 *
 * Aggressively retries until they are populated.
 */
const useFetchPrimitives = () => {
  const dispatch = useDispatch();

  // Fetch commodities ASAP
  const commodities = useSelector(commoditiesDuck.getList);
  const commoditiesFetching = useSelector(commoditiesDuck.isFetching());
  useEffect(() => {
    if (commodities.length === 0 && !commoditiesFetching) {
      dispatch(commoditiesDuck.fetchList());
    }
  }, [commodities, commoditiesFetching, dispatch]);
  const vendorCategories = useSelector(getVendorCategories);
  const vendorsCategoriesFetching = useSelector(getVendorCategoriesIsFetching);
  useEffect(() => {
    if (
      Object.keys(vendorCategories.results).length === 0 &&
      !vendorsCategoriesFetching
    ) {
      dispatch(requestVendorCategories());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Fetch incidentCauses ASAP
  const incidentCauses = useSelector(incidentCausesDuck.getList);
  const incidentCausesIsFetching = useSelector(incidentCausesDuck.isFetching());
  useEffect(() => {
    if (incidentCauses.length === 0 && !incidentCausesIsFetching) {
      dispatch(incidentCausesDuck.fetchList());
    }
  }, [incidentCauses, incidentCausesIsFetching, dispatch]);
  // Fetch deliveryIssues ASAP
  const deliveryIssues = useSelector(deliveryIssuesDuck.getList);
  const deliveryIssuesIsFetching = useSelector(deliveryIssuesDuck.isFetching());
  useEffect(() => {
    if (deliveryIssues.length === 0 && !deliveryIssuesIsFetching) {
      dispatch(deliveryIssuesDuck.fetchList());
    }
  }, [deliveryIssues, deliveryIssuesIsFetching, dispatch]);
};
/**
 * Fetch all data pertaining to a school
 */
export const fetchSchoolData = (
  dispatch: ReturnType<typeof useDispatch>,
  schoolId: string | number,
  reloadStores: boolean = true
) => {
  dispatch(clearCurrentSchoolState());
  dispatch(requestCurrentSchool(schoolId));
  dispatch(requestUsersFromSchool(schoolId));
  if (reloadStores) {
    dispatch(clearStores());
    dispatch(GETStores(schoolId));
  }
  dispatch(reportsDuck.fetchList(REPORT_FETCHER, { school: schoolId }));
};

type ContentProps = {
  isMobile: boolean;
};

/**
 * - Renders Nav, according to isMobile
 * - Renders Pages, according to routing
 * - Loads SC primitives, on first access
 *
 * This component must be accessible _only_ to authenticated users.
 */
const Content: React.ComponentType<ContentProps> = ({ isMobile }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const urlParams = queryString.parse(location.search);
  useFetchPrimitives();
  const currentUser = useSelector(getCurrentUser);
  const currentSchool = useSelector(getCurrentSchool);
  const isSchoolsFetching = useSelector(getSchoolsFetching);
  const onlineRequiredError = useSelector(getOnlineRequiredError);
  const schoolError = useSelector((state: any) => state.schools.error);
  const isViewer = useSelector(getIsViewer);
  const isOffline = useSelector(getLoggedInOffline);
  const schoolLoadedFromSelect = useSelector(getSchoolLoadedFromSelect);
  const isExtendedSchoolsFetching = useSelector(getExtendedSchoolsFetching);

  // TODO check if isExtendedSchoolsFetching needed here.
  // I think it was removed and come back later with a merge conflict.
  const isFetching = isSchoolsFetching || isExtendedSchoolsFetching;

  // React to changes in `/school/<schoolId>` URL or in store's currentSchool
  // For admins, this means "change currentSchool to the one in the URL"
  // For everyone else, realign the URL to currentSchool.id
  type SchoolMatch = {
    params: {
      schoolId: string;
    };
  };

  const schoolMatch: SchoolMatch | null = useRouteMatch("/school/:schoolId?");

  const schoolIdFromURL = schoolMatch
    ? parseInt(schoolMatch.params.schoolId)
    : undefined;

  // Post-login fetches for non-admin users (Principal etc.)
  useEffect(() => {
    if (
      currentUser?.school?.id &&
      !currentUser.is_admin &&
      (currentSchool === undefined ||
        currentSchool.id !== currentUser.school.id) &&
      !isSchoolsFetching &&
      !schoolError &&
      !isOffline
    ) {
      fetchSchoolData(dispatch, currentUser.school.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const { roles } = useSelector(getRoles);
  const countryId = currentSchool?.country?.id;

  useEffect(() => {
    if (isOffline) {
      // We need to update the currentSchool and the stores in redux
      // from the cached list of user's schools and stores, but only
      // for appropriate roles
      if (
        getCanUseOfflineMode(currentUser) &&
        currentSchool?.id !== schoolIdFromURL
      ) {
        dispatch(setUserCachedSchool(schoolIdFromURL!));
        dispatch(setUserCachedStores(schoolIdFromURL!));
      }
    }
  }, [isOffline, currentSchool, currentUser, dispatch, schoolIdFromURL]);

  useEffect(() => {
    if (currentSchool && currentSchool.id === schoolIdFromURL) {
      // "If URL has been adjusted to match currentSchool or viceversa". Do nothing.
      return;
    }

    if (getIsNonSchoolUser(currentUser)) {
      // Post-school-selection fetches for admin users:
      // changes `currentSchool` to `schoolIdFromURL` and fetches
      // its data, but only `schoolIdFromURL` is not the
      // same as the one from `schoolLoadedFromSelect`

      // Note: the logic for `schoolLoadedFromSelect` can
      // be found in the SchoolSelect component
      if (
        (schoolIdFromURL && !schoolLoadedFromSelect) ||
        (schoolIdFromURL &&
          schoolLoadedFromSelect &&
          schoolLoadedFromSelect !== schoolIdFromURL)
      ) {
        fetchSchoolData(dispatch, schoolIdFromURL);
        dispatch(setSchoolLoadedFromSelect(undefined));
      } else if (
        schoolIdFromURL &&
        schoolLoadedFromSelect &&
        schoolLoadedFromSelect === schoolIdFromURL
      ) {
        dispatch(setSchoolLoadedFromSelect(undefined));
      }
    } else {
      // User may not edit other schools: change URL to match currentUser.school.id
      if (currentUser.school) history.push(`/school/${currentUser.school.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolIdFromURL]);

  useEffect(() => {
    if (currentSchool && countryId && !isOffline) {
      dispatch(requestLevelGroupMappings(countryId));
      dispatch(requestCountryRoleMapping(countryId));
      dispatch(requestAllApprovedVendors(countryId));
      if (roles.length === 0) {
        dispatch(requestRoles());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSchool, countryId, isOffline]);
  if (
    !currentSchool &&
    (currentUser.is_admin ||
      currentUser.is_approver ||
      currentUser.is_country_admin ||
      currentUser.is_school_group_admin ||
      isViewer)
  ) {
    return <SchoolSelect />;
  }
  if (!currentSchool && isFetching) {
    return <InlineLoading />;
  }

  // Show an actionable explanation to users with broken user data
  if (
    !currentUser.school &&
    !currentUser.is_admin &&
    !currentUser.is_approver &&
    !currentUser.is_country_admin &&
    !currentUser.is_school_group_admin &&
    !isViewer
  ) {
    return <UserHasNoSchool />;
  }

  if (currentUser && !currentSchool?.profile) {
    return <SchoolHasNoProfile />;
  }
  if (currentUser && onlineRequiredError) {
    return <OnlineRequiredModal />;
  }
  return (
    <div
      className={classNames("content", {
        content__active: urlParams.nav,
      })}
    >
      {/* Modals */}
      {urlParams.select === "school" && <SchoolSelect />}
      <Route path="/school/:schoolId/" component={UpdateLocalPassword} />
      <Route path="/school/:schoolId/" component={SyncSelect} />
      <Route path="/school/:schoolId/" component={SupportModal} />
      <Route path="/school/:schoolId/" component={UserModal} />
      {/* Nav */}
      <Switch>
        <Route
          path="/school/:schoolId/:page?/:item?/:details?"
          render={(props) =>
            isMobile ? (
              <MobileNav {...props} />
            ) : (
              //<MainNav match={schoolMatch} {...props} />
              // TODO : check if this is working
              <MainNav {...props} />
            )
          }
        />
      </Switch>
      {/* Pages */}
      <div className="inner-content">
        <Switch>
          <Route
            path="/school/:schoolId/attendance/:item?/:thrItem?/:studentItem?/:details?"
            component={Attendance}
          />
          <Route
            path="/school/:schoolId/deliveries/:item?/:details?"
            component={Deliveries}
          />
          <Route
            path="/school/:schoolId/stock/:item?/:details?"
            component={Stock}
          />
          <Route
            path="/school/:schoolId/info/:item?/:details?"
            component={Info}
          />
          <Route
            path="/school/:schoolId/report/:item?/:details?"
            component={Report}
          />
          {/* Redirect to stock overview page */}
          <Route
            exact
            path="/:school/:schoolId/"
            component={() => (
              <Redirect
                to={`/school/${schoolIdFromURL}/stock/overview?nav=true`}
              />
            )}
          />
          {currentUser &&
            (currentUser.is_admin ||
              isViewer ||
              currentUser.is_approver ||
              currentUser.is_country_admin ||
              currentUser.is_school_group_admin) && (
              <Route component={SchoolSelect} />
            )}
        </Switch>
      </div>
    </div>
  );
};

const InitializedContent: React.ComponentType<ContentProps> = (props) => {
  return (
    <Initializer>
      <Content {...props} />
    </Initializer>
  );
};

export default withSizes(mapSizesToProps)(InitializedContent);
