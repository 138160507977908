import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faWheatAwn } from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Module,
  ModuleBody,
  Story,
  Tag,
  List,
  ListItem,
} from "@wfp/ui";

import DeleteModalButton from "components/DeleteModalButton";
import SubTitle from "components/SubTitle";
import Empty from "components/Empty";
import Section from "components/Section";

import { noMealsOptions } from "SCConstants";
import { getReportedStores } from "helpers/report";
import {
  getCurrentSchoolYear,
  getPreviousSchoolYears,
  getSchoolYearByDate,
} from "data-handler/ducks/stores";
import CommodityShow from "components/CommodityShow";
import Gate from "../Gate";
import { UserPermissionRoles } from "../../SCConstants";
import { getIsSyncableLocked } from "../../data-handler/ducks/stores";
import { buildAttendanceUrl } from "helpers/attendance";
import moment from "moment";

const MealsShow = ({ currentStoreData, date }) => {
  const params = useParams();
  const urlDetails = currentStoreData ? "meals" : "new-meals";

  const editUrl = buildAttendanceUrl(
    currentStoreData?.occurred_on,
    params.schoolId,
    params.item,
    params.thrItem,
    params.studentItem,
    urlDetails
  );

  const reportedStores = useSelector(getReportedStores);
  const isReported = reportedStores[params.item];

  const consumption = currentStoreData && currentStoreData.consumption;
  const isLocked = useSelector((state) =>
    getIsSyncableLocked(state, currentStoreData)
  );
  const currentYear = useSelector((state) => getCurrentSchoolYear(state)); //users current year
  const selectedMealSchoolYear = useSelector(
    getSchoolYearByDate(date ? moment(date) : moment().format("YYYY-MM-DD"))
  );
  const previousSchoolYears = useSelector(getPreviousSchoolYears)[0];
  const defineSection = !currentStoreData?.levels ? undefined : "consumption";

  const deleteConsumptionLabel = (
    <FormattedMessage
      id="delete.deleteConsumption"
      defaultMessage="Delete consumption"
    />
  );
  if (!consumption) {
    return (
      <Empty
        kind="section"
        title={
          <FormattedMessage id="MealsShow.mealsTitle" defaultMessage="Meals" />
        }
        icon={<FontAwesomeIcon icon={faWheatAwn} size="1x" />}
        button={
          <Gate
            forbidRoles={[UserPermissionRoles.is_viewer]}
            isForbidden={
              isLocked ||
              (currentYear !== null &&
                currentYear !== selectedMealSchoolYear) ||
              (currentYear === null &&
                previousSchoolYears !== selectedMealSchoolYear)
            }
          >
            <NavLink to={editUrl}>
              <Button
                kind="primary"
                icon={<FontAwesomeIcon icon={faChevronRight} />}
                disabled={isReported}
              >
                <FormattedMessage
                  id="MealsShow.provideMealData"
                  defaultMessage="Provide meal data"
                />
              </Button>
            </NavLink>
          </Gate>
        }
      ></Empty>
    );
  }

  const newAttendanceId =
    "newDate+" + moment(currentStoreData?.occurred_on).format("YYYY-MM-DD");

  return (
    <Section>
      <SubTitle
        additional={
          <Gate
            forbidRoles={[UserPermissionRoles.is_viewer]}
            isForbidden={
              isLocked ||
              (currentYear !== null &&
                currentYear !== selectedMealSchoolYear) ||
              (currentYear === null &&
                previousSchoolYears !== selectedMealSchoolYear)
            }
          >
            {currentStoreData.type !== "delete" && (
              <DeleteModalButton
                currentStoreData={currentStoreData}
                section={defineSection}
                title={deleteConsumptionLabel}
                customRedirectPath={
                  currentStoreData?.sync === false &&
                  !currentStoreData?.id &&
                  ((!currentStoreData.levels && currentStoreData.consumption) ||
                    (currentStoreData.levels && !currentStoreData.consumption))
                    ? buildAttendanceUrl(
                        currentStoreData?.occurred_on,
                        params.schoolId,
                        newAttendanceId,
                        params.thrItem,
                        params.studentItem
                      )
                    : buildAttendanceUrl(
                        currentStoreData?.occurred_on,
                        params.schoolId,
                        params.item,
                        params.thrItem,
                        params.studentItem
                      )
                }
              />
            )}
            <NavLink className="edit-nav-link" to={editUrl}>
              <Button
                kind="secondary"
                icon={<FontAwesomeIcon icon={faChevronRight} />}
                disabled={isReported}
              >
                <FormattedMessage
                  id="MealsShow.editMealsButton"
                  defaultMessage="Edit meals"
                />
              </Button>
            </NavLink>
          </Gate>
        }
      >
        <FormattedMessage id="MealsShow.mealsSubtitle" defaultMessage="Meals" />
      </SubTitle>
      <div className="on-site-meals-section">
        {consumption.meal_provided === false && (
          <Module noMargin>
            <ModuleBody>
              <Story>
                <h4>
                  <FormattedMessage
                    id="MealsShow.noMealsServedToday"
                    defaultMessage="No meals served today"
                  />
                </h4>
                <List kind="details" colon>
                  <ListItem
                    title={
                      <FormattedMessage
                        id="MealsShow.reasonsTitle"
                        defaultMessage="Reasons"
                      />
                    }
                  >
                    {consumption.no_meal_reasons.map((reasonId) => {
                      const option = noMealsOptions.find(
                        (option) => option.value === reasonId
                      );
                      return option ? (
                        <Tag type="info" key={option.value}>
                          {option.label}
                        </Tag>
                      ) : null;
                    })}
                  </ListItem>
                  {consumption.other_no_meal_reason && (
                    <ListItem
                      title={
                        <FormattedMessage
                          id="MealsShow.otherReason"
                          defaultMessage="Other reason"
                        />
                      }
                    >
                      {consumption.other_no_meal_reason}
                    </ListItem>
                  )}
                </List>
              </Story>
            </ModuleBody>
          </Module>
        )}
        {consumption.meal_provided === true && (
          <CommodityShow
            model={currentStoreData.model}
            commodities={consumption.commodities}
          />
        )}
      </div>
    </Section>
  );
};

export default MealsShow;
