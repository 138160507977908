import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Controller,
  FormProvider,
  useForm,
  useFieldArray,
} from "react-hook-form";

import moment from "moment";
import { isEmpty } from "lodash";

import {
  faCheck,
  faTimes,
  faArrowRight,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Button,
  Checkbox,
  TextInput,
  RadioButtonGroup,
  RadioButton,
  Select,
  SelectItem,
  Blockquote,
  Loading,
} from "@wfp/ui";

import useStore from "helpers/useStore";
import {
  buildAttendanceUrl,
  getFilteredAttendanceTotal,
  getPreviousAttendanceStoreWithAttendanceDataByDate,
} from "helpers/attendance";
import { stockValueByDateByCommodityByCategory } from "helpers/stock";

import { hasPurchaseStore, hasTHRStore } from "data-handler/ducks/stores";
import {
  requestCommoditySourcesForSchool,
  setCommoditySourcesFromSchoolCache,
  getCommoditySources,
  getCommoditySourcesFetching,
  requestAllCommoditySources,
  getAllCommoditySources,
  getAllCommoditySourcesFetching,
  getCurrentSchoolProfile,
  getCurrentSchoolProfileWfpCommodities,
  getSchoolsFetching,
} from "data-handler/ducks/schools";
import commoditiesDuck from "data-handler/ducks/commodities";
import {
  getAllStores,
  updateStore,
  getDeliveryAttendancesByDateRange,
  getCurrentOrLatestSchoolYear,
} from "data-handler/ducks/stores";

import ModalExtended from "components/ModalExtended";
import {
  stockInStoreByCategory,
  currentSchoolYearDateValidation,
} from "components/IncidentEdit";
import { RepeaterItem } from "components/Repeater";
import CommoditySelect from "components/CommoditySelect";
import QuantityInput from "components/QuantityInput";
import RepeaterAddButton from "components/RepeaterAddButton";
import ErrorList from "components/ErrorList";
import DateInput from "components/DateInput";
import { getSchoolDays } from "components/Report/ReportModalClose";
import ButtonRouter from "components/ButtonRouter";

import {
  noMealsOptions,
  commoditySourceOptions,
  purchaseDetailCategory,
  deliveryCategory,
  takeHomeRationCategory,
  nonWfpCategory,
  studentAttendanceCategory,
} from "SCConstants";

import "./_meals-edit.scss";
import {
  CommodityIdToSourceMapping,
  CommoditySource,
} from "data-handler/ducks/schools2";
import { getLoggedInOffline } from "data-handler/ducks/auth";

export const ERROR_MESSAGES = {
  missingCategory: (
    <FormattedMessage
      id="MealsEdit.missingCategory"
      defaultMessage="Select a category"
    />
  ),
  insufficientStock: (
    <FormattedMessage
      id="MealsEdit.insufficientStock"
      defaultMessage="The meal quantity exceeds the stock for this commodity, please reduce."
    />
  ),
  missingCommodity: (
    <FormattedMessage
      id="MealsEdit.missingCommodity"
      defaultMessage="Select a commodity"
    />
  ),
  missingQuantity: (
    <FormattedMessage
      id="MealsEdit.missingQuantity"
      defaultMessage="Enter a quantity"
    />
  ),
  zeroQuantity: (
    <FormattedMessage
      id="MealsEdit.zeroQuantity"
      defaultMessage="Please add a quantity to all commodities"
    />
  ),
};

export const requiredLabelCommodities = (
  <div
    className="wfp--form-required"
    style={{ borderColor: " 2px solid #C5192D" }}
  >
    {ERROR_MESSAGES["missingCommodity"]}
  </div>
);

const missingCategoryRequiredMessage = (
  <FormattedMessage
    id="MealsEdit.missingCategoryStar"
    defaultMessage="* Select a category"
  />
);

type Commodity = {
  id: number;
  category: string;
  commodity: number;
  quantity: string;
  delivery_commodities?: {};
  purchase_detail_commodities?: {};
};
type FormValues = {
  no_meal_reasons: boolean[];
  commodities:
    | Commodity[]
    | {
        delivery_commodities: Commodity[];
        purchase_detail_commodities: Commodity[];
      };
};

type Store = {
  no_meal_reasons: number[];
  consumption: {};
  isPreviousStore: boolean;
  levels: { male: string; female: string }[];
  commodities: {}[];
};
const storeToFormValues = (
  store: Store,
  prepopulatePerStudent: boolean,
  totalStudents: number
): FormValues => {
  let values = store.consumption
    ? JSON.parse(JSON.stringify(store.consumption)) // deep copy
    : {};

  // Calculate quantity per student if prepopulatePerStudent and if
  // there is attendance in the previous store
  if (
    prepopulatePerStudent &&
    store.isPreviousStore &&
    store.levels &&
    values.commodities &&
    values.commodities.length > 0 &&
    totalStudents > 0
  ) {
    let attendance = 0;
    store.levels.forEach((level: { male: string; female: string }) => {
      attendance += parseFloat(level.female);
      attendance += parseFloat(level.male);
    });

    values.commodities.forEach((commodity: any) => {
      if (attendance > 0) {
        const quantityPerStudent = parseFloat(commodity.quantity) / attendance;
        commodity.quantityPerStudent = quantityPerStudent.toFixed(3);
        commodity.quantity = (quantityPerStudent * totalStudents).toFixed(3);
      }
    });
  }

  // Reshape int arrays (foreign keys) to bool arrays (checkboxes)
  const no_meal_reasons: boolean[] = [];
  (values.no_meal_reasons || []).forEach((commodityId: number) => {
    no_meal_reasons[commodityId] = true;
  });
  return { ...values, no_meal_reasons };
};

const formValuesToStore = (values: FormValues) => {
  // Convert checkboxes to arrays of ints (foreign keys)
  const no_meal_reason_commodities: number[] = [];
  (values.no_meal_reasons || []).forEach((reason, i) => {
    if (reason) {
      no_meal_reason_commodities.push(i);
    }
  });
  if (values.commodities) {
    if (
      !("delivery_commodities" in values.commodities) &&
      !("purchase_detail_commodities" in values.commodities)
    ) {
      values.commodities.forEach((commodity) => {
        commodity.delivery_commodities = {};
        commodity.purchase_detail_commodities = {};
      });
    }
  }

  return { ...values, no_meal_reasons: no_meal_reason_commodities };
};

/** Handles validation for ALL form values */
export const validationResolver = (
  values: any,
  {
    allStores,
    currentStoreData,
    newDate,
    currentSchoolEnabledPurchases,
    currentSchoolEnabledTHR,
  }: any
) => {
  const errors: {
    [key: string]: { message: JSX.Element; requiredMessage?: JSX.Element };
  } = {};
  if (values.commodities) {
    values.commodities.forEach((commodity: Commodity, i: number) => {
      // Do not display insufficient stock for non-WFP category
      // (doesn't apply to purchases with non-wfp commodities)
      if (commodity.category !== nonWfpCategory) {
        let currentStock = stockValueByDateByCommodityByCategory(
          allStores,
          moment(newDate).format("YYYY-MM-DD"),
          commodity.commodity,
          commodity.category
        );
        if (
          currentStoreData?.consumption?.commodities &&
          currentStoreData?.consumption?.commodities[i] !== undefined &&
          commodity.commodity ===
            currentStoreData?.consumption?.commodities[i].commodity &&
          Object.keys(currentStoreData?.consumption?.commodities[i]).includes(
            "quantity"
          )
        ) {
          currentStock += parseFloat(
            currentStoreData?.consumption?.commodities[i].quantity
          );
        }
        if (
          parseFloat(commodity.quantity) > currentStock &&
          commodity.commodity
        ) {
          errors[`commodities[${i}].quantity`] = {
            message: ERROR_MESSAGES["insufficientStock"],
          };
        }
        if (
          (currentSchoolEnabledPurchases || currentSchoolEnabledTHR) &&
          !commodity.category
        ) {
          errors["category"] = {
            message: ERROR_MESSAGES["missingCategory"],
            requiredMessage: missingCategoryRequiredMessage,
          };
        }
        if (!commodity.commodity) {
          errors[`commodities[${i}].commodity`] = {
            message: ERROR_MESSAGES["missingCommodity"],
          };
        }
        // `quantity` is required on each commodity row
        if (!commodity.quantity) {
          errors[`commodities[${i}].quantity`] = {
            message: ERROR_MESSAGES["missingQuantity"],
          };
        }
        if (
          parseFloat(commodity.quantity) === 0 &&
          commodity.commodity &&
          commodity.quantity
        ) {
          errors[`commodities[${i}].quantity`] = {
            message: ERROR_MESSAGES["zeroQuantity"],
          };
        }
      }
    });
  }
  return {
    values: !isEmpty(errors) ? {} : values,
    errors,
  };
};

export const getCategoryInStock = (
  allStores: any,
  commoditiesListWfpFromSchoolProfile: { id: number; name: string }[],
  category: string
) => {
  return stockInStoreByCategory(
    commoditiesListWfpFromSchoolProfile,
    allStores,
    category
  ).length > 0
    ? true
    : false;
};

const makeCategoryOption = (category: CommoditySource) => ({
  value: category.code,
  name: category.code,
  label: category.name,
});

const makeCategoryOptions = (
  commodityIdToSourceMapping: CommodityIdToSourceMapping,
  allCommoditySourcesMap: { [commoditySourceCode: string]: CommoditySource },
  currentSchoolEnabledPurchases: boolean
) => {
  // All commodity source codes with duplicates.
  const allCommoditySourceCodes: string[] = Object.values(
    commodityIdToSourceMapping
  ).flat();

  // Add school purchases if it is enabled.
  if (currentSchoolEnabledPurchases) {
    allCommoditySourceCodes.push(purchaseDetailCategory);
  }

  // Remove duplicates from commodity source codes
  // and turn them into commodity source objects.
  const sources = [...new Set(allCommoditySourceCodes)].map(
    (categoryCode: string) => allCommoditySourcesMap[categoryCode]
  );
  // Sort by order
  sources.sort((a, b) => a.order - b.order);

  return sources.map(makeCategoryOption);
};

const MealsEditModal = ({
  currentStoreData,
  currentStudentStoreData,
  previousAttendanceStoreWithConsumptionData,
  prepopulatePerStudent,
  prepopulateTotalQuantity,

  commoditiesIndex,
  allStores,
  currentSchoolProfile,
  commoditiesListWfpFromSchoolProfile,
  latestSchoolYear,
  attendances,
  commoditySources,
  allCommoditySources,
}: any) => {
  const intl = useIntl();

  const currentSchoolEnabledPurchases =
    (useSelector(hasPurchaseStore) &&
      getCategoryInStock(
        allStores,
        commoditiesListWfpFromSchoolProfile,
        purchaseDetailCategory
      )) ||
    currentSchoolProfile.enable_cash_purchases;

  const currentSchoolEnabledTHR =
    (useSelector(hasTHRStore) &&
      getCategoryInStock(
        allStores,
        commoditiesListWfpFromSchoolProfile,
        takeHomeRationCategory
      )) ||
    currentSchoolProfile.enable_take_home_rations;

  const currentSchoolEnabledStudentAttendance =
    (useSelector(hasTHRStore) &&
      getCategoryInStock(
        allStores,
        commoditiesListWfpFromSchoolProfile,
        studentAttendanceCategory
      )) ||
    currentSchoolProfile.enable_student_attendance;

  const { submitBtnRef, submitViaBtnRefClick } = useStore();

  const dispatch = useDispatch();

  const allCommoditySourcesMap = useMemo(
    () => {
      const result: { [categoryCode: string]: CommoditySource } = {};
      for (let source of allCommoditySources) {
        result[source.code] = source;
      }
      return result;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allCommoditySources.length]
  );

  const params: any = useParams();
  const history = useHistory();
  const isTHRMeal = params.details === "new-take-home-ration-data";

  const id = (currentStoreData && currentStoreData.client_id) || undefined;

  const newDate = currentStoreData?.occurred_on
    ? moment(currentStoreData?.occurred_on)
    : params.item && params.item.split("+").length === 2
    ? moment(params.item.split("+")[1])
    : undefined;

  const currentDate: string = newDate!.format("YYYY-MM-DD");

  const attendanceProvided =
    currentStoreData?.levels && currentStoreData?.type !== "delete"
      ? true
      : false;

  const studentAttendanceProvided = !!(
    currentStudentStoreData && currentStudentStoreData?.type !== "delete"
  );

  const isReasonDisabled = (option: typeof noMealsOptions[0]) => {
    if (currentSchoolEnabledStudentAttendance) {
      const noSchoolWithAttendanceOrMeal =
        option.isNoSchoolReason &&
        (attendanceProvided || studentAttendanceProvided);
      const noMealWithMeal = !option.isNoSchoolReason && attendanceProvided;
      return noMealWithMeal || noSchoolWithAttendanceOrMeal;
    } else {
      return option.isNoSchoolReason && attendanceProvided;
    }
  };

  let totalStudents = getFilteredAttendanceTotal({
    attendance: currentStoreData,
  });

  const totalPrevStore = useSelector(
    getPreviousAttendanceStoreWithAttendanceDataByDate(moment(newDate))
  );

  const totalPrevStudents = getFilteredAttendanceTotal({
    attendance: totalPrevStore,
  });

  if (!attendanceProvided) {
    totalStudents = totalPrevStudents;
  }

  const methods = useForm({
    resolver: validationResolver,
    defaultValues: storeToFormValues(
      previousAttendanceStoreWithConsumptionData &&
        ((prepopulatePerStudent &&
          previousAttendanceStoreWithConsumptionData.levels) ||
          prepopulateTotalQuantity)
        ? {
            ...previousAttendanceStoreWithConsumptionData,
            isPreviousStore: true,
          }
        : currentStoreData,
      prepopulatePerStudent,
      totalStudents
    ),
    context: {
      allStores,
      currentStoreData,
      newDate,
      currentSchoolEnabledPurchases,
      currentSchoolEnabledTHR,
    },
  });

  const { control, handleSubmit, watch, errors, setValue, register } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "commodities",
  });

  const otherReason = watch("other_no_meal_reason");

  const isOtherReasonInputted =
    otherReason !== undefined && otherReason?.length > 0;

  const isNoMealReasonsChecked =
    watch("no_meal_reasons").includes(true) || isOtherReasonInputted;

  const commoditiesWatch = watch("commodities");
  const categoryWatch = (category: string, index: number) =>
    watch(`commodities[${index}].category`) === category ? true : false;
  const [meal_provided, _setMealProvided] = useState(
    currentStoreData.consumption && currentStoreData.consumption.meal_provided
  );

  const currentSchoolYearDate = currentSchoolYearDateValidation(
    latestSchoolYear!
  );

  const setMealProvided = (value: any) => {
    if (value === true) {
      // Ensure at least one commodity row
      if (fields.length === 0) {
        append({});
      }
    } else if (value === false) {
      // Remove all commodity rows
      remove(); // Removes all when no argument
    }
    _setMealProvided(value);
  };

  useEffect(() => {
    if (prepopulatePerStudent || prepopulateTotalQuantity) {
      setMealProvided(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prepopulatePerStudent, prepopulateTotalQuantity]);

  const closeModal = (newId: string) => {
    history.push(
      buildAttendanceUrl(
        currentDate,
        params.schoolId,
        newId ? newId : id,
        params.thrItem,
        params.studentItem
      )
    );
  };

  const replicateNoMealServed = (values: any) => {
    // Get the school days for the selected period
    const schoolDays = getSchoolDays(
      values.replicate_from_date,
      values.replicate_to_date,
      latestSchoolYear?.weekdays
    );

    // Go through all school days and create/edit the attendance objects accordingly:
    // - do not create/edit for the current day
    // - do not create/edit for existing attendance with consumption already entered
    schoolDays
      .filter((day) => !moment(day).isSame(newDate))
      .forEach((day) => {
        const attendanceStoreObject = attendances.find(
          (item: { occurred_on: string }) => item.occurred_on === day
        );
        if (
          !attendanceStoreObject ||
          (attendanceStoreObject && !attendanceStoreObject.consumption)
        ) {
          dispatch(
            updateStore({
              id: attendanceStoreObject?.client_id || undefined,
              values: {
                occurred_on: day,
                ...attendanceStoreObject,
                consumption: {
                  ...formValuesToStore(values),
                  meal_provided,
                  category: deliveryCategory,
                },
              },
              model: "attendance",
              type: (attendanceStoreObject?.client_id && "update") || "create",
              section: "consumption",
              category: deliveryCategory,
            })
          );
        }
      });
  };

  const onSubmit = (values: any) => {
    if (
      values.replicate_from_date &&
      values.replicate_to_date &&
      meal_provided === false
    ) {
      replicateNoMealServed(values);
    }

    // Delete not needed fields
    delete values.replicate_from_date;
    delete values.replicate_to_date;

    const toBeSaved = {
      ...currentStoreData,
      consumption: {
        ...formValuesToStore(values),
        meal_provided,
        category: deliveryCategory,
      },
    };

    // Meals can either be served or not served.
    // If meals were served, delete data related to not serving meals, and viceversa.
    if (meal_provided === true) {
      delete toBeSaved.consumption.no_meal_reasons;
      delete toBeSaved.consumption.other_no_meal_reason;
    } else if (meal_provided === false) {
      delete toBeSaved.consumption.commodities;
    }
    const store = dispatch(
      updateStore({
        id: (currentStoreData && currentStoreData.client_id) || undefined,
        values: toBeSaved,
        model: "attendance",
        type:
          (currentStoreData && currentStoreData.client_id && "update") ||
          "create",
        section: "consumption",
        category: deliveryCategory,
      })
    );
    closeModal(store.data.id);
  };

  const currentParam =
    params.details.split("-")[0] === "new" ? "new-meals" : "meals";

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalExtended
          open
          onRequestSubmit={submitViaBtnRefClick}
          onRequestClose={() => closeModal(params.item)}
          modalLabel={
            <FormattedMessage
              id="MealsEdit.mealConsumptionLabel"
              defaultMessage="Meal consumption"
            />
          }
          className="meals-edit"
          modalHeading={
            <>
              {moment(currentStoreData.occurred_on).format("dddd, DD.MM.YYYY")}{" "}
              {meal_provided === true && (
                <Button
                  kind="danger"
                  icon={<FontAwesomeIcon icon={faTimesCircle} />}
                  onClick={() => setMealProvided(false)}
                >
                  <FormattedMessage
                    id="MealsEdit.noMealsServed"
                    defaultMessage="No meals served"
                  />
                </Button>
              )}
              {meal_provided === false && (
                <Button onClick={() => setMealProvided(true)}>
                  <FormattedMessage
                    id="MealsEdit.mealsServedQuestion"
                    defaultMessage="Meal served?"
                  />
                </Button>
              )}
            </>
          }
          modalFooter={
            !meal_provided
              ? (props: any) => (
                  <div className="modal-footer">
                    <div className="repeat-section">
                      <label className="wfp--label repeat-label">
                        <FormattedMessage
                          id="MealsEdit.repeatForOtherPeriod"
                          defaultMessage="Repeat for other period"
                        />
                      </label>
                      <Controller
                        className="date-from-picker"
                        as={
                          <DateInput
                            labelText={
                              <FormattedMessage
                                id="MealsEdit.fromLabel"
                                defaultMessage="From"
                              />
                            }
                            min={currentSchoolYearDate.min}
                            max={currentSchoolYearDate.max}
                          />
                        }
                        name="replicate_from_date"
                      />
                      <FontAwesomeIcon
                        className="arrow-right"
                        icon={faArrowRight}
                      />
                      <Controller
                        className="date-to-picker"
                        as={
                          <DateInput
                            labelText={
                              <FormattedMessage
                                id="MealsEdit.toLabel"
                                defaultMessage="To"
                              />
                            }
                            min={currentSchoolYearDate.min}
                            max={currentSchoolYearDate.max}
                          />
                        }
                        name="replicate_to_date"
                      />
                    </div>
                    <div className="wfp--modal__buttons-container">
                      {props.secondaryButtonText && (
                        <Button
                          kind="secondary"
                          disabled={props.secondaryButtonDisabled}
                          onClick={
                            props.onSecondarySubmit
                              ? props.onSecondarySubmit
                              : props.onRequestClose
                          }
                        >
                          {props.secondaryButtonText}
                        </Button>
                      )}
                      <Button
                        kind="primary"
                        disabled={props.primaryButtonDisabled}
                        onClick={props.onRequestSubmit}
                      >
                        {props.primaryButtonText}
                      </Button>
                    </div>
                  </div>
                )
              : undefined
          }
          wide
          primaryButtonDisabled={!isNoMealReasonsChecked && !meal_provided}
          primaryButtonText={
            <FormattedMessage
              id="MealsEdit.saveMealButton"
              defaultMessage="Save"
            />
          }
          secondaryButtonText={
            <FormattedMessage
              id="MealsEdit.cancelButton"
              defaultMessage="Cancel"
            />
          }
          selectorPrimaryFocus={false}
          passiveModal={meal_provided !== true && meal_provided !== false}
        >
          <div>
            {meal_provided !== true && meal_provided !== false && !isTHRMeal && (
              <div className="meals-edit__question">
                <h3>
                  <FormattedMessage
                    id="MealsEdit.wasAMealProvidedQuestion"
                    defaultMessage="Was a meal served?"
                  />
                </h3>
                <div>
                  <Button
                    type="button"
                    kind="secondary"
                    onClick={() => setMealProvided(false)}
                    icon={<FontAwesomeIcon icon={faTimes} />}
                  >
                    <FormattedMessage
                      id="MealsEdit.mealNotServedButton"
                      defaultMessage="No"
                    />
                  </Button>
                  <Button
                    type="button"
                    onClick={() => setMealProvided(true)}
                    icon={<FontAwesomeIcon icon={faCheck} />}
                  >
                    <FormattedMessage
                      id="MealsEdit.mealProvidedButton"
                      defaultMessage="Meal served"
                    />
                  </Button>
                </div>
              </div>
            )}
            {meal_provided === false && !isTHRMeal && (
              <div className="wfp--form wfp--form-long no-meal-reasons-container">
                <RadioButtonGroup
                  name="MealsEdit.whyNoMealQuestion"
                  labelText={
                    <FormattedMessage
                      id="MealsEdit.whyNoMealQuestion"
                      defaultMessage="Why will no meal be served today?"
                    />
                  }
                  vertical
                >
                  {noMealsOptions.map((option) => (
                    <div
                      key={option.value}
                      className="no-meal-reasons-checkbox"
                    >
                      <Controller
                        render={({ onChange, value }) => (
                          <Checkbox
                            labelText={option.label}
                            id={`noMealsOptions-${option.value}`}
                            checked={value}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => onChange(e.target.checked)}
                            disabled={isReasonDisabled(option)}
                          />
                        )}
                        name={`no_meal_reasons[${option.value}]`}
                      />
                      {isReasonDisabled(option) && (
                        <span className="tooltiptext">
                          <FormattedMessage
                            id="MealsEdit.noMealReasonsAttendance"
                            defaultMessage="Please remove entered attendance to choose this reason"
                          />
                        </span>
                      )}
                    </div>
                  ))}
                </RadioButtonGroup>
                <Controller
                  as={
                    <TextInput
                      labelText={
                        <FormattedMessage
                          id="MealsEdit.otherReason"
                          defaultMessage="Other reason"
                        />
                      }
                    />
                  }
                  name={"other_no_meal_reason"}
                  defaultValue=""
                />
              </div>
            )}
            {meal_provided === true && (
              <>
                {attendanceProvided === false && totalStudents === 0 && (
                  <Blockquote kind="warning">
                    <FormattedMessage
                      id="info.noAttendance"
                      defaultMessage="You haven't inputted attendance data therefore we can't
                        populate the total quantity field according to the quantity
                        per student automatically."
                    />
                  </Blockquote>
                )}
                {attendanceProvided === false && totalStudents > 0 && (
                  <Blockquote kind="warning">
                    <FormattedMessage
                      id="editMeals.usingPreviousDaysAttendance"
                      defaultMessage="Since you haven't inputted attendance data for today,
                        the previous day's attendance will be used to populate the total
                        quantity field according to the quantity per student "
                    />
                  </Blockquote>
                )}
                <ErrorList errors={errors} />

                <div>
                  {fields.map((field, index) => (
                    <RepeaterItem
                      key={field.id}
                      removeAction={() => remove(index)}
                      disableRemove={fields.length === 1}
                    >
                      <>
                        <RadioButtonGroup
                          name="MealsEdit.commoditySource"
                          labelText={
                            <FormattedMessage
                              id="MealsEdit.commoditySource"
                              defaultMessage="Commodity Source"
                            />
                          }
                        >
                          {makeCategoryOptions(
                            commoditySources,
                            allCommoditySourcesMap,
                            currentSchoolEnabledPurchases
                          ).map((category) => (
                            <RadioButton
                              key={category.value}
                              labelText={category.label}
                              id={`commodities[${index}][${category.value}]`}
                              name={`commodities[${index}].category`}
                              value={category.value}
                              defaultChecked={
                                category.value === deliveryCategory
                              }
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setValue(
                                  `commodities[${index}].category`,
                                  e.target.value,
                                  {
                                    shouldDirty: true,
                                  }
                                );
                                setValue(
                                  `commodities[${index}].commodity`,
                                  null,
                                  {
                                    shouldDirty: true,
                                  }
                                );
                              }}
                              ref={
                                currentSchoolEnabledTHR ||
                                currentSchoolEnabledPurchases
                                  ? register({ required: true })
                                  : register({ required: false })
                              }
                              other={{
                                name: `commodities[${index}].category`,
                              }}
                            />
                          ))}
                        </RadioButtonGroup>
                      </>
                      <Controller
                        render={({ onChange, value }) => (
                          <CommoditySelect
                            category={watch(`commodities[${index}].category`)}
                            onChange={(e: {
                              value: string;
                              category: string;
                              measure_unit: unknown;
                            }) => {
                              setValue(
                                `commodities[${index}].measure_unit`,
                                e.measure_unit,
                                {
                                  shouldDirty: true,
                                }
                              );
                              onChange(e.value, e.category);
                            }}
                            value={value}
                            showStock={true}
                            invalidOverride={
                              errors[`commodities[${index}].commodity`] !==
                              undefined
                            }
                            invalidTextOverride={requiredLabelCommodities}
                            date={currentStoreData.occurred_on}
                          />
                        )}
                        name={`commodities[${index}].commodity`}
                        control={control}
                      />
                      {
                        // Only show `source` field when selected commodity is not from WFP
                        commoditiesWatch &&
                          commoditiesWatch[index] &&
                          commoditiesWatch[index].commodity &&
                          !commoditiesIndex[commoditiesWatch[index].commodity]
                            ?.is_wfp &&
                          categoryWatch(nonWfpCategory, index) && (
                            <Controller
                              className="meals__source"
                              as={
                                <Select
                                  labelText={
                                    <FormattedMessage
                                      id="MealsEdit.source"
                                      defaultMessage="Source"
                                    />
                                  }
                                >
                                  {commoditySourceOptions.map((source) => (
                                    <SelectItem
                                      value={source.value}
                                      text={intl.formatMessage({
                                        id: `Common.${source.key}`,
                                        defaultMessage: source.label,
                                      })}
                                    />
                                  ))}
                                </Select>
                              }
                              name={`commodities[${index}].source`}
                              placeholder=""
                              control={control}
                            />
                          )
                      }
                      <Controller
                        render={({ onChange, onBlur, value }) => {
                          return (
                            <QuantityInput
                              control={control}
                              value={value}
                              onChange={(e) => {
                                if (totalStudents) {
                                  setValue(
                                    `commodities[${index}].quantity`,
                                    (e.target.value * totalStudents).toFixed(2),
                                    { shouldDirty: true }
                                  );
                                }
                                return onChange(e);
                              }}
                              onBlur={onBlur}
                              labelText={
                                <FormattedMessage
                                  id="MealsEdit.quantityPerStudents"
                                  defaultMessage="Quantity per student"
                                />
                              }
                              setValue={setValue}
                              name={`commodities[${index}].quantityPerStudent`}
                              invalid={
                                errors[
                                  `commodities[${index}].quantityPerStudent`
                                ]
                              }
                              invalidText={
                                errors[
                                  `commodities[${index}].quantityPerStudent`
                                ]?.message
                              }
                              measureUnit={watch(
                                `commodities[${index}].measure_unit`
                              )}
                            />
                          );
                        }}
                        control={control}
                        name={`commodities[${index}].quantityPerStudent`}
                      />

                      <Controller
                        render={({ onChange, onBlur, value }) => {
                          return (
                            <QuantityInput
                              control={control}
                              value={value}
                              onChange={(e) => {
                                if (totalStudents) {
                                  setValue(
                                    `commodities[${index}].quantityPerStudent`,
                                    (e.target.value / totalStudents).toFixed(2),
                                    { shouldDirty: true }
                                  );
                                }
                                return onChange(e);
                              }}
                              onBlur={onBlur}
                              labelText={
                                <FormattedMessage
                                  id="MealsEdit.TotalQuantity"
                                  defaultMessage="Total quantity"
                                />
                              }
                              setValue={setValue}
                              name={`commodities[${index}].quantity`}
                              invalid={errors[`commodities[${index}].quantity`]}
                              invalidText={
                                errors[`commodities[${index}].quantity`]
                                  ?.message
                              }
                              measureUnit={watch(
                                `commodities[${index}].measure_unit`
                              )}
                            />
                          );
                        }}
                        control={control}
                        name={`commodities[${index}].quantity`}
                      />

                      <Controller
                        name={`commodities[${index}].measure_unit`}
                        control={control}
                      />
                    </RepeaterItem>
                  ))}
                </div>
                <RepeaterAddButton
                  onClick={() => append({ name: "commodities" })}
                />
              </>
            )}
            {meal_provided && (
              <div className="replicate-meal-section">
                <label className="wfp--label replicate-meal-label">
                  <FormattedMessage
                    id="MealsEdit.replicateMealLabel"
                    defaultMessage="Replicate meal from the last school day"
                  />
                </label>
                <div className="replicate-section-buttons">
                  <ButtonRouter
                    disabled={params.details.includes(
                      "prepopulate-per-student"
                    )}
                    to={buildAttendanceUrl(
                      currentDate,
                      params.schoolId,
                      params.item,
                      params.thrItem,
                      params.studentItem,
                      `${currentParam}-prepopulate-per-student`
                    )}
                  >
                    <FormattedMessage
                      id="MealsEdit.perStudent"
                      defaultMessage="Per student"
                    />
                  </ButtonRouter>
                  <ButtonRouter
                    disabled={params.details.includes(
                      "prepopulate-total-quantity"
                    )}
                    to={buildAttendanceUrl(
                      currentDate,
                      params.schoolId,
                      params.item,
                      params.thrItem,
                      params.studentItem,
                      `${currentParam}-prepopulate-total-quantity`
                    )}
                  >
                    <FormattedMessage
                      id="MealsEdit.totalQuantity"
                      defaultMessage="Total quantity"
                    />
                  </ButtonRouter>
                </div>
                {!previousAttendanceStoreWithConsumptionData &&
                  (prepopulatePerStudent || prepopulateTotalQuantity) && (
                    <Blockquote kind="warning">
                      <FormattedMessage
                        id="MealsEdit.noPreviousDataToImport"
                        defaultMessage="There is no previous data to import."
                      />
                    </Blockquote>
                  )}
                {previousAttendanceStoreWithConsumptionData &&
                  prepopulatePerStudent &&
                  !previousAttendanceStoreWithConsumptionData.levels && (
                    <Blockquote kind="warning">
                      <FormattedMessage
                        id="MealsEdit.noPreviousAttendance"
                        defaultMessage="There is no attendance information in the previous day, therefore quantity per student cannot be calculated."
                      />
                    </Blockquote>
                  )}
              </div>
            )}
          </div>
        </ModalExtended>
        <button className="hidden-btn" ref={submitBtnRef as any} />
      </form>
    </FormProvider>
  );
};

const MealsEdit = ({
  currentStoreData,
  currentStudentStoreData,
  previousAttendanceStoreWithConsumptionData,
  prepopulatePerStudent,
  prepopulateTotalQuantity,
}: any) => {
  const params: any = useParams();
  const commoditiesIndex = useSelector(commoditiesDuck.getIndex);
  const allStores = useSelector(getAllStores);
  const currentSchoolProfile = useSelector(getCurrentSchoolProfile);
  const commoditiesListWfpFromSchoolProfile = useSelector(
    getCurrentSchoolProfileWfpCommodities
  );
  const latestSchoolYear = useSelector(getCurrentOrLatestSchoolYear);
  // Get all the attendances store objects for the report's month
  const attendances = useSelector(
    getDeliveryAttendancesByDateRange(
      latestSchoolYear?.starts_on!,
      latestSchoolYear?.ends_on!
    )
  );

  const isSchoolsFetching = useSelector(getSchoolsFetching);
  const isCommoditySourcesFetching = useSelector(getCommoditySourcesFetching);
  const isAllCommoditySourcesFetching = useSelector(
    getAllCommoditySourcesFetching
  );

  const commoditySources = useSelector(getCommoditySources) || {};
  const allCommoditySources: CommoditySource[] =
    useSelector(getAllCommoditySources) || [];

  const isOffline = useSelector(getLoggedInOffline);

  // Are we still fetching some data
  const isFetching =
    isSchoolsFetching ||
    isCommoditySourcesFetching ||
    isAllCommoditySourcesFetching ||
    allCommoditySources.length === 0;

  const dispatch = useDispatch();

  useEffect(() => {
    // If we are on offline mode,
    // Set schools.commoditysources.data from schools.userCommoditySources.data.
    // If not get request commoditysources from the server for current school.
    if (isOffline) {
      dispatch(setCommoditySourcesFromSchoolCache(params.schoolId));
    } else {
      dispatch(requestAllCommoditySources());
      dispatch(requestCommoditySourcesForSchool(params.schoolId));
    }
  }, [dispatch, isOffline, params.schoolId]);

  if (isFetching) {
    return <Loading with_overlay={true} />;
  }

  return (
    <MealsEditModal
      currentStoreData={currentStoreData}
      currentStudentStoreData={currentStudentStoreData}
      previousAttendanceStoreWithConsumptionData={
        previousAttendanceStoreWithConsumptionData
      }
      prepopulatePerStudent={prepopulatePerStudent}
      prepopulateTotalQuantity={prepopulateTotalQuantity}
      commoditiesIndex={commoditiesIndex}
      allStores={allStores}
      currentSchoolProfile={currentSchoolProfile}
      commoditiesListWfpFromSchoolProfile={commoditiesListWfpFromSchoolProfile}
      latestSchoolYear={latestSchoolYear}
      attendances={attendances}
      commoditySources={commoditySources}
      allCommoditySources={allCommoditySources}
    ></MealsEditModal>
  );
};

export default MealsEdit;
