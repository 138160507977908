import React, { ElementType } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";

import { Button, Module, ModuleBody, List, ListItem } from "@wfp/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import DeleteModalButton from "components/DeleteModalButton";
import {
  Delivery,
  getCurrentSchoolYear,
  getPreviousSchoolYears,
  getSchoolYearByDate,
} from "data-handler/ducks/stores";
import Section from "components/Section";
import SubTitle from "components/SubTitle";
import CommodityShow from "components/CommodityShow";
import MainContent from "components/MainContent";

import { getReportedStores } from "helpers/report";

import "./_deliveries-show.scss";

import Gate from "../Gate";
import { UserPermissionRoles } from "../../SCConstants";
import { getIsSyncableLocked } from "../../data-handler/ducks/stores";
import UneditableMessage, {
  getModelDateFormat,
} from "components/UneditableMessage";
import { RootState } from "data-handler/rootReducer";

type DeliveriesShowProps = {
  currentStoreData: Delivery;
};

type DeliveriesShowParams = {
  schoolId: string;
  item: string;
};

const DeliveriesShow: React.ElementType<DeliveriesShowProps> = ({
  currentStoreData,
}) => {
  const params: DeliveriesShowParams = useParams();
  const reportedStores = useSelector(getReportedStores);
  const history = useHistory();
  const isReported = reportedStores[params.item];
  const isStockRebalance =
    currentStoreData.is_negative_stock_rebalance === true;
  const formattedDeliveryDate = moment(currentStoreData.delivered_at).format(
    "dddd, DD.MM.YYYY"
  );
  const isLocked = useSelector((state: RootState) =>
    getIsSyncableLocked(state, currentStoreData)
  );

  const selectedDeliverySchoolYear = useSelector(
    getSchoolYearByDate(
      getModelDateFormat(currentStoreData) || moment().format("YYYY-MM-DD")
    )
  );

  const previousSchoolYears = useSelector(getPreviousSchoolYears);
  const firstSchoolYear = previousSchoolYears.length === 0;

  const currentYear = useSelector(getCurrentSchoolYear);

  const commoditiesOpeningStock = currentStoreData?.commodities.filter(
    (deliveryCommodity) => deliveryCommodity.is_carry_over === true
  );
  const deliveredCommodities = currentStoreData?.commodities.filter(
    (deliveryCommodity) => !deliveryCommodity.is_carry_over
  );

  const carryOverCreated = commoditiesOpeningStock?.length > 0;

  const carryOverTitle = firstSchoolYear ? (
    <FormattedMessage
      id="DeliveriesShow.initialStockCommodities"
      defaultMessage="Initial stock commodities"
    />
  ) : (
    <FormattedMessage
      id="DeliveriesShow.carryOverCommodities"
      defaultMessage="Carry-over commodities"
    />
  );

  const carryOverEmptyTitle = firstSchoolYear ? (
    <FormattedMessage
      id="DeliveriesShow.noInitialStockCommoditiesDelivered"
      defaultMessage="No initial stock commodities "
    />
  ) : (
    <FormattedMessage
      id="DeliveriesShow.noCarryOverDelivered"
      defaultMessage="No carry-over commodities "
    />
  );

  return (
    <MainContent title={formattedDeliveryDate} className="deliveries-content">
      <Section>
        <UneditableMessage currentStoreData={currentStoreData} />
      </Section>
      <SubTitle
        additional={
          <Gate
            forbidRoles={[UserPermissionRoles.is_viewer]}
            isForbidden={
              isLocked ||
              (currentYear !== null &&
                currentYear !== selectedDeliverySchoolYear) ||
              (currentYear === null &&
                previousSchoolYears[0] !== selectedDeliverySchoolYear)
            }
          >
            {currentStoreData.type !== "delete" && (
              <DeleteModalButton
                currentStoreData={currentStoreData}
                disabled={isStockRebalance || carryOverCreated}
              />
            )}
            <Button
              className="edit-deliveries-button"
              kind="secondary"
              icon={<FontAwesomeIcon icon={faChevronRight} />}
              disabled={isReported || isStockRebalance || carryOverCreated}
              onClick={() =>
                history.push(
                  `/school/${params.schoolId}/deliveries/${params.item}/delivery`
                )
              }
            >
              <FormattedMessage
                id="DeliveriesShow.editDelivery"
                defaultMessage="Edit Delivery"
              />
            </Button>
          </Gate>
        }
      >
        <FormattedMessage
          id="DeliveriesShow.deliveryFrom"
          defaultMessage="Delivery {waybill_no} from {date}"
          values={{
            waybill_no: currentStoreData.waybill_no,
            date: formattedDeliveryDate,
          }}
        />
      </SubTitle>
      <Module noMargin>
        <ModuleBody>
          <List kind="details" colon>
            <ListItem
              title={
                <FormattedMessage
                  id="DeliveriesShow.waybill"
                  defaultMessage="Waybill"
                />
              }
            >
              {currentStoreData.waybill_no}
            </ListItem>
            <ListItem
              title={
                <FormattedMessage
                  id="DeliveriesShow.formattedDeliveryDate"
                  defaultMessage="Delivery date"
                />
              }
            >
              {formattedDeliveryDate}
            </ListItem>
          </List>
        </ModuleBody>
      </Module>
      {(currentStoreData.waybill_no?.includes("IS") ||
        currentStoreData.waybill_no === "Initial stock") &&
        commoditiesOpeningStock?.length > 0 && (
          <CommodityShow
            emptyTitle={carryOverEmptyTitle}
            title={carryOverTitle}
            model={currentStoreData.model}
            commodities={commoditiesOpeningStock as any}
            type={"Carry-over"}
          />
        )}

      <CommodityShow
        emptyTitle={
          <FormattedMessage
            id="DeliveriesShow.noCommoditiesDelivered"
            defaultMessage="No commodities delivered"
          />
        }
        title={
          <FormattedMessage
            id="DeliveriesShow.deliveredCommodities"
            defaultMessage="Delivered commodities"
          />
        }
        model={currentStoreData.model}
        commodities={deliveredCommodities as any}
      />
    </MainContent>
  );
};

export default DeliveriesShow;
