import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import withSizes from "react-sizes";

import {
  faClipboardUser,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Module, ModuleBody } from "@wfp/ui";

import { deliveryCategory, UserPermissionRoles } from "SCConstants";
import { mapSizesToProps } from "helpers/mapSizesToProps";
import { getReportedStores } from "helpers/report";
import {
  Attendance,
  getCurrentSchoolYear,
  getIsSyncableLocked,
  getPreviousSchoolYears,
  getSchoolYearByDate,
  getSchoolYearLevelsBySchoolYearId,
  Store,
} from "data-handler/ducks/stores";

import DeleteModalButton from "components/DeleteModalButton";
import Empty from "components/Empty";
import Section from "components/Section";
import SubTitle from "components/SubTitle";
import Gate from "components/Gate";
import AttendanceTableMobile from "components/AttendanceShow/AttendanceTableMobile";
import AttendanceTableDesktop from "components/AttendanceShow/AttendanceTableDesktop";

import UneditableMessage from "components/UneditableMessage";
import moment from "moment";
import { RootState } from "data-handler/rootReducer";
import { buildAttendanceUrl } from "helpers/attendance";

export const noMealReasonsRestriction = (
  currentStoreData: Store,
  currentStudentStoreData: Store,
  currentSchoolEnabledStudentAttendance: boolean
) => {
  if (currentSchoolEnabledStudentAttendance) {
    return (
      (currentStoreData as any)?.consumption?.no_meal_reasons?.length &&
      currentStoreData?.type !== "delete"
    );
  } else {
    return (
      ((currentStoreData as any)?.consumption?.no_meal_reasons?.includes(1) ||
        (currentStoreData as any)?.consumption?.no_meal_reasons?.includes(5) ||
        (currentStoreData as any)?.consumption?.no_meal_reasons?.includes(7)) &&
      currentStoreData?.type !== "delete"
    );
  }
};

type AttendanceShowProps = {
  currentStoreData: Attendance;
  currentStudentStoreData: Attendance;
  currentSchoolEnabledStudentAttendance: boolean;
  allowDelete: boolean;
  date: string;
};

type WithSizesProps = {
  isMobile: boolean;
};

type AttendanceShowParams = {
  schoolId: string;
  item: string;
  thrItem: string;
  studentItem: string;
};

const AttendanceShow: React.ComponentType<
  AttendanceShowProps & WithSizesProps
> = ({
  currentStoreData,
  currentStudentStoreData,
  currentSchoolEnabledStudentAttendance,
  allowDelete,
  date,
  isMobile,
}) => {
  const params: AttendanceShowParams = useParams();

  const urlDetails = currentStoreData ? "attendance" : "new-attendance";
  const editUrl = buildAttendanceUrl(
    date,
    params.schoolId,
    params.item,
    params.thrItem,
    params.studentItem,
    urlDetails
  );

  const reportedStores = useSelector(getReportedStores);
  const isReported = (reportedStores as any)[params.item];
  const isLocked = useSelector((state: RootState) =>
    getIsSyncableLocked(state, currentStoreData)
  );

  const defineSection = !currentStoreData?.consumption
    ? undefined
    : "attendance";

  const deleteAttendanceLabel = currentSchoolEnabledStudentAttendance ? (
    <FormattedMessage
      id="delete.deleteAttendanceWithStudentAttendanceEnabled"
      defaultMessage="Delete canteen attendance"
    />
  ) : (
    <FormattedMessage
      id="delete.deleteAttendance"
      defaultMessage="Delete attendance"
    />
  );

  const attendanceTitle = currentSchoolEnabledStudentAttendance ? (
    <FormattedMessage
      id="AttendanceShow.attendanceTitleWithStudentAttendanceEnabled"
      defaultMessage="Canteen Attendance"
    />
  ) : (
    <FormattedMessage
      id="AttendanceShow.attendanceTitle"
      defaultMessage="Attendance"
    />
  );

  const attendanceSubTitle = currentSchoolEnabledStudentAttendance ? (
    <FormattedMessage
      id="AttendanceShow.attendanceSubTitleWithStudentAttendanceEnabled"
      defaultMessage="Canteen Attendance"
    />
  ) : (
    <FormattedMessage
      id="AttendanceShow.attendanceSubTitle"
      defaultMessage="Attendance"
    />
  );

  const editAttendanceButton = currentSchoolEnabledStudentAttendance ? (
    <FormattedMessage
      id="AttendanceShow.editAttendanceButtonWithStudentAttendanceEnabled"
      defaultMessage="Edit canteen attendance"
    />
  ) : (
    <FormattedMessage
      id="AttendanceShow.editAttendanceButton"
      defaultMessage="Edit attendance"
    />
  );

  const currentYear = useSelector((state: RootState) =>
    getCurrentSchoolYear(state)
  ); //users current year

  const selectedAttendanceSchoolYear = useSelector(
    getSchoolYearByDate((date ? moment(date) : moment()).format("YYYY-MM-DD"))
  );

  const selectedAttendanceSchoolYearLevels = useSelector(
    getSchoolYearLevelsBySchoolYearId(selectedAttendanceSchoolYear!.object_id)
  );

  const previousSchoolYears = useSelector(getPreviousSchoolYears)[0];
  if (!currentStoreData?.levels) {
    return (
      <Empty
        kind="section"
        title={attendanceTitle}
        icon={<FontAwesomeIcon icon={faClipboardUser} size="1x" />}
        button={
          <Gate
            forbidRoles={[UserPermissionRoles.is_viewer]}
            isForbidden={
              isLocked ||
              (currentYear !== null &&
                currentYear !== selectedAttendanceSchoolYear) ||
              (currentYear === null &&
                previousSchoolYears !== selectedAttendanceSchoolYear)
            }
          >
            <NavLink className="edit-nav-link" to={editUrl}>
              <Button
                kind="accent"
                icon={<FontAwesomeIcon icon={faChevronRight} />}
                disabled={
                  isReported ||
                  noMealReasonsRestriction(
                    currentStoreData,
                    currentStudentStoreData,
                    currentSchoolEnabledStudentAttendance
                  )
                }
              >
                {currentSchoolEnabledStudentAttendance ? (
                  <FormattedMessage
                    id="AttendanceShow.provideCanteenAttendanceButton"
                    defaultMessage="Provide canteen attendance"
                  />
                ) : (
                  <FormattedMessage
                    id="AttendanceShow.provideAttendanceButton"
                    defaultMessage="Provide attendance"
                  />
                )}
              </Button>
            </NavLink>
          </Gate>
        }
      >
        <FormattedMessage
          id={
            currentSchoolEnabledStudentAttendance
              ? "AttendanceShow.provideCanteenAttendanceLabel"
              : "AttendanceShow.provideAttendanceLabel"
          }
          defaultMessage={
            currentSchoolEnabledStudentAttendance
              ? "Provide canteen attendance"
              : "Provide attendance"
          }
        />
      </Empty>
    );
  }

  const newAttendanceId =
    "newDate+" + moment(currentStoreData?.occurred_on).format("YYYY-MM-DD");
  return (
    <Section>
      <SubTitle
        additional={
          <Gate
            isForbidden={
              isLocked ||
              (currentYear !== null &&
                currentYear !== selectedAttendanceSchoolYear) ||
              (currentYear === null &&
                previousSchoolYears !== selectedAttendanceSchoolYear)
            }
            forbidRoles={[UserPermissionRoles.is_viewer]}
          >
            {currentStoreData.type !== "delete" && allowDelete && (
              <DeleteModalButton
                currentStoreData={currentStoreData}
                section={defineSection}
                title={deleteAttendanceLabel}
                customRedirectPath={
                  currentStoreData?.sync === false &&
                  !currentStoreData?.id &&
                  ((!currentStoreData.levels && currentStoreData.consumption) ||
                    (currentStoreData.levels && !currentStoreData.consumption))
                    ? buildAttendanceUrl(
                        date,
                        params.schoolId,
                        newAttendanceId,
                        params.thrItem,
                        params.studentItem
                      )
                    : buildAttendanceUrl(
                        date,
                        params.schoolId,
                        params.item,
                        params.thrItem,
                        params.studentItem
                      )
                }
              />
            )}
            <NavLink className="edit-nav-link" to={editUrl}>
              <Button
                kind="secondary"
                icon={<FontAwesomeIcon icon={faChevronRight} />}
                disabled={isReported || isLocked}
              >
                {editAttendanceButton}
              </Button>
            </NavLink>
          </Gate>
        }
      >
        <Section>
          <UneditableMessage currentStoreData={currentStoreData} />
        </Section>
        {attendanceSubTitle}
      </SubTitle>
      <Module noMargin>
        <ModuleBody noPadding>
          {!isMobile ? (
            <AttendanceTableDesktop
              attendance={currentStoreData}
              schoolYear={selectedAttendanceSchoolYear!}
              schoolYearLevels={selectedAttendanceSchoolYearLevels}
              category={deliveryCategory}
            />
          ) : (
            <AttendanceTableMobile
              attendance={currentStoreData}
              schoolYear={selectedAttendanceSchoolYear!}
              schoolYearLevels={selectedAttendanceSchoolYearLevels}
            />
          )}
        </ModuleBody>
      </Module>
    </Section>
  );
};

export default (withSizes(mapSizesToProps)(
  AttendanceShow as any
) as unknown) as React.ComponentType<AttendanceShowProps>;
